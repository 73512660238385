import { useEffect, useState } from "react";
import { HeightUnits } from "../../../../../types/enums";
import Modal from "../../common/Modal";
import RangeSlider from "./RangeSlider";
import "./heightModal.css";

import Toggle from "../../../../../utils/Toggle";
import * as math from "../../../../../utils/math";
import {
  unshareDemographics,
  updateDemographics,
} from "../../../../../api/demographicsApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDemographics,
  setAllDemographics,
} from "../../../../../store/features/demographicsInfoSlice";
import { selectUser } from "../../../../../store/features/authSlice";
import { closeModal } from "../../../../../store/features/modalSlice";
import { toaster } from "../../../../../utils/toast";

interface HeightModalProps {
  modalRef: any;
}

const HeightModal = ({ modalRef }: HeightModalProps) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);
  const { height } = useSelector(selectDemographics);

  const defaultFeet = height?.normalized
    ? math.cmToFeetInches(height?.normalized).feet
    : 5;

  const defaultInches = height?.normalized
    ? math.cmToFeetInches(height?.normalized).inches
    : 7;

  const [feet, setFeet] = useState(defaultFeet);
  const [inches, setInches] = useState(defaultInches);
  const [centimeters, setCentimeters] = useState(height?.normalized || 170);
  const [isCm, setIsCm] = useState<boolean>(
    height?.units === HeightUnits.CENTIMETERS || true
  );
  const [units, setUnits] = useState(height?.units || HeightUnits.CENTIMETERS);

  const handleFeetChange = (newFeet: number) => {
    setFeet(newFeet);
    const cm = math.feetInchesToCm(newFeet, inches);
    setCentimeters(cm);
  };

  const handleInchesChange = (newInches: number) => {
    setInches(newInches);
    const cm = math.feetInchesToCm(feet, newInches);
    setCentimeters(cm);
  };

  const handleCmChange = (newCm: number) => {
    setCentimeters(newCm);
    const { feet: newFeet, inches: newInches } = math.cmToFeetInches(newCm);
    setFeet(newFeet);
    setInches(newInches);
  };

  const setDemographic = () => {
    const height = { normalized: centimeters, units };
    updateDemographics({ userId, height })
      .then(() => {
        dispatch(setAllDemographics({ height }));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster(error.response.data.message);
      });
  };

  const unshare = () => {
    unshareDemographics({ userId, field: "height" })
      .then(() => {
        dispatch(setAllDemographics({ height: undefined }));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster({ text: error.response.data.message });
      });
  };

  useEffect(() => {
    if (isCm) {
      const cm = math.feetInchesToCm(feet, inches);
      setCentimeters(cm);
      setUnits(HeightUnits.CENTIMETERS);
    } else {
      const { feet: newFeet, inches: newInches } =
        math.cmToFeetInches(centimeters);
      setFeet(newFeet);
      setInches(newInches);
      setUnits(HeightUnits.INCHES);
    }
  }, [isCm, feet, inches, centimeters]);

  const isDisabled =
    height?.normalized === centimeters && height.units === units;

  return (
    <div ref={modalRef}>
      <Modal title="Height">
        <div className="h-full max-w-[330px] flex flex-col text-normal">
          <div className="flex flex-row h-40">
            {isCm && (
              <>
                <div className="h-full w-52">
                  <RangeSlider
                    id="feet-slider"
                    min={91}
                    max={244}
                    value={centimeters}
                    onChange={handleCmChange}
                    color="#BD1550"
                  />
                </div>
                <div className="w-24 rounded-lg overflow-hidden font-mono font-extrabold">
                  <div className="h-full bg-[#bd1550] center text-white">
                    {centimeters} cm
                  </div>
                </div>
              </>
            )}
            {!isCm && (
              <>
                <div className="h-full w-52">
                  <RangeSlider
                    id="feet-slider"
                    min={2}
                    max={8}
                    value={feet}
                    onChange={handleFeetChange}
                    sliders={2}
                  />
                  <RangeSlider
                    id="in-slider"
                    min={0}
                    max={11}
                    value={inches}
                    onChange={handleInchesChange}
                    sliders={2}
                    color="#BD1550"
                  />
                </div>
                <div className="w-24 rounded-lg overflow-hidden font-mono font-extrabold">
                  <div className="h-1/2 bg-[#547980] center text-white">
                    {feet} ft
                  </div>
                  <div className="h-1/2 bg-[#bd1550] center text-white">
                    {inches} in
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="!w-24 flex items-center">
            <Toggle
              id="units"
              text1="cm"
              text2="ft"
              value={isCm}
              onChange={() => setIsCm(!isCm)}
            />
          </div>
          <hr className="mt-4" />
          <button
            className={`${
              isDisabled ? "button-disabled" : "button"
            }  w-full mt-5`}
            onClick={setDemographic}
            disabled={isDisabled}
          >
            Save
          </button>
          {height?.normalized && (
            <button className="button-cancel w-full mt-5" onClick={unshare}>
              Unshare
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default HeightModal;
