import { useState } from "react";
import { useDispatch } from "react-redux";
import Cropper from "react-cropper";
import { useQuery } from "@tanstack/react-query";

import Modal from "../../../common/Modal";
import FileUpload from "./FileUpload";
import { blobToFile, dataURLToBlob } from "../../../../../../utils/functions";
import { closeModal } from "../../../../../../store/features/modalSlice";
import Loading from "../../../../../Loading";
import { toaster } from "../../../../../../utils/toast";
import { getMe, updateProfile } from "../../../../../../api/profileApi";

const ProfileBannerImage = () => {
  const dispatch = useDispatch();

  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState<any>();
  const [fileDataUrl, setFileDataUrl] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [changing, setChanging] = useState(false);

  const { data } = useQuery({
    queryKey: ["getMe", isUpdated],
    queryFn: async () => {
      return getMe()
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return null;
        });
    },
  });

  const resetModal = () => {
    dispatch(closeModal());
  };

  const cropImage = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleUpload = async () => {
    if (cropData) {
      const blob = dataURLToBlob(cropData);
      const newFile = blobToFile(blob, "header.png");
      await updateProfile({ type: "header", image: newFile })
        .then(() => {
          setLoading(false);
          setUpdated(true);
          toaster({ text: "Succesfully updated profile picture" });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleClear = async () => {
    await updateProfile({ type: "header" })
      .then(() => {
        setLoading(false);
        setUpdated(true);
        toaster({ text: "Succesfully removed profile picture" });
        resetModal();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (!fileDataUrl) {
    return (
      <Modal title="Profile Banner Image">
        <div className="center">
          {data?.header && !changing ? (
            <div className="center flex-col">
              <img src={data.header} width={500} alt="Banner" />
              <div>
                <button className="button-cancel mt-4" onClick={handleClear}>
                  Clear
                </button>
                <button
                  className="button mt-4"
                  onClick={() => setChanging(true)}
                >
                  Change
                </button>
              </div>
            </div>
          ) : (
            <FileUpload setFileDataUrl={setFileDataUrl} />
          )}
        </div>
      </Modal>
    );
  }

  if (!cropData)
    return (
      <Modal title="Profile Banner Image">
        <Cropper
          style={{
            height: "100%",
            width: "100%",
            minHeight: 300,
            maxWidth: 500,
            maxHeight: 500,
          }}
          zoomTo={0}
          aspectRatio={4}
          src={fileDataUrl}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={false}
        />
        <div className="flex justify-center w-full mt-4">
          <button className="button" type="button" onClick={cropImage}>
            Crop
          </button>
        </div>
      </Modal>
    );

  return (
    <Modal title="Profile Picture">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <img
            src={cropData}
            alt="preview"
            style={{
              height: "100%",
              width: "100%",
              minHeight: 150,
              maxWidth: 800,
              maxHeight: 200,
            }}
          />
          {isUpdated ? (
            <div className="flex justify-center mt-3">
              <button className="button" onClick={resetModal}>
                Close
              </button>
            </div>
          ) : (
            <div className="flex justify-around mt-3">
              <button className="button-cancel" onClick={() => setCropData("")}>
                Cancel
              </button>
              <button className="button" onClick={handleUpload}>
                Save
              </button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default ProfileBannerImage;
