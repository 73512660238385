import axios from "axios";
import Cookies from "js-cookie";

import { Choice } from "../types";
import { COOKIE_TOKEN } from "../constants/strings";
import { QuestionType } from "../types/enums";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const API_ROUTE = `${DOMAIN}/api/v1`;
const QUESTION_ROUTE = `${API_ROUTE}/question`;

interface NewQuestionData {
  title: string;
  description: string;
  questionType: string;
  private: boolean;
  groups: Array<string> | null;
  choices?: Array<Choice>;
  files?: FileList;
}

export const createNewQustion = async (data: NewQuestionData) => {
  const formData = new FormData();

  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("questionType", data.questionType);
  formData.append("private", data.private.toString());

  if (data.choices) {
    formData.append("choices", JSON.stringify(data.choices));
  }

  if (data.files) {
    Array.from(data.files).forEach((file) => {
      formData.append("files", file);
    });
  }

  return await axios({
    method: "POST",
    url: `${QUESTION_ROUTE}/create`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const deleteQuestion = async (questionId: string) => {
  return await axios({
    method: "DELETE",
    url: `${QUESTION_ROUTE}/delete`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: { questionId },
  });
};

export const searchQuestion = async (searchTerm: string) => {
  return await axios({
    method: "POST",
    url: `${QUESTION_ROUTE}/search`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: { searchTerm },
  });
};

export const getPersonalizedFeed = async (
  page: number,
  feedType?: string,
  username?: string
) => {
  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/personalized`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params: {
      page,
      ...(feedType && { feedType }),
      ...(username && { username }),
    },
  });
};

type FilterItem = {
  filter: string;
  value: string;
  label: string;
};

const buildParamsFromFilters = (
  filters: FilterItem[]
): Record<string, string> => {
  return filters.reduce((acc, { filter, label }) => {
    // Convert the filter to lower case to match the params structure
    if (
      ["gender", "ethnicity", "orientation", "religion", "politics"].includes(
        filter
      )
    ) {
      acc[filter] = label;
    }
    return acc;
  }, {} as Record<string, string>);
};

export const getQuestion = async (
  questionId: string,
  filters: Array<FilterItem>
) => {
  const params = {
    questionId,
    ...buildParamsFromFilters(filters),
  };

  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/get`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params,
  });
};

interface VoteData {
  questionId: string;
  questionType: QuestionType;
  choiceId?: string;
  rating?: number;
  items?: Array<string>;
}

export const voteOnQuestion = async (data: VoteData) => {
  return await axios({
    method: "POST",
    url: `${QUESTION_ROUTE}/vote`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      questionId: data.questionId,
      questionType: data.questionType,
      ...(data.choiceId && { choiceId: data.choiceId }),
      ...(data.rating && { rating: data.rating }),
      ...(data.items && { items: data.items }),
    },
  });
};

export const likeQuestion = async (id: string) => {
  return await axios({
    method: "POST",
    url: `${QUESTION_ROUTE}/like`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      questionId: id,
    },
  });
};

interface CommentData {
  questionId: string;
  text: string;
  parentCommentId?: string;
}
export const commentOnQuestion = async (data: CommentData) => {
  return await axios({
    method: "POST",
    url: `${QUESTION_ROUTE}/comment`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data,
  });
};

interface GetCommentData {
  questionId?: string;
  commentId?: string;
}

export const getComments = async (data: GetCommentData) => {
  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/comments`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params: {
      ...(data.questionId && { questionId: data.questionId }),
      ...(data.commentId && { commentId: data.commentId }),
    },
  });
};

export const deleteComment = async (commentId: string) => {
  return await axios({
    method: "DELETE",
    url: `${QUESTION_ROUTE}/deleteComment`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      commentId,
    },
  });
};

interface LikeCommentProps {
  commentId: string;
  action: string;
}

export const voteComment = async ({ commentId, action }: LikeCommentProps) => {
  return await axios({
    method: "Post",
    url: `${QUESTION_ROUTE}/voteComment`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      commentId,
      action,
    },
  });
};

export const getQuestionOfTheDay = async () => {
  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/qotd`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const getPopularQuestions = async () => {
  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/popular`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};
