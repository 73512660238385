import { useEffect, useState } from "react";
import { GroupedAgeResult, GroupedHeightResult } from "../utils/math";

const useRangeSetter = (
  interval: number,
  ranges: { [key: string]: Record<string, number> } | undefined,
  groupingFunction: (
    data: any,
    interval: number
  ) => GroupedAgeResult | GroupedHeightResult
) => {
  const [newRanges, setRanges] = useState<
    GroupedAgeResult | GroupedHeightResult
  >({});
  useEffect(() => {
    if (ranges) {
      setRanges(groupingFunction(ranges, interval));
    }
  }, [interval, ranges, groupingFunction]);

  return { newRanges };
};

export default useRangeSetter;
