import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Modal from "../../common/Modal";
import { closeModal } from "../../../../../store/features/modalSlice";
import { COOKIE_TOKEN } from "../../../../../constants/strings";
import { terminate } from "../../../../../api/authApi";
import { toaster } from "../../../../../utils/toast";

const TerminateModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetModal = () => {
    dispatch(closeModal());
  };

  const terminateAccount = async () => {
    terminate()
      .then(() => {
        Cookies.remove(COOKIE_TOKEN);
        dispatch(closeModal());
        toaster({ text: "Account terminated" });
        navigate("/");
      })
      .catch(() => {
        toaster({ text: "Error terminating account" });
      });
  };

  return (
    <Modal title="Terminate Account">
      <div className="max-w-[500px]">
        <p className="text-sm">
          Are you sure you would like to terminate your account? This action
          cannot be undone.
        </p>
        <br />
        <p className="text-xs text-gray-700">
          *All questions will be deleted
          {/*, but question data may remain in the
          form of snapshots*/}
          .
        </p>
      </div>
      <div className="flex justify-around mt-5">
        <button className="button" onClick={resetModal}>
          Cancel
        </button>
        <button className="button-cancel" onClick={terminateAccount}>
          Terminate
        </button>
      </div>
    </Modal>
  );
};

export default TerminateModal;
