import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdMore } from "react-icons/io";
import { FaCamera } from "react-icons/fa6";
import { IoFlag } from "react-icons/io5";
import { ImBlocked } from "react-icons/im";

import QuestionHeaderMenuItem from "../QuestionHeaderMenuItem";
import {
  setBlockQuestionId,
  setBlockUserId,
  setDeleteId,
  setModal,
  setRefId,
} from "../../../../store/features/modalSlice";
import * as strings from "../../../../constants/strings";
import { formatDistanceToNow } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticate } from "../../../../api/authApi";
import { errorCatcher } from "../../../../utils/functions";
import { FaTrashAlt } from "react-icons/fa";
import { selectUser } from "../../../../store/features/authSlice";
import { Author } from "../../../../types";
import useHandleOutslideClick from "../../../../hooks/useHanldeOutsideClick";
import { MdFilterList } from "react-icons/md";

interface QuestionHeaderProps {
  id: string;
  author: Author;
  createdAt: string;
  headerRef: any;
  preview?: boolean;
  disabled?: boolean;
  flip: boolean;
  setFiltering?: Dispatch<SetStateAction<boolean>>;
}

const QuestionHeader = ({
  id,
  author,
  createdAt,
  headerRef,
  preview,
  flip,
  setFiltering,
}: QuestionHeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userId, premium } = useSelector(selectUser);

  const menuRef = useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const currentPath = location.pathname;
  const onBackPage = currentPath.includes(`/question`);

  const setModalState = (state: string) => {
    dispatch(setModal({ isModalOpen: true, modalState: state }));
  };

  const handleButtonClick = () => {
    setMenuOpen(!menuOpen);
  };

  const goToProfile = () => {
    authenticate()
      .then(() => {
        navigate(`/profile/${author.username}`);
      })
      .catch((error) => {
        errorCatcher(error, "Must be logged in to view profiles");
      });
  };

  const reportQuestion = async () => {
    setMenuOpen(false);
    dispatch(setRefId(id));
    setModalState(strings.REPORT_QUESTION_MODAL);
  };

  const blockQuestion = async () => {
    setMenuOpen(false);
    dispatch(setModal({ blockQuestionId: id, blockUserId: author._id }));
    setModalState(strings.BLOCK_QUESTIONS_MODAL);
  };

  const deleteQuestion = () => {
    setMenuOpen(false);
    dispatch(setDeleteId(id));
    setModalState(strings.DELETE_QUESTION_MODAL);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };

  const timeAgo = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

  useHandleOutslideClick(menuOpen, handleClickOutside);

  return (
    <div className="w-full" ref={headerRef}>
      <div className="question-header">
        <div className="user-info flex flex-row items-center">
          <div
            className="text-sm font-semibold cursor-pointer"
            onClick={goToProfile}
          >
            {author?.username || <i>Unknown</i>}
          </div>
          <span className="mx-2">&#x2022;</span>
          <div className="text-xs">{timeAgo}</div>
        </div>
        <div className="flex">
          {flip && premium && (
            <button
              className="mr-6"
              onClick={() => {
                setFiltering && setFiltering((prev) => !prev);
              }}
            >
              <MdFilterList size={24} className="text-icon" />
            </button>
          )}
          <button disabled={preview} onClick={handleButtonClick}>
            <IoMdMore size={24} className="text-icon" />
          </button>
        </div>
        {menuOpen && (
          <div className="header-menu-container">
            <div ref={menuRef} className="header-menu-element">
              <div className="py-1">
                {onBackPage && premium && (
                  <QuestionHeaderMenuItem
                    handleMenuItemClick={handleMenuItemClick}
                    Icon={FaCamera}
                    text="Snapshot"
                  />
                )}
                {author._id !== userId && (
                  <>
                    <QuestionHeaderMenuItem
                      handleMenuItemClick={reportQuestion}
                      Icon={IoFlag}
                      text="Report"
                      size={16}
                      red
                    />
                    <QuestionHeaderMenuItem
                      handleMenuItemClick={blockQuestion}
                      Icon={ImBlocked}
                      text="Block"
                      red
                    />
                  </>
                )}
                {author._id === userId && (
                  <QuestionHeaderMenuItem
                    handleMenuItemClick={deleteQuestion}
                    Icon={FaTrashAlt}
                    text="Delete"
                    red
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionHeader;
