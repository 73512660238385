import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../common/Modal";
import {
  unshareDemographics,
  updateDemographics,
} from "../../../../../api/demographicsApi";
import { toaster } from "../../../../../utils/toast";
import { selectUser } from "../../../../../store/features/authSlice";
import {
  selectDemographics,
  setAllDemographics,
} from "../../../../../store/features/demographicsInfoSlice";
import { closeModal } from "../../../../../store/features/modalSlice";
import "./ageModal.css";
import { format, parseISO } from "date-fns";

interface AgeModalProps {
  modalRef: any;
}

const AgeModal = ({ modalRef }: AgeModalProps) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);
  const { dob: oldDob } = useSelector(selectDemographics);

  const [dob, setDob] = useState(oldDob?.split("T")[0]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDob(e.target.value);
  };

  const formatDate = () => {
    try {
      const date = parseISO(String(dob));
      const formattedDate = format(date, "EEEE, MMMM do, yyyy");
      return formattedDate;
    } catch (error) {
      return null;
    }
  };

  const setDemographic = () => {
    if (dob) {
      updateDemographics({ userId, dob })
        .then(() => {
          dispatch(setAllDemographics({ dob }));
          dispatch(closeModal());
        })
        .catch((error) => {
          console.error(error.response.data.message);
          toaster({ text: error.response.data.message });
        });
    }
  };

  const unshare = () => {
    unshareDemographics({ userId, field: "dob" })
      .then(() => {
        dispatch(setAllDemographics({ dob: "" }));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster({ text: error.response.data.message });
      });
  };

  return (
    <div ref={modalRef}>
      <Modal title="Date of Birth">
        <input
          className="age-input"
          type="date"
          value={String(dob)}
          onChange={onChange}
        />
        <div className="center mt-4">{formatDate()}</div>
        <button
          className={`${false ? "button-disabled" : "button"}  w-full mt-5`}
          onClick={setDemographic}
          disabled={false}
        >
          Save
        </button>
        {oldDob && (
          <button className="button-cancel w-full mt-5" onClick={unshare}>
            Unshare
          </button>
        )}
      </Modal>
    </div>
  );
};

export default AgeModal;
