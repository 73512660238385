import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import { closeModal, selectModal } from "../../../../store/features/modalSlice";
import { createReport } from "../../../../api/privacyApi";
import * as enums from "../../../../types/enums";

interface ReportQuestionModalProps {
  modalRef: any;
}

const ReportQuestionModal = ({ modalRef }: ReportQuestionModalProps) => {
  const dispatch = useDispatch();

  const [reason, setReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const { refId } = useSelector(selectModal);

  const resetModal = () => {
    dispatch(closeModal());
  };

  const clickReason = (_reason: string) => {
    setReason(_reason);
  };

  const submitReport = () => {
    createReport({
      reportType: enums.ReportType.QUESTION,
      refId: refId!,
      reason: reason || customReason,
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div ref={modalRef} className="card w-[440px] min-w-[400px]">
      <div className="flex justify-between mb-3">
        <div className="text-lg font-semibold">Submit a report</div>
        <button onClick={resetModal}>
          <IoMdClose />
        </button>
      </div>
      {!submitted ? (
        <>
          <div>
            Thanks for looking out for yourself and our users by reporting
            things that break rules. Tell use what is happening and we will look
            into it.
          </div>
          <div>
            {Object.values(enums.ReportReasons).map((r) => {
              return (
                <button
                  key={r}
                  className={`my-2 ${
                    reason === r ? "button !bg-blue-700" : "button"
                  }`}
                  onClick={() => clickReason(r)}
                >
                  {r}
                </button>
              );
            })}
            <input
              className="input mb-4"
              placeholder="Other, please specify..."
              value={customReason}
              onChange={(e) => {
                setReason("");
                setCustomReason(e.target.value);
              }}
            />
          </div>
          <button
            className={`${
              reason || customReason.length > 0 ? "button" : "button-disabled"
            } w-full !mx-0`}
            disabled={!reason && !customReason}
            onClick={submitReport}
          >
            Submit
          </button>
        </>
      ) : (
        <div>
          Thank you for your submission. Someone will look into this shortly
        </div>
      )}
    </div>
  );
};

export default ReportQuestionModal;
