import tinycolor from "tinycolor2";
import { Fragment } from "react/jsx-runtime";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { IAgeRange } from "../../../../utils/math";

interface VerticalBarsProps {
  data: IAgeRange;
  height?: number; // This will now represent the total height of the chart
  hoverKey?: string | null;
  handleMouseEnter?: (key: string) => void;
  handleMouseLeave?: () => void;
  colorMap: any;
  range: string;
}

const VerticalBars = ({
  data,
  height = 240,
  hoverKey,
  handleMouseEnter,
  handleMouseLeave,
  colorMap,
  range,
}: VerticalBarsProps) => {
  const total = Object.values(data).reduce((acc, val) => acc + val, 0);
  return (
    <div
      className={`flex flex-col items-end bg-gray-200 h-full rounded-lg overflow-hidden`}
      style={{ height: `${height}px`, width: 20 }}
    >
      {Object.entries(data)
        .sort(([aKey], [bKey]) => {
          if (aKey > bKey) return 1;
          if (aKey < bKey) return -1;
          return 0;
        })
        .map(([key, value]) => {
          const percentage = (value / total) * 100;

          const isHovered = hoverKey === key;
          const backgroundColor = isHovered
            ? tinycolor(colorMap[key]).lighten(20).toString()
            : colorMap[key];

          return (
            <Fragment key={`${key}-${range}`}>
              <div
                className="center"
                data-tooltip-id={`${key}-${range}`}
                style={{
                  height: `${percentage}%`,
                  backgroundColor,
                  width: "100%",
                }}
                onMouseEnter={() => handleMouseEnter && handleMouseEnter(key)}
                onMouseLeave={handleMouseLeave}
              >
                <span>{value}</span>
              </div>
              <ReactTooltip
                id={`${key}-${range}`}
                content={`${percentage.toFixed(2)}%`}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default VerticalBars;
