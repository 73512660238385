import LeftPanelContent from "../feed/LeftPanel/LeftPanelContent";

interface LeftPanelDrawerProps {
  modalRef: any;
}

const LeftPanelDrawer = ({ modalRef }: LeftPanelDrawerProps) => {
  return (
    <div
      className="absolute left-0 bg-white dark:bg-gray-800 h-full min-w-[280px]"
      ref={modalRef}
    >
      <LeftPanelContent />
    </div>
  );
};

export default LeftPanelDrawer;
