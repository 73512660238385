import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getQuestion } from "../../../api/questionApi";
import { selectUser } from "../../../store/features/authSlice";
import { useSelector } from "react-redux";
import LineBreak from "../../../utils/LineBreak";
import CommentSection from "../../feed/Question/Comments/CommentSection";
import Question from "../../feed/Question";
import Comment from "../../feed/Question/Comments/NewComment";
import { FilterItem, TComment } from "../../../types";
import Loading from "../../Loading";
import { useState } from "react";

interface QuestionPageQuestionProps {
  backPage: boolean;
  comments: Array<TComment>;
  reset: () => void;
}

const QuestionPageQuestion = ({
  backPage,
  comments = [],
  reset,
}: QuestionPageQuestionProps) => {
  const { userId } = useSelector(selectUser);

  const { questionId } = useParams<{ questionId: string }>();

  const [filters, setFilters] = useState<Array<FilterItem>>([]);

  const { isLoading, data: questionData } = useQuery({
    queryKey: ["getQuestion", questionId, userId, filters],
    queryFn: () =>
      getQuestion(questionId as string, filters)
        .then((response) => {
          return response.data.data;
        })
        .catch(),
  });

  if (isLoading) return <Loading />;

  if (questionData)
    return (
      <Question
        id={questionData._id}
        author={questionData.author}
        createdAt={questionData.createdAt}
        questionType={questionData.questionType}
        title={questionData.title}
        description={questionData.description}
        image={questionData.image}
        answers={questionData.answers}
        myAnswer={questionData.myAnswer}
        liked={questionData.liked}
        likes={questionData.likes}
        comments={questionData.comments}
        choices={questionData.choices}
        votes={questionData.votes}
        flipped={backPage}
        setFilters={setFilters}
      >
        <LineBreak />
        <Comment questionId={questionData._id} reset={reset} />
        {comments.length === 0 && (
          <div className="center mt-5">No comments yet. Start a discussion</div>
        )}
        <CommentSection
          questionId={questionData._id}
          comments={comments}
          reset={reset}
        />
      </Question>
    );

  return null;
};

export default QuestionPageQuestion;
