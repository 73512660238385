import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";

import { RatingAnswers } from "../../../../../types/answers";
import SliderButtons from "../SliderButtons";
import RatingSlide from "./RatingSlide";
import ShareToSee from "../AnswerToSee";
import Mapbox from "./Mapbox";
import OverallData from "./OverallData";
import AgeData from "./AgeData";
import HeightData from "./HeightData";
import { getDemographics } from "../../../../../api/demographicsApi";
import { selectUser } from "../../../../../store/features/authSlice";
import "swiper/css";

interface RatingDataProps {
  answers: RatingAnswers;
  hasAnswer: boolean;
}

const RatingData = ({ answers, hasAnswer }: RatingDataProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { userId } = useSelector(selectUser);

  const [, setInit] = useState(false);
  const [active, setActive] = useState(0);

  const { data } = useQuery({
    queryKey: ["getDemographics", userId],
    queryFn: () =>
      getDemographics(userId).then((response) => {
        const data = response.data.data;
        return data;
      }),
    enabled: !!userId,
  });

  useEffect(() => {
    if (swiperRef.current) swiperRef.current?.swiper.slideTo(active);
  }, [active]);

  return (
    <>
      <SliderButtons
        active={active}
        setActive={setActive}
        pages={9}
        prevRef={prevRef}
        nextRef={nextRef}
      />
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        className="max-w-[800px] my-6"
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={() => setInit(true)}
        onSlideChange={(e: any) => setActive(e.realIndex)}
        allowTouchMove={false}
        autoHeight
      >
        <SwiperSlide>
          {hasAnswer ? (
            <OverallData answers={answers} />
          ) : (
            <div className="center">No results at this time</div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.dob} demographic="height">
            <AgeData answers={answers} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.location} demographic="location">
            <Mapbox locationData={answers?.location} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.gender} demographic="gender">
            <RatingSlide answers={answers} type={"gender"} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.ethnicity} demographic="race">
            <RatingSlide answers={answers} type={"ethnicity"} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee
            shared={!!data?.orientation}
            demographic="sexual orientation"
          >
            <RatingSlide answers={answers} type={"orientation"} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.religion} demographic="religion">
            <RatingSlide answers={answers} type={"religion"} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.politics} demographic="political leaning">
            <RatingSlide answers={answers} type={"politics"} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.height} demographic="height">
            <HeightData answers={answers} />
          </ShareToSee>
        </SwiperSlide>
      </Swiper>
      <div className="mt-6" />
    </>
  );
};

export default RatingData;
