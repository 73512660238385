import { Dispatch, SetStateAction, useState } from "react";
import { Form, Formik } from "formik";
import { FiUnlock } from "react-icons/fi";

import FormikInputField from "../../utils/FormikInputField";
import LineBreak from "../../utils/LineBreak";
import { ModalState } from "./AuthModal";
import { forgotPassword } from "../../api/authApi";
import { toaster } from "../../utils/toast";
import { FaCheckCircle } from "react-icons/fa";

interface ForgotPassswordModalProps {
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

const ForgotPasswordModal = ({ setModalState }: ForgotPassswordModalProps) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      {!submitted ? (
        <>
          <div className="center flex-col p-2">
            <div className="border-2 border-black rounded-full w-fit p-4 mt-12">
              <FiUnlock size={50} />
            </div>
            <div className="center text-lg font-semibold mt-6">
              Trouble logging in?
            </div>
            <p className="mt-6 text-center">
              Enter your email or username and we'll send you a like to get you
              back into your account.
            </p>
            <Formik
              initialValues={{
                emailOrUsername: "",
              }}
              onSubmit={(values) => {
                forgotPassword(values.emailOrUsername)
                  .then(() => setSubmitted(true))
                  .catch((error) => {
                    toaster({ text: error.response.data.message });
                  });
              }}
            >
              {({ errors }) => (
                <Form className="center flex-col">
                  <FormikInputField
                    name="emailOrUsername"
                    autocomplete="email"
                    error={errors.emailOrUsername}
                  />
                  <button className="button mt-4" type="submit">
                    Send login link
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <LineBreak text="or" />
          <button
            className="mb-5"
            type="button"
            onClick={() => setModalState(ModalState.SIGN_UP)}
          >
            Create new account
          </button>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <FaCheckCircle color="#6fc276" size={100} />
          <p className="mt-4 text-center">
            Password reset link sent to your email inbox. Follow the
            instructions to reset your password
          </p>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordModal;
