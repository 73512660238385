import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/authSlice";
import modalReducer from "./features/modalSlice";
import settingsReducer from "./features/settingsSlice";
import demographicsReducer from "./features/demographicsInfoSlice";
import feedSlice from "./features/feedSlice";
import privacySlice from "./features/privacySlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    demographics: demographicsReducer,
    settings: settingsReducer,
    feed: feedSlice,
    privacy: privacySlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
