import { Dispatch, SetStateAction, useRef, useState } from "react";
import { AiFillPicture } from "react-icons/ai";

interface FileUploadProps {
  setFileDataUrl: Dispatch<SetStateAction<string | null>>;
}

const FileUpload = ({ setFileDataUrl }: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const files = event.dataTransfer.files;
    const syntheticEvent: React.ChangeEvent<HTMLInputElement> = {
      target: {
        files: files,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleFileChange(syntheticEvent);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      readFileAsDataUrl(file).then((dataUrl) => {
        setFileDataUrl(dataUrl);
      });
    }
  };

  const readFileAsDataUrl = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result && typeof event.target.result === "string") {
          resolve(event.target.result);
        } else {
          reject(new Error("Failed to read file as Data URL"));
        }
      };
      reader.onerror = () => reject(new Error("Error reading file"));
      reader.readAsDataURL(file);
    });
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={() => {
        inputRef.current?.click();
      }}
      style={{
        backgroundColor: isDragOver ? "lightblue" : "none",
        border: `4px dashed #4276b2`,
        borderRadius: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: 8,
        paddingRight: 15,
        margin: 15,
        marginTop: 0,
      }}
    >
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
      <AiFillPicture size={28} color="#3b82f6" />
      Click or Drag Image
    </div>
  );
};

export default FileUpload;
