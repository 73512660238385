import { Field, FieldProps } from "formik";
import { startCase, kebabCase } from "lodash";

interface FormikInputFieldProps {
  name: string;
  type?: string;
  placeholder?: string;
  autocomplete?: string;
  error?: string;
  validate?: () => void;
}

const FormikInputField = ({
  name,
  type,
  placeholder,
  autocomplete,
  error,
  validate,
}: FormikInputFieldProps) => {
  return (
    <>
      <Field name={name} validate={validate}>
        {(props: FieldProps) => (
          <>
            {type === "textarea" ? (
              <textarea
                id={name}
                rows={5}
                className={error ? "textarea-error" : "textarea"}
                placeholder={placeholder ? placeholder : startCase(name)}
                autoComplete={autocomplete ? autocomplete : kebabCase(name)}
                {...props.field}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              <input
                type={type ? type : "text"}
                id={name}
                className={error ? "input-error" : "input"}
                placeholder={placeholder ? placeholder : startCase(name)}
                autoComplete={autocomplete ? autocomplete : kebabCase(name)}
                {...props.field}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            )}
            <div className="pl-1 mt-1 mb-4 text-red-600">{error}</div>
          </>
        )}
      </Field>
    </>
  );
};

export default FormikInputField;
