import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import {
  acceptRequest,
  denyRequest,
  getFollowRequests,
  getFollowers,
} from "../../../api/followApi";
import noAvatar from "../../../assets/noAvatar.png";
import noBanner from "../../../assets/noBanner.png";
import Accordion from "../../../utils/Accordion";
import { toaster } from "../../../utils/toast";
import { authenticate } from "../../../api/authApi";

import { errorCatcher } from "../../../utils/functions";

type FollowRequest = {
  _id: string;
  from: {
    username: string;
    createdAt: string;
    stats: {
      followers: number;
      following: number;
      asked: number;
      answer: number;
    };
  };
};

const Followers = () => {
  const navigate = useNavigate();

  const [requestFeed, setRequestFeed] = useState<Array<FollowRequest>>([]);
  const [acceptedFollow, setAcceptedFollow] = useState(false);

  const { data: followRequestData } = useQuery({
    queryKey: ["getFollowRequests", acceptedFollow],
    queryFn: () => {
      return getFollowRequests().then((response) => {
        const data = response.data;
        setRequestFeed(data.requests);
        return data;
      });
    },
  });

  const goToProfile = (username: string) => {
    authenticate()
      .then(() => {
        navigate(`/profile/${username}`);
      })
      .catch((error) => {
        errorCatcher(error, "Must be logged in to view profiles");
      });
  };

  const { data: followData } = useQuery({
    queryKey: ["getFollowers", acceptedFollow],
    queryFn: () => {
      return getFollowers()
        .then((response) => {
          const data = response.data;
          return data;
        })
        .catch((error) => console.error(error));
    },
  });

  const removeRequest = (idToRemove: string) => {
    setRequestFeed((prev) =>
      prev.filter((request) => request._id !== idToRemove)
    );
  };

  const acceptFollowRequest = async (id: string) => {
    await acceptRequest(id)
      .then(() => {
        removeRequest(id);
        setAcceptedFollow(!acceptedFollow);
      })
      .catch(() => toaster({ text: "Error accepting request" }));
  };

  const declineFollowRequest = async (id: string) => {
    await denyRequest(id)
      .then(() => removeRequest(id))
      .catch(() => toaster({ text: "Error declining request" }));
  };

  return (
    <>
      {followRequestData?.requests?.length > 0 && (
        <Accordion
          text="New Follow Requests"
          count={followRequestData?.requests?.length}
        >
          <div className="grid grid-cols-2 gap-4">
            {requestFeed ? (
              requestFeed.map((request: any) => {
                return (
                  <div key={request.from._id} className="flex">
                    <div className="flex flex-col">
                      <div className="relative">
                        <img
                          src={
                            request.from.headerImage
                              ? request.from.headerImage
                              : noBanner
                          }
                          alt="banner"
                          height="auto"
                          style={{ width: "100%", height: "auto" }}
                          className="rounded-lg"
                        />
                        <img
                          className="absolute left-[2%] top-[16.6%] w-1/6 h-4/6 rounded-full border-2 border-black"
                          src={
                            request.from.avatar ? request.from.avatar : noAvatar
                          }
                          alt="avatar"
                        />
                      </div>
                      <div className="flex text-sm">
                        <div className="w-1/3">
                          <div className="font-semibold">
                            {request.from.username}
                          </div>
                          <div className="text-xs text-gray-500">
                            {formatDistanceToNow(new Date(request.createdAt), {
                              addSuffix: true,
                            })}
                          </div>
                        </div>
                        <div className="w-1/3">
                          <div>Asked: {request.from.stats.asked}</div>
                          <div>Answered: {request.from.stats.answered}</div>
                        </div>
                        <div className="w-1/3">
                          <div>Following: {request.from.stats.following}</div>
                          <div>Followers: {request.from.stats.followers}</div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col justify-around items-center w-full mx-3">
                      <button
                        className="flex"
                        onClick={() => acceptFollowRequest(request._id)}
                      >
                        <FaCheckCircle size={30} color="#b8dc8b" />
                      </button>
                      <button
                        className="flex"
                        onClick={() => declineFollowRequest(request._id)}
                      >
                        <FaTimesCircle size={30} color="#f46562" />
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No new follow Requests</div>
            )}
          </div>
        </Accordion>
      )}
      <div className="grid grid-cols-2">
        {followData?.followers.length > 0 ? (
          followData?.followers.map((follower: any) => {
            return (
              <div
                key={follower.follower._id}
                className="flex cursor-pointer"
                onClick={() => goToProfile(follower.follower.username)}
              >
                <div className="flex flex-col">
                  <div className="relative w-full">
                    <img
                      src={
                        follower?.follower.headerImage
                          ? follower.follower.headerImage
                          : noBanner
                      }
                      alt="banner"
                      height="auto"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      className="rounded-lg"
                    />
                    <img
                      className="absolute left-[2%] top-[16.6%] w-1/6 h-4/6 rounded-full border-2 border-black"
                      src={
                        follower.follower.avatar
                          ? follower.follower.avatar
                          : noAvatar
                      }
                      alt="avatar"
                    />
                  </div>
                  <div className="flex text-sm">
                    <div className="w-1/3 truncate">
                      <div className="font-semibold truncate">
                        {follower.follower.username}
                      </div>
                      <div className="text-xs text-gray-500">
                        {formatDistanceToNow(new Date(follower.createdAt), {
                          addSuffix: true,
                        })}
                      </div>
                    </div>
                    <div className="w-1/3 truncate">
                      <div>Asked: {follower.follower.stats.asked}</div>
                      <div>Answered: {follower.follower.stats.answered}</div>
                    </div>
                    <div className="w-1/3 truncate">
                      <div>Following: {follower.follower.stats.following}</div>
                      <div>Followers: {follower.follower.stats.followers}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="center mt-4">
            No one is following you at this time
          </div>
        )}
      </div>
    </>
  );
};

export default Followers;
