import { useState } from "react";
import { useLocation } from "react-router-dom";

import LeftPanel from "../../feed/LeftPanel";
import Account from "./Account";
import Demographics from "./Demographics";
import Privacy from "./Privacy";
import Prefrences from "./Preferences";
import * as strings from "../../../constants/strings";
import { settingsMenu } from "../../../constants/menus";
import "./settings.css";

const Settings = () => {
  const location = useLocation();
  const { state } = location;

  const [page, setPage] = useState(state?.page || strings.SETTINGS_ACCOUNT);

  return (
    <div className="flex flex-row">
      <LeftPanel />
      <div className="bg-white dark:bg-gray-800 h-screen p-8 pb-20 overflow-scroll w-full">
        <div className="text-lg font-semibold">Settings</div>
        <div className="flex flex-row overflow-scroll scrollbar-hidden">
          {settingsMenu.map((setting) => {
            return (
              <button
                key={setting}
                className={`text-sm settings-button ${
                  page !== setting && "text-[#576F76]"
                }`}
                onClick={() => setPage(setting)}
              >
                {setting}
              </button>
            );
          })}
        </div>
        {page === strings.SETTINGS_ACCOUNT && <Account />}
        {page === strings.SETTINGS_DEMOGRAPHICS && <Demographics />}
        {page === strings.SETTINGS_PRIVACY && <Privacy />}
        {page === strings.SETTIGNS_PREFERENCES && <Prefrences />}
      </div>
    </div>
  );
};

export default Settings;
