import { Dispatch, SetStateAction, useState } from "react";
import { Rating as ReactRating } from "react-simple-star-rating";
import { GiRoundStar } from "react-icons/gi";

interface RatingsDisplayProps {
  previouslySelected: number | undefined;
  image?: string;
  setSelectedRating: Dispatch<SetStateAction<number>>;
}

const RatingDisplay = ({
  previouslySelected,
  image,
  setSelectedRating,
}: RatingsDisplayProps) => {
  const [rating, setRating] = useState(0);

  const starColor = () => {
    if (previouslySelected) return "#51aff7";
    else return "#ffbc0b";
  };

  return (
    <div className="center flex flex-col">
      {image && (
        <img
          src={image}
          alt={`Preview`}
          style={{
            maxWidth: "60%",
            maxHeight: "300px",
            objectFit: "cover",
            margin: "5px",
            borderRadius: 8,
          }}
        />
      )}
      <ReactRating
        className="my-4"
        onClick={(e) => {
          setRating(e);
          setSelectedRating(e);
        }}
        style={previouslySelected ? { cursor: "default" } : {}}
        size={30}
        emptyIcon={<GiRoundStar size={30} />}
        fillIcon={<GiRoundStar color={starColor()} size={30} />}
        initialValue={previouslySelected || rating}
        iconsCount={10}
        emptyStyle={{ display: "flex" }}
        fillStyle={{ display: "-webkit-inline-box" }}
        allowHover={!previouslySelected}
      />
    </div>
  );
};

export default RatingDisplay;
