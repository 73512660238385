import { useState } from "react";

import Modal from "../../common/Modal";
import ModalMenu from "./ModalMenu";
import { Ethnicity } from "../../../../../types/enums";

interface EthnicityModalProps {
  modalRef: any;
}

const EthnicityModal = ({ modalRef }: EthnicityModalProps) => {
  const [selection, setSelection] = useState("");

  return (
    <div ref={modalRef}>
      <Modal title="Race / Ethnicity">
        <ModalMenu
          type="ethnicity"
          valuesEnum={Ethnicity}
          selection={selection}
          setSelection={setSelection}
        />
      </Modal>
    </div>
  );
};

export default EthnicityModal;
