import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

export interface SettingsState {
  darkMode: boolean;
}

const initialState: SettingsState = {
  darkMode: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleDarkMode: (_, action: PayloadAction<SettingsState>) => {
      return { ...action.payload, darkMode: action.payload.darkMode };
    },
  },
});

export const { toggleDarkMode } = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
