import "./util.css";

interface ToggleProps {
  id: string;
  value: boolean;
  onChange: () => void;
  text1: string;
  text2: string;
}

const Toggle = ({ id, text1, text2, value, onChange }: ToggleProps) => {
  return (
    <>
      <input
        id={id}
        type="checkbox"
        className="toggle-input"
        checked={value}
        onChange={onChange}
      />
      <label htmlFor={id} className="toggle-label">
        <div className="icon w-8/12 flex justify-between">
          <div>{text1}</div>
          <div> {text2}</div>
        </div>
      </label>
    </>
  );
};

export default Toggle;
