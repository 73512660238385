import { Dispatch, SetStateAction, useEffect, useRef } from "react";

interface CommentBoxProps {
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  commenting: boolean;
  toggleCommenting: () => void;
  createComment: () => void;
}

const CommentBox = ({
  comment,
  setComment,
  commenting,
  toggleCommenting,
  createComment,
}: CommentBoxProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (commenting && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [commenting]);

  return (
    <div className="comment-textarea-container">
      <textarea
        ref={textareaRef}
        className="comment-textarea pb-9"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className="comment-area-buttons">
        <button
          className="comment-textarea-button bg-red-600"
          type="button"
          onClick={toggleCommenting}
        >
          Cancel
        </button>
        <button
          className="comment-textarea-button"
          onClick={createComment}
          type="button"
        >
          Comment
        </button>
      </div>
    </div>
  );
};

export default CommentBox;
