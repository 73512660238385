import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";

import { SETTINGS_DEMOGRAPHICS } from "../../../../constants/strings";

interface ShareToSeeProps {
  shared: boolean;
  demographic: string;
  children: ReactNode;
}

const ShareToSee = ({ shared, demographic, children }: ShareToSeeProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/settings", { state: { page: SETTINGS_DEMOGRAPHICS } });
  };

  if (!shared)
    return (
      <div className="center flex-col px-8">
        <div className="mb-3 font-semibold">{startCase(demographic)}</div>
        <div className="text-sm mb-4">
          {`Voluntarily sharing your info is how we populate these demographic
          charts. If you would like to see this data, please share your
          ${demographic} in order to see ${demographic} charts.`}
        </div>
        <button className="button" onClick={handleClick}>
          Share to See
        </button>
      </div>
    );
  return <>{children}</>;
};

export default ShareToSee;
