import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import { verifyEmail } from "../../api/authApi";

const VerifyEmail = () => {
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, setCount] = useState(10);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (success && count > -1) {
      interval = setInterval(() => {
        setCount((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (count === 0) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      verifyEmail(token)
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error.response.data.message);
          setSuccess(false);
          setLoading(false);
        });
    }
  }, [token]);

  return (
    <div className="center h-[80vh] items-center text-center">
      <div>
        {!token ? (
          <>Unknown error</>
        ) : (
          <>
            {loading ? (
              <ReactLoading type="bars" color="#ccc" />
            ) : (
              <>
                {success ? (
                  <>
                    <div className="text-lg font-semibold mb-4">
                      Email Verified
                    </div>
                    <div className="mb-5">
                      Thank you for verifying your email. You have now unlocked
                      additional access and features.
                    </div>
                    <button
                      className="button mb-5"
                      onClick={() => navigate("/")}
                    >
                      Go Home
                    </button>
                    <div className="text-sm">
                      This page will automatically redirect to the homepage in{" "}
                      {count} seconds
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-lg font-semibold mb-4">
                      Link Expired
                    </div>
                    <div>
                      Verification link expires after 30 mins and can only be
                      used once.
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
