import {} from "lodash";
import { RefObject, useEffect, useState } from "react";

export const useHeadsObserver = (ref: RefObject<HTMLDivElement>) => {
  const [activeId, setActiveId] = useState("");

  const isElementInViewport = (el: any) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const updateFirstVisibleId = () => {
    const h3Elements = document.querySelectorAll("h2, h3, h4");
    const visibleH3s = Array.from(h3Elements).filter(isElementInViewport);
    setActiveId(visibleH3s.length > 0 ? visibleH3s[0].id : "");
  };

  useEffect(() => {
    const element = ref.current;

    if (element) {
      element.addEventListener("scroll", updateFirstVisibleId);

      updateFirstVisibleId();

      return () => {
        element.removeEventListener("scroll", updateFirstVisibleId);
      };
    }
    // eslint-disable-next-line
  }, []);

  return { activeId };
};
