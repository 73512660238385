import { useDispatch } from "react-redux";

import SettingsBar from "../common/SettingsBar";
import * as menus from "../../../../constants/menus";
import { setModal } from "../../../../store/features/modalSlice";

const Account = () => {
  const dispatch = useDispatch();

  const setModalState = (modalState: string) => {
    dispatch(setModal({ isModalOpen: true, modalState }));
  };

  return (
    <div>
      <div className="font-semibold mt-4">General</div>
      {menus.accountGeneral.map((item) => {
        return (
          <SettingsBar
            key={item.text}
            text={item.text}
            modalState={item.location}
            setModalState={setModalState}
          />
        );
      })}
      <div className="font-semibold mt-4">Profile</div>
      {menus.profileGeneral.map((item) => {
        return (
          <SettingsBar
            key={item.text}
            text={item.text}
            modalState={item.location}
            setModalState={setModalState}
          />
        );
      })}
      <div className="font-semibold mt-4">Terminate</div>
      <SettingsBar
        text={menus.accountTerminate.text}
        modalState={menus.accountTerminate.location}
        setModalState={setModalState}
      />
    </div>
  );
};

export default Account;
