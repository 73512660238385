import { useState } from "react";
import { isEmpty } from "lodash";

import { ChoiceAnswers } from "../../../../../types/answers";
import { groupByAge } from "../../../../../utils/math";
import VerticalBars from "../VerticalBar";
import RangeSlider from "../../../../pages/Settings/Demographics/Modals/RangeSlider";
import useRangeSetter from "../../../../../hooks/useRangeSetter";

interface AgeDataProps {
  answers: ChoiceAnswers;
  colorMap: any;
}

export const AgeData = ({ answers, colorMap }: AgeDataProps) => {
  const [interval, setInterval] = useState(10);

  const [hoveredKey, setHoveredKey] = useState<string | null>(null);

  const handleMouseEnter = (key: string) => {
    setHoveredKey(key);
  };

  const handleMouseLeave = () => {
    setHoveredKey(null);
  };

  const { newRanges } = useRangeSetter(interval, answers?.age, groupByAge);

  return (
    <>
      {!isEmpty(answers?.age) ? (
        <>
          <div className="mb-8 font-semibold">Age</div>
          <div className="flex justify-evenly">
            {newRanges &&
              Object.entries(newRanges)
                .sort(([aKey], [bKey]) => {
                  if (aKey > bKey) return 1;
                  if (bKey > aKey) return -1;
                  return 0;
                })
                .map(([key, value]) => {
                  if (isEmpty(value)) return null;
                  return (
                    <div key={key} className="w-4">
                      <div>
                        <VerticalBars
                          data={value}
                          colorMap={colorMap}
                          hoverKey={hoveredKey}
                          handleMouseEnter={handleMouseEnter}
                          handleMouseLeave={handleMouseLeave}
                          range={key}
                        />
                      </div>
                      <div
                        className="text-sm mt-6 text-nowrap"
                        style={{ transform: "rotate(-75deg)" }}
                      >
                        {key}
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className="center mt-6">
            <div className="w-full">
              <RangeSlider
                id="age-slider"
                min={5}
                max={10}
                value={interval}
                onChange={(e) => setInterval(e)}
              />
            </div>
            <span className="text-nowrap mb-2">Age Range</span>
          </div>
        </>
      ) : (
        <div className="center">No Data to Display at this time</div>
      )}
    </>
  );
};

export default AgeData;
