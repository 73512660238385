import { useCallback, useRef, useState, KeyboardEvent, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";

import HeaderAuth from "./HeaderAuth";
import * as strings from "../../constants/strings";
import * as modalSlice from "../../store/features/modalSlice";
import { modalComponents } from "../../constants/menus";
import useHandleOutslideClick from "../../hooks/useHanldeOutsideClick";
import { IoSearchOutline } from "react-icons/io5";
import "./nav.css";

const Header = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const modalRef = useRef<HTMLDivElement>(null);

  const { isModalOpen, modalState } = useSelector(modalSlice.selectModal);

  const [searchTerm, setSearchTerm] = useState("");

  const ModalComponent = modalComponents[modalState];

  const feedPage = location.pathname === "/";

  const setModalState = (modalState: string) => {
    dispatch(modalSlice.setModal({ isModalOpen: true, modalState }));
  };

  const goHome = () => navigate("/");

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      navigate(`/?search=${searchTerm}`);
    }
  };

  const resetModal = useCallback(() => {
    dispatch(modalSlice.closeModal());
  }, [dispatch]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const refs = [modalRef];
      const isClickOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      );

      if (isClickOutside && modalState !== strings.QUESTION_MODAL_STATE) {
        resetModal();
      }
    },
    [modalState, resetModal]
  );

  useHandleOutslideClick(isModalOpen, handleClickOutside);

  return (
    <div className="flex flex-col">
      <nav className="nav">
        <div
          className={`w-full grid ${feedPage ? "grid-cols-3" : "grid-cols-2"}`}
        >
          <div className="flex">
            <button
              className="mr-3 showAll:hidden"
              onClick={() => setModalState(strings.LEFT_PANEL_DRAWER)}
            >
              <MdMenu size={24} />
            </button>
            <button
              className="text-lg font-semibold center !justify-start"
              onClick={goHome}
            >
              POLIQS
            </button>
          </div>
          {feedPage && (
            <div className="relative flex items-center w-full h-9 rounded-lg focus-within:shadow-lg bg-white overflow-hidden border-2">
              <IoSearchOutline size={20} className="mx-3 text-black" />
              <input
                className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search something.."
              />
            </div>
          )}
          <HeaderAuth />
        </div>
        {isModalOpen ? (
          <>
            <div className="center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              {ModalComponent && (
                <ModalComponent modalRef={modalRef} resetModal={resetModal} />
              )}
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </nav>
      {children}
    </div>
  );
};

export default Header;
