import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import Cookies from "js-cookie";
import * as yup from "yup";

import { login } from "../../api/authApi";
import { COOKIE_TOKEN } from "../../constants/strings";
import { UserState, setUser } from "../../store/features/authSlice";
import FormikInputField from "../../utils/FormikInputField";
import { closeModal } from "../../store/features/modalSlice";
import { toaster } from "../../utils/toast";
import LineBreak from "../../utils/LineBreak";
import GoogleButton from "./GoogleButton";
import { Dispatch, SetStateAction } from "react";
import { ModalState } from "./AuthModal";

interface LoginModalProps {
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

const LoginModal = ({ setModalState }: LoginModalProps) => {
  const dispatch = useDispatch();

  const setLoginState = (user: UserState) => {
    dispatch(setUser(user));
  };

  const resetModal = () => {
    dispatch(closeModal());
  };

  const schema = yup.object({
    emailOrUsername: yup
      .string()
      .required("Please enter an email or username!"),
    password: yup.string().required("Please enter a password!"),
  });

  return (
    <>
      <div className="center text-2xl font-semibold">Sign In</div>
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ emailOrUsername: "", password: "", rememberMe: false }}
        onSubmit={async (values) => {
          login(values.emailOrUsername, values.password, values.rememberMe)
            .then((response) => {
              const data = response.data.data;
              Cookies.set(COOKIE_TOKEN, data.token);
              setLoginState({
                userId: data.id,
                email: data.email,
                username: data.username,
                picture: data.picture,
                premium: data.premium,
                emailVerified: data.emailVerified,
              });
              resetModal();
            })
            .catch((error) => {
              console.error(error.response.data.message);
              toaster({ text: error.response.data.message });
            });
        }}
      >
        {({ errors }) => (
          <Form>
            <FormikInputField
              name="emailOrUsername"
              autocomplete="email"
              error={errors.emailOrUsername}
            />
            <FormikInputField
              name="password"
              error={errors.password}
              type="password"
              autocomplete="current-password"
            />
            <div className="flex justify-between">
              <div>
                <Field type="checkbox" name="rememberMe" />
                <span className="ml-1">Remember Me</span>
              </div>
              <button
                className="text-blue-400"
                type="button"
                onClick={() => setModalState(ModalState.FORGOT)}
              >
                Forget your password?
              </button>
            </div>
            <div className="center">
              <button className="button mt-4" type="submit">
                Sign In
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <LineBreak text="or" />
      <div className="mt-5 center">
        <GoogleButton login />
      </div>
    </>
  );
};

export default LoginModal;
