import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

import { QuestionData } from "../../../types";
import { searchQuestion } from "../../../api/questionApi";
import Content from "./Content";
import {
  useFetchFeedOnFetchLoad,
  useInitializeFeed,
  useScrollFetch,
} from "../../../hooks/FeedHooks";
import { selectUser } from "../../../store/features/authSlice";
import { COOKIE_TOKEN } from "../../../constants/strings";
import { selectFeed } from "../../../store/features/feedSlice";

interface SearchFeedProps {
  searchTerm: string;
}

const SearchFeed = ({ searchTerm }: SearchFeedProps) => {
  const { userId } = useSelector(selectUser);
  const { refreshFeed } = useSelector(selectFeed);
  const feedRef = useRef<HTMLDivElement>(null);

  const [nextPage, setNextPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [feed, setFeed] = useState<Array<QuestionData>>([]);
  const [isLoading, setLoading] = useState(true);

  const token = Cookies.get(COOKIE_TOKEN);

  useEffect(() => {
    setNextPage(1);
  }, [userId, token]);

  const loadMoreQuestions = async () => {
    setLoading(true);
    await searchQuestion(searchTerm)
      .then((response) => {
        const data = response.data.data;
        setHasNext(data.hasNext);
        setNextPage((prevPage) => prevPage + 1);
        const questions = data.questions;
        setFeed((prevFeed) => [...prevFeed, ...questions]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useInitializeFeed(setFeed, loadMoreQuestions, [
    userId,
    refreshFeed,
    searchTerm,
  ]);
  const { load, setLoad } = useScrollFetch(feedRef);
  useFetchFeedOnFetchLoad(load, setLoad, loadMoreQuestions, hasNext, nextPage);

  return <Content isLoading={isLoading} feed={feed} feedRef={feedRef} />;
};

export default SearchFeed;
