import axios from "axios";
import Cookies from "js-cookie";

import { COOKIE_TOKEN } from "../constants/strings";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const API_ROUTE = `${DOMAIN}/api/v1`;
const QUESTION_ROUTE = `${API_ROUTE}/question`;
const FOLLOWER_ROUTE = `${API_ROUTE}/follow`;

export const getProfileComments = async (filterUser: string) => {
  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/profileComments`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params: {
      username: filterUser,
    },
  });
};

export const getFollowRequests = async () => {
  return axios({
    method: "GET",
    url: `${FOLLOWER_ROUTE}/getRequests`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const getFollowing = async () => {
  return axios({
    method: "GET",
    url: `${FOLLOWER_ROUTE}/following`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const getFollowers = async () => {
  return axios({
    method: "GET",
    url: `${FOLLOWER_ROUTE}/followers`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const acceptRequest = async (requestId: string) => {
  return axios({
    method: "POST",
    url: `${FOLLOWER_ROUTE}/accept`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: { requestId },
  });
};

export const denyRequest = (requestId: string) => {
  return axios({
    method: "POST",
    url: `${FOLLOWER_ROUTE}/decline`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: { requestId },
  });
};

export const requestToFolllow = async (id: string) => {
  return await axios({
    method: "POST",
    url: `${FOLLOWER_ROUTE}/new`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: { to: id },
  });
};
