import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SettingsBar from "../common/SettingsBar";
import { getDemographics } from "../../../../api/demographicsApi";
import { selectUser } from "../../../../store/features/authSlice";
import { setModal } from "../../../../store/features/modalSlice";
import * as menus from "../../../../constants/menus";
import * as demographicsSlice from "../../../../store/features/demographicsInfoSlice";
import { errorCatcher } from "../../../../utils/functions";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../Loading";

const Demographics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(selectUser);

  const setModalState = (modalState: string) => {
    dispatch(setModal({ isModalOpen: true, modalState }));
  };

  const updateDemographicsState = ({
    dob,
    height,
    gender,
    ethnicity,
    orientation,
    religion,
    politics,
    location,
  }: demographicsSlice.DemographicsInfoState) => {
    dispatch(
      demographicsSlice.setAllDemographics({
        dob,
        height,
        gender,
        ethnicity,
        orientation,
        religion,
        politics,
        location,
      })
    );
  };

  const { isLoading } = useQuery({
    queryKey: ["getDemographics", userId],
    queryFn: () =>
      getDemographics(userId)
        .then((response) => {
          const data = response.data.data;
          updateDemographicsState({
            dob: data.dob,
            height: data.height,
            gender: data.gender,
            ethnicity: data.ethnicity,
            orientation: data.orientation,
            religion: data.religion,
            politics: data.politics,
            location: data.location,
          });
          return data;
        })
        .catch((error) => {
          errorCatcher(
            error,
            "You must be logged in to perform this action!",
            () => navigate("/")
          );
        }),
    enabled: !!userId,
  });

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="font-semibold mt-4">Physiological</div>
      {menus.demographicsMenuPhysiological.map((item) => {
        return (
          <SettingsBar
            key={item.text}
            text={item.text}
            modalState={item.location}
            setModalState={setModalState}
          />
        );
      })}

      <div className="font-semibold mt-4">Identity</div>
      {menus.demographicsMenuIdentity.map((item) => {
        return (
          <SettingsBar
            key={item.text}
            text={item.text}
            modalState={item.location}
            setModalState={setModalState}
          />
        );
      })}
      <div className="font-semibold mt-4">Enviromental</div>
      {menus.demographicsMenuEnviromental.map((item) => {
        return (
          <SettingsBar
            key={item.text}
            text={item.text}
            modalState={item.location}
            setModalState={setModalState}
          />
        );
      })}
    </>
  );
};

export default Demographics;
