import { Tooltip as ReactTooltip } from "react-tooltip";
import { Fragment } from "react/jsx-runtime";
import tinycolor from "tinycolor2";

interface DataItem {
  key: string;
  name: string;
  count: number;
  color: string;
}

interface StackedBarChartProps {
  data: Array<DataItem>;
  height?: number;
  demographic: string;
  hoverKey?: string | null;
  handleMouseEnter?: (key: string) => void;
  handleMouseLeave?: () => void;
}

const StackedBarChart = ({
  data,
  height = 12,
  demographic,
  hoverKey,
  handleMouseEnter,
  handleMouseLeave,
}: StackedBarChartProps) => {
  const totalCount = data.reduce((acc, item) => acc + item.count, 0);

  return (
    <div
      className={`w-5/6 flex items-center bg-gray-200 h-${height} rounded-lg overflow-hidden`}
    >
      {data
        .sort((a, b) => {
          if (a.key > b.key) return 1;
          if (a.key < b.key) return -1;
          return 0;
        })
        .map((item, index) => {
          const percentage = (item.count / totalCount) * 100;

          const isHovered = hoverKey === item.key;
          const backgroundColor = isHovered
            ? tinycolor(item.color).lighten(20).toString()
            : item.color;

          return (
            <Fragment key={`${item.key}-${demographic}-${index}`}>
              <div
                key={item.name}
                data-tooltip-id={`${item.key}-${demographic}-${index}`}
                className="h-full flex items-center justify-center"
                style={{ width: `${percentage}%`, backgroundColor }}
                onMouseEnter={() =>
                  handleMouseEnter && handleMouseEnter(item.key)
                }
                onMouseLeave={handleMouseLeave}
              >
                <span className="text-white">{item.count}</span>
              </div>
              <ReactTooltip
                id={`${item.key}-${demographic}-${index}`}
                place="top"
                content={`${percentage.toFixed(2)}%`}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default StackedBarChart;
