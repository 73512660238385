import { ReactNode } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface ToasterProps {
  text: string;
  dark?: boolean;
  button?: string;
  location?: string;
  page?: string;
}

export const toaster = ({
  text,
  dark,
  button,
  location,
  page,
}: ToasterProps) => {
  toast(
    <ToastContent
      text={text}
      button={button}
      location={location}
      page={page}
    />,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: dark ? "dark" : "light",
    }
  );
};

interface ToastContentProps {
  text: string;
  button?: ReactNode;
  location?: string;
  page?: string;
}

const ToastContent = ({ text, button, location, page }: ToastContentProps) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (location) {
      navigate(String(location), { ...(page && { state: { page } }) });
    }
  };

  return (
    <div className="center flex-col">
      <div>{text}</div>
      {button && (
        <button className="button" onClick={handleNavigation}>
          {button}
        </button>
      )}
    </div>
  );
};
