import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getComments } from "../../../api/questionApi";
import LeftPanel from "../../feed/LeftPanel";
import QuestionPageQuestion from "./Question";
import { useState } from "react";

const QuestionPage = () => {
  const location = useLocation();

  const [update, setUpdate] = useState(false);

  const { questionId } = useParams<{ questionId: string }>();
  const { state } = location;
  const backPage = state?.stats ? true : false;

  const { data: comments } = useQuery({
    queryKey: ["getComments", questionId, update],
    queryFn: () =>
      getComments({ questionId }).then((response) => {
        return response.data.comments;
      }),
  });

  const reset = () => {
    setUpdate(!update);
  };

  return (
    <div className="flex flex-row">
      <LeftPanel />
      <div className="w-full flex justify-center">
        <div className="center flex-row w-full max-w-[1200px]">
          <div className="w-full p-3 h-[calc(100vh-60px)] overflow-y-scroll">
            <QuestionPageQuestion
              backPage={backPage}
              comments={comments}
              reset={reset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionPage;
