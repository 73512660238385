export enum Gender {
  FEMALE = "Female",
  MALE = "Male",
  TRANSGENDER = "Transgender",
  NON_BINARY = "Non-binary/Non-conforming",
  OTHER = "Other",
}

export enum Ethnicity {
  ABORIGINE = "Aboriginal or Native",
  ARAB = "Arabic",
  BLACK = "Black",
  CENTRAL_ASIAL = "Central Asian",
  EAST_ASIAN = "East Asian",
  LATIN = "Latino",
  OTHER = "Other",
  PACIFIC_ISLANDER = "Pacific Islander",
  SOUTH_ASIAN = "South Asian",
  TWO_RACES = "Two or more races",
  WHITE = "White",
}

export enum Orientation {
  ASEXUAL = "Asexual",
  BISEXUAL = "Bisexual",
  GAY = "Gay",
  LESBIAN = "Lesbian",
  STRAIGHT = "Straight",
}

export enum Religion {
  CHRISTIANITY = "Christianity",
  ISLAM = "Islam",
  HINDUISM = "Hinduism",
  BUDDHISM = "Buddhism",
  SIKHISM = "Sikhism",
  JUDAISM = "Judaism",
  CONFUCIANISM = "Confucianism",
  MUISM = "Mu-ism",
  BAHAI_FAITH = "Bahá'í Faith",
  JAINISM = "Jainism",
  SHINTO = "Shinto",
  TAOISM = "Taoism",
  ZOROASTRIANISM = "Zoroastrianism",
  ATHEISM = "Atheism",
  AGNOSTICISM = "Agnosticism",
  OTHER = "Other",
}

export enum Politics {
  ANARCHIST = "Anarchist",
  APOLITICAL = "Apolitical",
  CENTRIST = "Centrist",
  COMMUNIST = "Communist",
  CONSERVATIVE = "Conservative",
  ENVIRONMENTALIST = "Environmentalism",
  FASCIST = "Fascist",
  INDEPENDENT = "Independent",
  LIBERAL = "Liberal",
  NATIONALIST = "Nationalist",
  POPULIST = "Populist",
  SOCIALIST = "Socialist",
}

export enum QuestionType {
  CHOICE = "Choice",
  RATING = "Rating",
  OPEN = "Open",
  // RANK = "Rank",
}

export enum ReportType {
  USER = "user",
  QUESTION = "question",
  COMMENT = "comment",
}

export enum HeightUnits {
  CENTIMETERS = "cm",
  INCHES = "in",
}

export enum FeedTypes {
  POPULAR = "popular",
  NEW = "new",
  PROFILE = "profile",
  FOLLOWING = "following",
}

export enum FollowState {
  NONE = "none",
  REQUESTED = "requested",
  FOLLOWING = "following",
}

export enum ReportReasons {
  HARASSMENT = "Harassment",
  VIOLENCE = "Violence",
  HATE = "Hate",
  MINOR_ABUSE = "Minor abuse",
  IMPERSONATING = "Impersonating",
  SPAM = "Spam",
  SHARING_PERSONAL_INFORMATION = "Sharing personal information",
  NUDITY = "Nudity",
}

interface PoliticsDefinition {
  name: Politics;
  definition: string;
}

export const PoliticsDefinitions: Record<Politics, PoliticsDefinition> = {
  [Politics.APOLITICAL]: {
    name: Politics.APOLITICAL,
    definition: "",
  },
  [Politics.ANARCHIST]: {
    name: Politics.ANARCHIST,
    definition:
      "Seeks to abolish all forms of compulsory government and promotes a stateless society where individuals govern themselves.",
  },
  [Politics.CENTRIST]: {
    name: Politics.CENTRIST,
    definition:
      "Embraces moderate or balanced views, avoiding extreme positions on the political spectrum.",
  },
  [Politics.COMMUNIST]: {
    name: Politics.COMMUNIST,
    definition:
      "An extreme form of socialism advocating for a classless society where all property is publicly owned and each person works and is paid according to their abilities and needs.",
  },
  [Politics.CONSERVATIVE]: {
    name: Politics.CONSERVATIVE,
    definition:
      "Upholds traditional values and institutions, often supporting a free-market economy and a cautious approach to societal change.",
  },
  [Politics.ENVIRONMENTALIST]: {
    name: Politics.ENVIRONMENTALIST,
    definition:
      "Prioritizes environmental conservation and sustainability, often advocating for policies to address climate change and protect natural resources.",
  },
  [Politics.FASCIST]: {
    name: Politics.FASCIST,
    definition:
      "An authoritarian ideology emphasizing strong centralized power, extreme nationalism, and often, suppression of opposition.",
  },
  [Politics.INDEPENDENT]: {
    name: Politics.INDEPENDENT,
    definition: "",
  },
  [Politics.LIBERAL]: {
    name: Politics.LIBERAL,
    definition:
      "Advocates for individual rights, democracy, and free-market economics. It emphasizes personal freedoms and limited government intervention in the economy.",
  },
  [Politics.NATIONALIST]: {
    name: Politics.NATIONALIST,
    definition:
      "Prioritizes the interests and culture of one's nation, sometimes to the exclusion or detriment of others.",
  },
  [Politics.POPULIST]: {
    name: Politics.POPULIST,
    definition:
      "Advocates for the interests of the common people against the elite or establishment, often using rhetoric that appeals to the general public.",
  },
  [Politics.SOCIALIST]: {
    name: Politics.SOCIALIST,
    definition:
      "Focuses on collective ownership and control of the means of production, advocating for more equitable distribution of wealth and resources.",
  },
};

export enum PrivacyViewing {
  EVERYONE = "Everyone",
  FOLLOWING = "Following",
  NONE = "None",
}
