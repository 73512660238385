import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { CgUnblock } from "react-icons/cg";

import * as menus from "../../../../constants/menus";
import SettingsBar from "../common/SettingsBar";
import { setModal } from "../../../../store/features/modalSlice";
import Loading from "../../../Loading";
import {
  selectPrivacy,
  setPrivacySettings,
} from "../../../../store/features/privacySlice";
import { getPrivacy } from "../../../../api/profileApi";
import Accordion from "../../../../utils/Accordion";
import { UNBLOCK_USERS_MODAL } from "../../../../constants/strings";

type User = {
  _id: string;
  username: string;
};

const Privacy = () => {
  const dispatch = useDispatch();

  const { unblocked } = useSelector(selectPrivacy);

  const [blockedUsers, setBlockedUsers] = useState<Array<User>>([]);

  const setModalState = (modalState: string) => {
    dispatch(setModal({ isModalOpen: true, modalState }));
  };

  const unblock = async (id: string) => {
    dispatch(setModal({ isModalOpen: true, blockUserId: id }));
    setModalState(UNBLOCK_USERS_MODAL);
  };

  const { isLoading } = useQuery({
    queryKey: ["getPrivacy", unblocked],
    queryFn: async () =>
      await getPrivacy()
        .then((response) => {
          const data = response.data.privacy;
          dispatch(
            setPrivacySettings({
              questions: data.questions,
              comments: data.comments,
            })
          );
          setBlockedUsers(data?.blockedUsers);
          return data;
        })
        .catch(() => null),
  });

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="font-semibold mt-4">General</div>
      {menus.privacyGeneral.map((item) => {
        return (
          <SettingsBar
            key={item.text}
            text={item.text}
            modalState={item.location}
            setModalState={setModalState}
          />
        );
      })}
      <div className="font-semibold mt-4">Blocked</div>
      <div className="p-4">
        <Accordion text="Blocked Users" count={blockedUsers.length || 0}>
          <div className="h-[400px] overflow-scroll">
            {blockedUsers.length > 0 &&
              blockedUsers.map((bu: User) => {
                return (
                  <div
                    key={bu._id}
                    className="flex items-center p-2 w-full justify-between"
                  >
                    <div className="mr-4 text-lg">{bu.username}</div>
                    <button
                      className="button-cancel flex items-center"
                      onClick={() => unblock(bu._id)}
                    >
                      <CgUnblock size={22} color="white" />
                      <div className="ml-3">Unblock</div>
                    </button>
                  </div>
                );
              })}
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default Privacy;
