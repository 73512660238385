import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { IoMdClose } from "react-icons/io";
import { StatusCodes } from "http-status-codes";

import { closeModal, selectModal } from "../../../../store/features/modalSlice";
import { deleteQuestion } from "../../../../api/questionApi";
import { addToDeleted } from "../../../../store/features/feedSlice";

interface DeleteQuestionModalProps {
  modalRef: any;
}

const DeleteQuestionModal = ({ modalRef }: DeleteQuestionModalProps) => {
  const dispatch = useDispatch();

  const { deleteId } = useSelector(selectModal);

  const resetModal = () => {
    dispatch(closeModal());
  };

  const confirmDelete = () => {
    deleteQuestion(String(deleteId))
      .then((response) => {
        if (response.status === StatusCodes.ACCEPTED) {
          dispatch(addToDeleted(String(deleteId)));
          resetModal();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div ref={modalRef} className="card w-[440px] min-w-[400px]">
      <div className="flex justify-between mb-3">
        <div className="text-lg font-semibold">Delete Question</div>

        <button onClick={resetModal}>
          <IoMdClose />
        </button>
      </div>
      <Suspense fallback={<ReactLoading type="bars" color="#ccc" />}>
        <div className="text-normal mb-5">
          Are you sure you want to delete this question? This action cannot be
          undone.
        </div>
        <div className="flex justify-end">
          <button className="button" onClick={resetModal}>
            Cancel
          </button>
          <button className="button-cancel" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </Suspense>
    </div>
  );
};

export default DeleteQuestionModal;
