import { ChangeEvent, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import Modal from "../../common/Modal";
import { getMe, updateUsername } from "../../../../../api/profileApi";
import { toaster } from "../../../../../utils/toast";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../store/features/modalSlice";
import { setPartialUser } from "../../../../../store/features/authSlice";

const UsernameModal = () => {
  const dispatch = useDispatch();

  const [changing, setChanging] = useState(false);
  const [username, setUsername] = useState("");

  const { data } = useQuery({
    queryKey: ["getMe"],
    queryFn: async () => {
      return getMe()
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return null;
        });
    },
  });

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const resetModal = () => {
    dispatch(closeModal());
  };

  const changeUsername = () => {
    updateUsername(username)
      .then(() => {
        dispatch(setPartialUser({ username }));
        resetModal();
        toaster({ text: "Username succesfully updated" });
      })
      .catch((error) => {
        toaster({ text: error.response.data.message });
      });
  };

  return (
    <Modal title="Username">
      <div className="center flex-col w-[400px]">
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div>{data?.username}</div>
            <div className="ml-6">
              <button
                className="font-semibold"
                onClick={() => setChanging(!changing)}
              >
                {changing ? "Cancel" : "Change"}
              </button>
            </div>
          </div>
          {changing && (
            <div className="center flex-col mt-4">
              <input
                className="input"
                placeholder="New Username"
                value={username}
                onChange={handleUsernameChange}
              />
              <button className="button mt-6" onClick={changeUsername}>
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UsernameModal;
