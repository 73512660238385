import Comment from "./Comment";
import { TComment } from "../../../../types";

interface CommentSectionProps {
  questionId: string;
  comments: Array<TComment>;
  reset: () => void;
}

export const CommentSection = ({
  questionId,
  comments,
  reset,
}: CommentSectionProps) => {
  return (
    <>
      <>
        {comments.map((comment) => {
          return (
            <Comment
              key={comment._id}
              _id={comment._id}
              questionId={questionId}
              author={comment.author}
              comment={comment.text}
              score={comment.score}
              createdAt={comment.createdAt}
              hasReplies={comment.hasReplies}
              subComments={comment.subComments}
              reset={reset}
            />
          );
        })}
      </>
    </>
  );
};

export default CommentSection;
