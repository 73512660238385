import { Dispatch, Fragment, ReactNode, SetStateAction } from "react";
import QuestionHeader from "../Question/QuestionHeader";
import { Author } from "../../../types";

interface QuestionTopProps {
  author: Author;
  title: string;
  description: string;
  filePreviews: Array<string>;
  setFilePreviews: Dispatch<SetStateAction<Array<string>>>;
  setFieldValue: any;
  children?: ReactNode;
}

const QuestionTop = ({
  author,
  title,
  description,
  filePreviews,
  setFilePreviews,
  setFieldValue,
  children,
}: QuestionTopProps) => {
  return (
    <div className="center w-full flex-col">
      <QuestionHeader
        id={""}
        author={author}
        createdAt={new Date().toISOString()}
        headerRef={null}
        flip={false}
      />
      <div className="text-normal my-2">
        <div className="center font-semibold">{title}</div>
        <div className="font-normal">
          {description.split("\n").map((line: string, index: number) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

export default QuestionTop;
