import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { IoFlag, IoTrash } from "react-icons/io5";
import { VscCommentDiscussion } from "react-icons/vsc";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

import Avatar from "../../../../utils/Avatar";
import CommentBox from "./CommentBox";
import { commentOnQuestion, voteComment } from "../../../../api/questionApi";
import { Author, TComment } from "../../../../types";
import * as strings from "../../../../constants/strings";
import { debounce, errorCatcher } from "../../../../utils/functions";
import {
  setDeleteId,
  setModal,
  setRefId,
} from "../../../../store/features/modalSlice";
import { selectUser } from "../../../../store/features/authSlice";

interface CommentProps {
  _id: string;
  questionId: string;
  author: Author;
  comment: string;
  score: number;
  createdAt: string;
  hasReplies: boolean;
  subComments: Array<TComment>;
  level?: number;
  reset: () => void;
}

const Comment = ({
  _id,
  questionId,
  author,
  comment,
  score,
  createdAt,
  hasReplies,
  subComments,
  level = 0,
  reset,
}: CommentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);

  const [commenting, setCommenting] = useState(false);
  const [_comment, setComment] = useState("");
  const [subCommentsOpen, setSubCommentsOpen] = useState(false);
  const [commentScore, setCommentScore] = useState(score);

  const setModalState = (state: string) => {
    dispatch(setModal({ isModalOpen: true, modalState: state }));
  };

  const timeAgo = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

  const toggleCommenting = () => {
    setCommenting(!commenting);
  };

  const goToProfile = () => {
    navigate(`/profile/${author.username}`);
  };

  const reportQuestion = async () => {
    setModalState(strings.REPORT_COMMENT_MODAL);
    dispatch(setRefId(_id));
  };

  const deleteComment = async () => {
    setModalState(strings.DELETE_COMMENT_MODAL);
    dispatch(setDeleteId(_id));
  };

  const createComment = () => {
    commentOnQuestion({ questionId, parentCommentId: _id, text: _comment })
      .then(() => {
        setComment("");
        reset();
      })
      .catch((error) => {
        errorCatcher(error, "You must be logged in to comment");
      });
  };

  const voteOnComment = (action: string) => {
    voteComment({ commentId: _id, action })
      .then((response) => {
        const data = response.data;
        setCommentScore(data.score);
      })
      .catch();
  };

  const debouncedVoteOnComment = debounce(voteOnComment, 300);

  return (
    <div className="pt-2 text-sm" style={level ? { marginLeft: 35 } : {}}>
      <div className="flex flex-row justify-between">
        <div className="flex">
          <div className="w-9">
            {author.avatar ? (
              <img
                src={author.avatar}
                style={{ borderRadius: 100, height: 25, width: 25 }}
              />
            ) : (
              <Avatar />
            )}
          </div>
          <div className="flex flex-col w-full">
            <div className="cursor-pointer" onClick={() => goToProfile()}>
              <span className="font-semibold">
                {author?.username || <i>Unknown</i>}
              </span>
              <span className="mx-2">&#x2022;</span>
              <span className="text-sm text-gray-500">{timeAgo}</span>
            </div>
            <div>{comment}</div>
          </div>
        </div>
        <div>
          <div className="flex flex-col text-center pt-2">
            <button onClick={() => debouncedVoteOnComment("like")}>
              <FaAngleUp />
            </button>
            {commentScore}
            <button onClick={() => debouncedVoteOnComment("dislike")}>
              <FaAngleDown />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-9">
          <button onClick={() => setSubCommentsOpen(!subCommentsOpen)}>
            {hasReplies ? (
              <div className="m-auto">
                {subCommentsOpen ? <FiMinusCircle /> : <FiPlusCircle />}
              </div>
            ) : (
              <></>
            )}
          </button>
        </div>
        <div className="w-full flex flex-row gap-6">
          <button
            className="flex gap-1 items-center"
            onClick={toggleCommenting}
          >
            <VscCommentDiscussion /> Reply
          </button>
          {userId === author?._id ? (
            <button
              className="flex gap-1 items-center text-red-500"
              onClick={deleteComment}
            >
              <IoTrash /> Delete
            </button>
          ) : (
            <button
              className="flex gap-1 items-center text-red-500"
              onClick={reportQuestion}
            >
              <IoFlag /> Report
            </button>
          )}
        </div>
      </div>
      {commenting && (
        <CommentBox
          comment={_comment}
          setComment={setComment}
          commenting={commenting}
          toggleCommenting={toggleCommenting}
          createComment={createComment}
        />
      )}
      {subCommentsOpen &&
        subComments.map((subComment) => {
          return (
            <Comment
              key={subComment._id}
              _id={subComment._id}
              questionId={questionId}
              author={subComment.author}
              comment={subComment.text}
              score={subComment.score}
              createdAt={subComment.createdAt}
              hasReplies={subComment.hasReplies}
              subComments={subComment.subComments}
              level={level + 1}
              reset={reset}
            />
          );
        })}
    </div>
  );
};

export default Comment;
