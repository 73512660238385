import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";

import { ChoiceAnswers } from "../../../../../types/answers";
import SliderButtons from "../SliderButtons";
import { colorArray, GetData } from "./GetData";
import { Choice } from "../../../../../types";
import ShareToSee from "../AnswerToSee";
import Mapbox from "./Mapbox";
import OverallData from "./OverallData";
import AgeData from "./AgeData";
import HeightData from "./HeightData";
import { getDemographics } from "../../../../../api/demographicsApi";
import { selectUser } from "../../../../../store/features/authSlice";
import "swiper/css";

interface ChoiceDataProps {
  answers: ChoiceAnswers;
  choices: Array<Choice>;
  hasAnswer: boolean;
}

const ChoiceData = ({ answers, choices, hasAnswer }: ChoiceDataProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { userId } = useSelector(selectUser);

  const choiceKeyMap: any = {};
  const colorMap: any = { null: "#ccc" };

  choices.forEach((choice, index) => {
    choiceKeyMap[choice._id as string] = choice.text;
    const key = choice._id || "";
    colorMap[key] = colorArray[index];
  });

  const [, setInit] = useState(false);
  const [active, setActive] = useState(0);

  const { data } = useQuery({
    queryKey: ["getDemographics", userId],
    queryFn: () =>
      getDemographics(userId).then((response) => {
        const data = response.data.data;
        return data;
      }),
    enabled: !!userId,
  });

  useEffect(() => {
    if (swiperRef.current) swiperRef.current?.swiper.slideTo(active);
  }, [active]);

  if (!userId)
    return (
      <div className="center">
        <div className="text-sm my-6">
          You must be logged in to view results
        </div>
      </div>
    );

  if (!hasAnswer) {
    return (
      <div className="center">
        <div className="text-sm my-6">
          Please answer the question before viewing answers
        </div>
      </div>
    );
  }

  return (
    <>
      <SliderButtons
        active={active}
        setActive={setActive}
        pages={9}
        prevRef={prevRef}
        nextRef={nextRef}
      />
      <Swiper
        ref={swiperRef}
        className="max-w-[800px] my-6"
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={() => setInit(true)}
        onSlideChange={(e: any) => setActive(e.realIndex)}
        allowTouchMove={false}
        autoHeight
      >
        <SwiperSlide>
          {hasAnswer ? (
            <OverallData
              answers={answers}
              choiceKeyMap={choiceKeyMap}
              colorMap={colorMap}
            />
          ) : (
            <div className="center">No results at this time</div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.dob} demographic="age">
            <AgeData answers={answers} colorMap={colorMap} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.location} demographic="location">
            <Mapbox locationData={answers?.location} colorMap={colorMap} />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.gender} demographic="gender">
            <GetData
              answers={answers}
              type="gender"
              title="Gender"
              chartHeight={8}
              choiceKeyMap={choiceKeyMap}
              colorMap={colorMap}
            />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.ethnicity} demographic="race">
            <GetData
              answers={answers}
              type="ethnicity"
              title="Race"
              chartHeight={8}
              choiceKeyMap={choiceKeyMap}
              colorMap={colorMap}
            />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee
            shared={!!data?.orientation}
            demographic="sexual orientation"
          >
            <GetData
              answers={answers}
              type="orientation"
              title="Sexual Orientation"
              chartHeight={8}
              choiceKeyMap={choiceKeyMap}
              colorMap={colorMap}
            />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.religion} demographic="religion">
            <GetData
              answers={answers}
              type="religion"
              title="Faith"
              chartHeight={8}
              choiceKeyMap={choiceKeyMap}
              colorMap={colorMap}
            />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.politics} demographic="political leaning">
            <GetData
              answers={answers}
              type="politics"
              title="Political Leaning"
              chartHeight={8}
              choiceKeyMap={choiceKeyMap}
              colorMap={colorMap}
            />
          </ShareToSee>
        </SwiperSlide>
        <SwiperSlide>
          <ShareToSee shared={!!data?.height} demographic="height">
            <HeightData answers={answers} colorMap={colorMap} />
          </ShareToSee>
        </SwiperSlide>
      </Swiper>
      <table className="center">
        <tbody>
          {choices.map((choice, index) => {
            return (
              <tr key={choice._id} className="flex flex-row mb-2">
                <td
                  className="h-5 w-5 rounded-md mr-2"
                  style={{ background: colorArray[index] }}
                />
                <td className="text-sm">{choice.text}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ChoiceData;
