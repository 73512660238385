import { ReactNode, useLayoutEffect, useRef, useState } from "react";

const Accordion = ({
  text,
  count,
  children,
}: {
  text: string;
  count: number;
  children: ReactNode;
}) => {
  const contentEl = useRef<HTMLDivElement>(null);
  const [isActive, setActive] = useState(false);
  const [height, setHeight] = useState<number | null>(0);

  const toggleActive = () => {
    setActive(!isActive);
  };

  useLayoutEffect(() => {
    if (contentEl.current) {
      setHeight(contentEl.current.scrollHeight);
    }
  }, [isActive]);

  return (
    <div>
      <button className="flex items-center mb-4" onClick={toggleActive}>
        {text}
        {count > 0 && (
          <span className="w-4 h-4 ml-2 bg-red-500 text-white text-xs font-bold rounded-full flex items-center justify-center">
            {count}
          </span>
        )}
      </button>
      <div
        ref={contentEl}
        style={{
          height: isActive ? `${height}px` : "0px",
          overflow: "hidden",
          transition: "height 0.3s ease", // Optional: to add a smooth transition
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
