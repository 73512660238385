import { Dispatch, SetStateAction } from "react";

import QuestionTop from "./QuestionTop";
import { Author, Choice } from "../../../types";

interface NewChoiceQuestionProps {
  author: Author;
  title: string;
  description: string;
  filePreviews: Array<string>;
  setFilePreviews: Dispatch<SetStateAction<Array<string>>>;
  setFieldValue: any;
  choices: Array<Choice>;
}

const NewChoiceQuestion = ({
  author,
  title,
  description,
  filePreviews,
  setFilePreviews,
  setFieldValue,
  choices,
}: NewChoiceQuestionProps) => {
  return (
    <QuestionTop
      author={author}
      title={title}
      description={description}
      filePreviews={filePreviews}
      setFilePreviews={setFilePreviews}
      setFieldValue={setFieldValue}
    >
      <div className="w-4/5 grid grid-cols-2 gap-4 max-sm:grid-cols-1">
        {choices.map((choice, index) => {
          return (
            <div key={`choice-${index}`} className="center flex-col">
              {choice.text}
              {filePreviews[index] && (
                <div className="center">
                  <img
                    src={filePreviews[index]}
                    alt={`Preview`}
                    style={{
                      maxWidth: "60%",
                      maxHeight: "300px",
                      objectFit: "cover",
                      margin: "5px",
                      borderRadius: 8,
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </QuestionTop>
  );
};

export default NewChoiceQuestion;
