import { IconType } from "react-icons";
import {
  FaGear,
  FaHandshake,
  FaPeopleGroup,
  FaRegNewspaper,
} from "react-icons/fa6";
import {
  MdBalance,
  MdHomeFilled,
  MdLogout,
  MdOutlineInfo,
} from "react-icons/md";
import { LuScrollText } from "react-icons/lu";

import * as strings from "./strings";
import AuthModal from "../components/auth/AuthModal";
import NewQuestion from "../components/feed/NewQuestion";
import ReportQuestionModal from "../components/feed/Question/QuestionHeader/ReportQuestionModal";
import GenderModal from "../components/pages/Settings/Demographics/Modals/GenderModal";
import EthnicityModal from "../components/pages/Settings/Demographics/Modals/EthnicityModal";
import OrientationModal from "../components/pages/Settings/Demographics/Modals/OrientationModal";
import PoliticalModal from "../components/pages/Settings/Demographics/Modals/PoliticalModal";
import ReligionModal from "../components/pages/Settings/Demographics/Modals/Religion";
import LocationModal from "../components/pages/Settings/Demographics/Modals/LocationModal";
import HeightModal from "../components/pages/Settings/Demographics/Modals/HeightModal";
import ProfilePictureModal from "../components/pages/Settings/Account/Profile/Modals/ProfilePictureModal";
import BioModal from "../components/pages/Settings/Account/Profile/Modals/BioModal";
import EmailModal from "../components/pages/Settings/Account/Modals/EmailModal";
import PasswordModal from "../components/pages/Settings/Account/Modals/PasswordModal";
import TerminateModal from "../components/pages/Settings/Account/Modals/TerminateModal";
import LeftPanelDrawer from "../components/navigation/LeftPanelDrawer";
import AgeModal from "../components/pages/Settings/Demographics/Modals/AgeModal";
import DeleteQuestionModal from "../components/feed/Question/QuestionHeader/DeleteQuestionModal";
import QuestionImagePreviewModal from "../components/feed/Question/QuestionImagePreviewModal";
import ProfileBannerImage from "../components/pages/Settings/Account/Profile/Modals/ProfileBannerImageModal";
import { FeedTypes } from "../types/enums";
import ReportCommentModal from "../components/feed/Question/Comments/ReportCommentModal";
import DeleteCommentModal from "../components/feed/Question/Comments/DeleteCommentModal";
import ViewQuestionsModal from "../components/pages/Settings/Privacy/Modals/ViewQuestionsModal";
import ViewCommentsModal from "../components/pages/Settings/Privacy/Modals/ViewCommentsModal";
import UsernameModal from "../components/pages/Settings/Account/Modals/UsernameModal";
import BlockQuestionsModal from "../components/pages/Settings/Privacy/Modals/BlockQuestionsModal";
import BlockUsersModal from "../components/pages/Settings/Privacy/Modals/BlockUsersModal";
import UnblockUsersModal from "../components/pages/Settings/Privacy/Modals/UnblockUserModal";

interface AuthHeaderMenuItem {
  icon?: IconType;
  text: string;
  location?: string;
  linbreak?: boolean;
  action?: string;
}

export const authHeaderMenu: Array<AuthHeaderMenuItem> = [
  { text: "View Profile", location: "/profile" },
  { icon: FaGear, text: "Settings", location: "/settings", linbreak: true },
  {
    icon: MdLogout,
    text: "Logout",
    action: strings.LOGOUT_ACTION,
    location: "/",
  },
  // { icon: FaShieldHalved, text: "Premium", location: "/premium" },
];

interface LeftPanelItem {
  text: string;
  icon: IconType;
  feed?: string;
  location?: string;
  breakpoint?: boolean;
}

export const leftPanelMenu: Array<LeftPanelItem> = [
  { text: "Home", icon: MdHomeFilled, feed: FeedTypes.POPULAR },
  { text: "New", icon: FaRegNewspaper, feed: FeedTypes.NEW },
  {
    text: "Following",
    icon: FaPeopleGroup,
    feed: FeedTypes.FOLLOWING,
    breakpoint: true,
  },
  { text: "About", icon: MdOutlineInfo, location: "/about" },
  { text: "Content Policy", icon: LuScrollText, location: "/content" },
  { text: "Privacy Policy", icon: MdBalance, location: "/privacy" },
  { text: "User Agreement", icon: FaHandshake, location: "/tos" },
];

export const profileMenu = [
  strings.PROFILE_QUESTIONS,
  strings.PROFILE_COMMENTS,
];

export const myProfileMenu = [
  strings.PROFILE_FOLLOWING,
  strings.PROFILE_FOLLOWERS,
];

export const settingsMenu = [
  strings.SETTINGS_ACCOUNT,
  strings.SETTINGS_DEMOGRAPHICS,
  strings.SETTINGS_PRIVACY,
];

interface SettingsMenuItem {
  text: string;
  location: string;
  toggle?: boolean;
}

export const accountGeneral: Array<SettingsMenuItem> = [
  {
    text: "Email",
    location: strings.SETTINGS_ACCOUNT_EMAIL_MODAL,
  },
  {
    text: "Username",
    location: strings.SETTINGS_ACCOUNT_USERNAME_MODAL,
  },
  {
    text: "Password",
    location: strings.SETTINGS_ACCOUNT_PASSWORD_MODAL,
  },
];

export const accountTerminate: SettingsMenuItem = {
  text: "Terminate Account",
  location: strings.SETTINGS_ACCOUNT_TERMINATE_MODAL,
};

export const profileGeneral: Array<SettingsMenuItem> = [
  {
    text: "Profile Picture",
    location: strings.SETTINGS_PROFILE_PICTURE_MODAL,
  },
  {
    text: "Profile Banner Image",
    location: strings.SETTINGS_PROFILE_BANNER_MODAL,
  },
  {
    text: "Bio",
    location: strings.SETTINGS_PROFILE_BIO_MODAL,
  },
];

export const demographicsMenuPhysiological: Array<SettingsMenuItem> = [
  {
    text: "Age",
    location: strings.SETTINGS_DEMOGRAPHIC_AGE_MODAL,
  },
  {
    text: "Height",
    location: strings.SETTINGS_DEMOGRAPHIC_HEIGHT_MODAL,
  },
  {
    text: "Gender",
    location: strings.SETTINGS_DEMOGRAPHIC_GENDER_MODAL,
  },
  {
    text: "Race / Ethnicicty",
    location: strings.SETTINGS_DEMOGRAPHIC_ETHNICITY_MODAL,
  },
];

export const demographicsMenuIdentity: Array<SettingsMenuItem> = [
  {
    text: "Sexual Orientation",
    location: strings.SETTINGS_DEMOGRAPHIC_ORIENTATION_MODAL,
  },
  {
    text: "Religion",
    location: strings.SETTINGS_DEMOGRAPHIC_RELIGION_MODAL,
  },
  {
    text: "Political Affliation",
    location: strings.SETTINGS_DEMOGRAPHIC_POLITICAL_MODAL,
  },
];

export const demographicsMenuEnviromental: Array<SettingsMenuItem> = [
  {
    text: "Location",
    location: strings.SETTINGS_DEMOGRAPHIC_LOCATION_MODAL,
  },
];

export const privacyGeneral: Array<SettingsMenuItem> = [
  {
    text: "Who can view my questions",
    location: strings.SETTINGS_PRIVACY_VIEW_QUESTIONS,
  },
  {
    text: "Who can view my comments",
    location: strings.SETTINGS_PRIVACY_VIEW_COMMENTS,
  },
];

type ModalOrResetProps = {
  modalRef: React.RefObject<HTMLDivElement>;
  resetModal?: () => void;
};

type ModalAndResetProps = {
  modalRef: React.RefObject<HTMLDivElement>;
  resetModal: () => void;
};

export const modalComponents: {
  [key: string]:
    | React.ComponentType<ModalOrResetProps>
    | React.ComponentType<ModalAndResetProps>;
} = {
  [strings.QUESTION_MODAL_STATE]: NewQuestion,
  [strings.AUTH_MODAL_STATE]: AuthModal,
  [strings.REPORT_QUESTION_MODAL]: ReportQuestionModal,
  [strings.DELETE_QUESTION_MODAL]: DeleteQuestionModal,
  [strings.DELETE_COMMENT_MODAL]: DeleteCommentModal,
  [strings.REPORT_COMMENT_MODAL]: ReportCommentModal,
  [strings.BLOCK_QUESTIONS_MODAL]: BlockQuestionsModal,
  [strings.BLOCK_USERS_MODAL]: BlockUsersModal,
  [strings.UNBLOCK_USERS_MODAL]: UnblockUsersModal,
  [strings.IMAGE_PREVIEW_MODAL]: QuestionImagePreviewModal,
  [strings.LEFT_PANEL_DRAWER]: LeftPanelDrawer,
  [strings.SETTINGS_ACCOUNT_EMAIL_MODAL]: EmailModal,
  [strings.SETTINGS_ACCOUNT_USERNAME_MODAL]: UsernameModal,
  [strings.SETTINGS_ACCOUNT_PASSWORD_MODAL]: PasswordModal,
  [strings.SETTINGS_ACCOUNT_TERMINATE_MODAL]: TerminateModal,
  [strings.SETTINGS_DEMOGRAPHIC_AGE_MODAL]: AgeModal,
  [strings.SETTINGS_DEMOGRAPHIC_HEIGHT_MODAL]: HeightModal,
  [strings.SETTINGS_DEMOGRAPHIC_GENDER_MODAL]: GenderModal,
  [strings.SETTINGS_DEMOGRAPHIC_ETHNICITY_MODAL]: EthnicityModal,
  [strings.SETTINGS_DEMOGRAPHIC_ORIENTATION_MODAL]: OrientationModal,
  [strings.SETTINGS_DEMOGRAPHIC_RELIGION_MODAL]: ReligionModal,
  [strings.SETTINGS_DEMOGRAPHIC_POLITICAL_MODAL]: PoliticalModal,
  [strings.SETTINGS_DEMOGRAPHIC_LOCATION_MODAL]: LocationModal,
  [strings.SETTINGS_PRIVACY_VIEW_QUESTIONS]: ViewQuestionsModal,
  [strings.SETTINGS_PRIVACY_VIEW_COMMENTS]: ViewCommentsModal,
  [strings.SETTINGS_PROFILE_PICTURE_MODAL]: ProfilePictureModal,
  [strings.SETTINGS_PROFILE_BANNER_MODAL]: ProfileBannerImage,
  [strings.SETTINGS_PROFILE_BIO_MODAL]: BioModal,
};
