import { Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { StatusCodes } from "http-status-codes";

import {
  closeModal,
  selectModal,
} from "../../../../../store/features/modalSlice";
import Loading from "../../../../Loading";
import { toaster } from "../../../../../utils/toast";
import { blockQuestion } from "../../../../../api/privacyApi";
import {
  addToDeleted,
  updateFeed,
} from "../../../../../store/features/feedSlice";

interface BlockQuestionsModalProps {
  modalRef: any;
}

export const BlockQuestionsModal = ({ modalRef }: BlockQuestionsModalProps) => {
  const dispatch = useDispatch();

  const [blockUser, setBlockUser] = useState(false);

  const { blockQuestionId, blockUserId } = useSelector(selectModal);

  const resetModal = () => {
    dispatch(closeModal());
  };

  const confirmBlock = () => {
    blockQuestion(
      String(blockQuestionId),
      blockUser ? String(blockUserId) : undefined
    )
      .then((response) => {
        if (response.status !== StatusCodes.OK) return;
        if (blockQuestionId) {
          dispatch(addToDeleted(String(blockUserId)));
        }
        dispatch(addToDeleted(String(blockQuestionId)));
        dispatch(updateFeed());
        resetModal();
        const message = `Question${blockUser ? " and user" : ""} blocked`;
        toaster({ text: message });
      })
      .catch((error) => console.error(error));
  };

  return (
    <div ref={modalRef} className="card w-[440px] min-w-[400px]">
      <div className="flex justify-between mb-3">
        <div className="text-lg font-semibold">Block Question</div>

        <button onClick={resetModal}>
          <IoMdClose />
        </button>
      </div>
      <Suspense fallback={<Loading />}>
        <div className="text-normal mb-5">
          Are you sure you want to question? This action cannot be undone.
        </div>
        <div className="flex items-center">
          <input
            className="mr-2"
            type="checkbox"
            checked={blockUser}
            onChange={(e) => setBlockUser(e.target.checked)}
          />
          Also block user?
        </div>
        <div className="flex justify-end">
          <button className="button" onClick={resetModal}>
            Cancel
          </button>
          <button className="button-cancel" onClick={confirmBlock}>
            Block
          </button>
        </div>
      </Suspense>
    </div>
  );
};

export default BlockQuestionsModal;
