import LeftPanel from "../../feed/LeftPanel";
import { SectionLink, P, PrivacyQA, H1, H2, UL, MailLink } from "./Section";
import { collectedInfo } from "./collectedInfo";

const PrivacyPolicy = () => {
  return (
    <div className="flex w-full">
      <LeftPanel />
      <div className="flex w-full h-full justify-center">
        <div className="flex h-screen overflow-scroll">
          <div className="p-6 py-0  max-w-[800px]">
            <H1>PRIVACY POLICY</H1>
            <P>Last updated August 10, 2024</P>
            <P>
              This privacy notice for POLIQS (<b>"we"</b>, <b>"us"</b> or{" "}
              <b>"our"</b>), describes how and why we might collect, store, use,
              and/or share (<b>"process"</b>) your information when you use our
              services (<b>"Services"</b>), such as when you:
            </P>
            <UL>
              <li id="">
                Visit our website at{" "}
                <SectionLink
                  text="https://www.poliqs.com"
                  link="https://www.poliqs.com"
                />
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                markeying, or events
              </li>
            </UL>
            <P>
              <b>Questions or concerns?</b> Regarding this privacy notice will
              help you understand your privacy rights and choices. If you do not
              agree with our policies and practicies, please do not use our
              Services. If you still have any questions or concerns, please
              contact us at <MailLink mail="support@poliqs.com" />.
            </P>
            <H1>SUMMARY OF KEY POINTS</H1>
            <P b>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{" "}
              <SectionLink text="table of contents" link="#table-of-contents" />{" "}
              below to find the section you are looking for.
            </P>
            <PrivacyQA
              question="What personal information do we process?"
              answer="When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and Services, the choices you make, and the products and features you use. Learn more about"
              linkText="personal information you disclose to us."
              link="#what-information-do-we-collect"
            />
            <PrivacyQA
              question="Do we process any sensitve personal information?"
              answer="We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about"
              linkText="sensitive information we process."
              link="#sensitive-information"
            />
            <PrivacyQA
              question="Do we collect any information from third parties?"
              answer="We do not collect any information from third parties."
            />
            <PrivacyQA
              question="How do we process your information?"
              answer="We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other pruposes with your consent. We process your information only when we have a valid legal reasn to do so. Learn more about "
              linkText="how we process your information."
              link="#how-do-we-process-your-information"
            />
            <PrivacyQA
              question="In what situations and with which types of parties do we share personal information?"
              answer="We may share information in specific situations and with specific categories of third parties. Learn more about "
              linkText="when and with whom we share your personal information."
              link="#when-and-with-whom-do-we-share-your-personal-information"
            />
            <PrivacyQA
              question="How do we keep your information safe?"
              answer="We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about"
              linkText="how we keep your information safe."
              link="#how-do-we-keep-your-information-safe"
            />
            <PrivacyQA
              question="What are your rights?"
              answer="Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about "
              linkText="your privacy rights."
              link="#what-are-your-privacy-rigts"
            />
            <PrivacyQA
              question="How do you exercise your rights?"
              answer="The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws."
            />
            <H1>TABLE OF CONTENTS</H1>
            <SectionLink
              text="WHAT INFORMATION DO WE COLLECT?"
              link="#what-information-do-we-collect"
            />
            <br />
            <SectionLink
              text="HOW DO WE PROCESS YOUR INFORMATION?"
              link="#how-do-we-process-your-information"
            />
            <br />
            <SectionLink
              text="WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?"
              link="#what-legal-bases-do-we-rely-on-to-process-your-information"
            />
            <br />
            <SectionLink
              text="WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
              link="#when-and-with-whom-do-we-share-your-personal-information"
            />
            <br />
            <SectionLink
              text="WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?"
              link="#what-is-our-stance-on-third-party-websites"
            />
            <br />
            <SectionLink
              text="DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?"
              link="#do-we-use-cookies-and-other-tracking-technologies"
            />
            <br />
            <SectionLink
              text="HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
              link="#how-do-we-handle-your-social-logins"
            />
            <br />
            <SectionLink
              text="HOW LONG DO WE KEEP YOUR INFORMATION?"
              link="#how-long-do-we-keep-your-information"
            />
            <br />
            <SectionLink
              text="HOW DO WE KEEP YOUR INFORMATION SAFE?"
              link="#how-do-we-keep-your-information-safe"
            />
            <br />
            <SectionLink
              text="WHAT ARE YOUR PRIVACY RIGHTS?"
              link="#what-are-your-privacy-rigts"
            />
            <br />
            <SectionLink
              text="CONTROLS FOR DO-NOT-TRACK FEATURES?"
              link="#do-united-states-residents-have-specific-privacy-rights"
            />
            <br />
            <SectionLink
              text="DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
              link="#do-united-states-residents-have-specific-privacy-rights"
            />
            <br />
            <SectionLink
              text="DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?"
              link="#do-other-regions-have-specific-privacy-rights"
            />
            <br />
            <SectionLink
              text="DO WE MAKE UPDATES TO THIS NOTICE?"
              link="#do-we-make-updates-to-this-notice"
            />
            <br />
            <SectionLink
              text="HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
              link="#how-can-you-contact-us-about-this-notice"
            />
            <br />
            <SectionLink
              text="HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
              link="#how-can-you-review-update-or-delete-the-data-we-collect-from-you"
            />
            <br />

            <H1>WHAT INFORMATION DO WE COLLECT?</H1>
            <H2>Personal information you disclose to us</H2>
            <PrivacyQA
              question="In Short: "
              answer="We collect personal information that your provide to us."
              i
            />
            <P>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </P>
            <PrivacyQA
              question="Personal Information Provided by You."
              answer="The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:"
            />
            <UL>
              <li>usernames</li>
              <li>passwords</li>
              <li>debit/credit card numbers</li>
              <li>email addresses</li>
              <li>billing addresses</li>
            </UL>
            <div id="sensitive-information"></div>
            <PrivacyQA
              question="Sensitive Information."
              answer="When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:"
            />
            <UL>
              <li>information revealing religious or philosophical beliefs</li>
              <li>information revealing political opinions</li>
              <li>information revealing race or ethnic origin</li>
              <li>data about a person's sex life or sexual orientation</li>
            </UL>
            <p>
              <b>Social Media Login Data. </b>
              We may provide you with the option to register with us using your
              existing social media account details, like your Facebook, X, or
              other social media account. If you choose to register in this way,
              we will collect certain profile information about you from the
              social media provider, as described in the section called{" "}
              <SectionLink
                text={`"HOW DO WE HANDLE YOUR SOCIAL LOGINS?"`}
                link="#how-do-we-handle-your-social-logins"
              />{" "}
              below
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <H2>Information automatically collected</H2>

            <PrivacyQA
              question="In Short: "
              answer="Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services."
              i
            />

            <P>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </P>
            <P>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </P>
            <P>The information we collect includes:</P>
            <UL>
              <li>
                <i>Log and Usage Data. </i> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services
              </li>
              <li>
                <i>Device Data.</i> We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
              <li>
                <i>Location Data. </i> We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able
                to use certain aspects of the Services.
              </li>
            </UL>
            <H2>Google API</H2>
            <P>
              Our use of information received from Google APIs will adhere to{" "}
              <SectionLink
                text="Google API Services Use Data Policy"
                link="https://developers.google.com/terms/api-services-user-data-policy"
              />
              , including the{" "}
              <SectionLink
                text="Limited Use requirements."
                link="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
              />
            </P>
            <H1>HOW DO WE PROCESS YOUR INFORMATION?</H1>
            <PrivacyQA
              question="In Short: "
              answer="We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent."
              i
            />
            <P b>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </P>
            <UL>
              <li>
                <PrivacyQA
                  question="To facilitate account creation and authentication and otherwise manages user accounts."
                  answer="We may process your information so you can create and log in to your account, as well as keep your account in working order."
                />
              </li>
              <li>
                <PrivacyQA
                  question="To respond to user inquiries/offer support to users."
                  answer="We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service."
                />
              </li>
              <li>
                <PrivacyQA
                  question="To save or protect an individual's vital interest."
                  answer="We may process your information when necessary to save or protect individual's vital interest, such as to prevent harm."
                />
              </li>
              <li>
                <PrivacyQA
                  question="Provide demographic data."
                  answer="To provide inquiring users, data about demographic breakdown of data"
                />
              </li>
            </UL>
            <H1>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</H1>
            <PrivacyQA
              question="In Short: "
              answer="We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil  our legitimate business interests."
              i
            />
            <P b i u>
              If you are located in the EU or UK, this section applies to you
            </P>
            <P>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </P>
            <UL>
              <li>
                <PrivacyQA
                  question="Consent."
                  answer="We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about"
                  linkText="withdrawing your consent."
                  link="#withdraw-your-consent"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Performance of a Contract."
                  answer=" We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entereing into a contract with you."
                />
              </li>
              <li>
                <PrivacyQA
                  question="Legal Obligations."
                  answer=" We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved."
                />
              </li>
              <li>
                <PrivacyQA
                  question="Vital Interests."
                  answer=" We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person."
                />
              </li>
            </UL>
            <P b i u>
              If you are located in Canada, this section applies to you
            </P>
            <P>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can{" "}
              <SectionLink
                text="withdraw your consent"
                link="#withdraw-your-consent"
              />{" "}
              at any time.
            </P>
            <P>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </P>
            <UL>
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>
                For business transactions provided certain conditions are met
              </li>
              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been,
                is or may be victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>
              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>
              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </UL>
            <H1>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION</H1>
            <PrivacyQA
              question="In Short:"
              answer="We are committed to protecting your privacy and ensuring that your
          personal information is handled with the utmost care. We do not sell,
          rent, or lease any of the information collected from our users to
          third parties, including vendors, consultants, or other service
          providers."
              i
            />
            <P>
              The information we collect; (gender, age, date of birth, race and
              ethnicity, sexual orientation, geolocation, and religious or
              philosophical beliefs ) is used solely for the purpose of
              providing and improving our services. We may collect and analyze
              demographic information to generate statistical data and display
              charts illustrating the demographic breakdown of our users. This
              demographic information is presented in aggregate form and is used
              to enhance the functionality and user experience on our platform.
              It is not shared with or sold to any external parties.
            </P>
            <H1>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</H1>
            <PrivacyQA
              question="In Short:"
              answer=" We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services."
              i
            />
            <P>
              The Services may link to third-party websites, online services, or
              mobile applications and/or contain advertisements from third
              parties that are not affiliated with us and which may link to
              other websites, services, or applications. Accordingly, we do not
              make any guarantee regarding any such third parties, and we will
              not be liable for any loss or damage caused by the use of such
              third-party websites, services, or applications. The inclusion of
              a link towards a third-party website, service, or application does
              not imply an endorsement by us. We cannot guarantee the safety and
              privacy of data you provide to any third parties. Any data
              collected by third parties is not covered by this privacy notice.
              We are not responsible for the content or privacy and security
              practices and policies of any third parties, including other
              websites, services, or applications that may be linked to or from
              the Services. You should review the policies of such third parties
              and contact them directly to respond to your questions.
            </P>
            <H1>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</H1>
            <PrivacyQA
              question="In Short:"
              answer="We may use cookies and other tracking technologies to collect and store your information."
              i
            />
            <P>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to gather information when you interact with
              our Services. Some online tracking technologies help us maintain
              the security of our Services and your account, prevent crashes,
              fix bugs, save your preferences, and assist with basic site
              functions.
            </P>
            <P>
              We also permit third parties and service providers to use online
              tracking technologies on our Services for analytics and
              advertising, including to help manage and display advertisements,
              to tailor advertisements to your interests, or to send abandoned
              shopping cart reminders (depending on your communication
              preferences). The third parties and service providers use their
              technology to provide advertising about products and services
              tailored to your interests which may appear either on our Services
              or on other websites.
            </P>
            <P>
              To the extent these online tracking technologies are deemed to be
              a "sale"/"sharing" (which includes targeted advertising, as
              defined under the applicable laws) under applicable US state laws,
              you can opt out of these online tracking technologies by
              submitting a request as described below under section{" "}
              <SectionLink
                text={`"DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"`}
                link=""
              />
            </P>
            <P>
              Specific information about how we use such technologies and how
              you can refuse certain cookies is set out in our Cookie Notice
            </P>
            <H2>Google Analytics</H2>
            <P>
              We may share your information with Google Analytics to track and
              analyze the use of the Services. To opt out of being tracked by
              Google Analytics across the Services, visit{" "}
              <SectionLink
                text="https://tools.google.com/dlpage/gaoptout."
                link="https://tools.google.com/dlpage/gaoptout"
              />{" "}
              For more information on the privacy practices of Google, please
              visit the{" "}
              <SectionLink
                text="Google Privacy & Terms page"
                link="https://policies.google.com/privacy"
              />
            </P>
            <H1>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</H1>
            <PrivacyQA
              question="In Short:"
              answer="If you choose tho register or log in to our Services using a social media account, we may have access to certain information about you."
              i
            />
            <P>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Google
              login). Where you choose to do this, we will receive certain
              profile information about you from your social media provider. The
              profile information we receive may vary depending on the social
              media provider concerned, but will often include your name, email
              address, friends list, and profile picture, as well as other
              information you choose to make public on such a social media
              platform.
            </P>
            <P>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </P>
            <H1>HOW LONG DO WE KEEP YOUR INFORMATION?</H1>
            <PrivacyQA
              question="In Short:"
              answer="We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law."
              i
            />
            <P>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </P>
            <P>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </P>
            <H1>HOW DO WE KEEP YOUR INFORMATION SAFE?</H1>
            <PrivacyQA
              question="In Short:"
              answer="We aim to protect your personal information through a system of organizational and technical security measures."
              i
            />
            <P>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </P>
            <H1>WHAT ARE YOUR PRIVACY RIGTS?</H1>
            <PrivacyQA
              question="In Short:"
              answer="Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdon (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence."
              i
            />
            <P>
              In some regions (like the EEA, UK, Switzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision-making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided in
              the section{" "}
              <SectionLink
                text={`"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"`}
                link="#how-can-you-contact-us-about-this-notice"
              />{" "}
              below.
            </P>
            <P>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </P>
            <P>
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your{" "}
              <SectionLink
                text="Member State data protection authority"
                link="https://ec.europa.eu/newsroom/article29/items/612080"
              />{" "}
              or{" "}
              <SectionLink
                text="UK data protection authority."
                link="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
              />
            </P>
            <P>
              If you are located in Switzerland, you may contact the{" "}
              <SectionLink
                text="Federal Data Protection and Information Commissioner"
                link="https://www.edoeb.admin.ch/edoeb/en/home.html"
              />
            </P>
            <P>
              <b>
                <u id="withdraw-your-consent">Withdrawing your consent:</u>
              </b>{" "}
              If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section{" "}
              <SectionLink
                text={`"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"`}
                link="#how-can-you-contact-us-about-this-notice"
              />{" "}
              below or updating your preferences.
            </P>
            <P>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </P>
            <H2>Account Information</H2>
            <P>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </P>
            <UL>
              <li className="mb-4">
                Log in to your account settings and update your user account.
              </li>
            </UL>
            <P>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </P>
            <P>
              <b>
                <u>Cookies and similar technologies:</u>
              </b>
              {
                " Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also"
              }
              <SectionLink
                text="opt out of Interest-based advertising by advertisers"
                link="https://optout.aboutads.info/?c=2&lang=EN"
              />{" "}
              on our Services.
            </P>
            <P>
              If you have any questions or comments about your privacy rights,
              you may email us a <MailLink mail="support@poliqs.com" />.
            </P>
            <H1>CONTROLS FOR DO-NOT-TRACK FEATURES</H1>
            <P>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage, no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </P>
            <P>
              California law requires us to let you know how we respond to web
              browser DNT signals. Because there currently is not an industry or
              legal standard for recognizing or honoring DNT signals, we do not
              respond to them at this time.
            </P>
            <H1>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</H1>
            <PrivacyQA
              question="In Short:"
              answer=" California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below."
              i
            />
            <table className="border border-black mb-9">
              <tr className="border border-black">
                <th className="w-1/3">Category</th>
                <th className="w-3/5">Examples</th>
                <th className="w-14">Collected</th>
              </tr>
              {collectedInfo.map((ci) => {
                return (
                  <tr className="border border-black">
                    <td className="p-2 border border-black">{ci.category}</td>
                    <td className="p-2 border border-black">{ci.examples}</td>
                    <td className="p-2 center">
                      {ci.collected ? "YES" : "NO"}
                    </td>
                  </tr>
                );
              })}
            </table>
            <P>
              We only collect sensitive personal information, as defined by
              applicable privacy laws or the purposes allowed by law or with
              your consent. Sensitive personal information may be used, or
              disclosed to a service provider or contractor, for additional,
              specified purposes. You may have the right to limit the use or
              disclosure of your sensitive personal information. We do not
              collect or process sensitive personal information for the purpose
              of inferring characteristics about you.
            </P>
            <P>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </P>
            <UL>
              <li className="mb-4">
                Receiving help through our customer support channels;
              </li>
              <li className="mb-4">
                Partcipation in customer surveys or contests; and
              </li>
              <li className="mb-4">
                Facilitation in the delivery of our Services and to respond to
                your inquiries
              </li>
            </UL>
            <P>
              We will use and retain the collected personal information as
              needed to provide the Services or for:
            </P>
            <UL>
              <li className="mb-4">
                Category C - As long as the user chooses to share this
                information
              </li>
              <li className="mb-4">
                Category G - As long as the user chooses to share this
                information
              </li>
              <li className="mb-4">
                Category L - As long as the user chooses to share this
                information
              </li>
            </UL>
            <H2>Sources of Personal Information</H2>
            <P>
              Learn more about the sources of personal information we collect in{" "}
              <SectionLink
                text={`"WHAT INFORMATION DO WE COLLECT?"`}
                link="#what-information-do-we-collect"
              />
            </P>
            <H2>How We Use and Share Personal Information</H2>
            <P>
              Learn more about how we use your personal information in the
              section,
              <SectionLink
                text={`"HOW DO WE PROCESS YOUR INFORMATION"`}
                link="#how-do-we-process-your-information"
              />
            </P>
            <P b>Will your information be shared with anyone else?</P>
            <P>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.
            </P>
            <H2>Your Rights</H2>
            <P>
              You have rights under certain US state data protection laws.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law. These rights
              include:
            </P>
            <UL>
              <li>
                <PrivacyQA
                  question="Right to know"
                  answer="whether or not we are processing you personal data"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Right to access"
                  answer="your personal data"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Right to correct"
                  answer="inaccuracies in your personal data"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Right to request"
                  answer="the deletion of your personal data"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Right to obtain a copy"
                  answer="of the personal data you previously shared with us"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Right to non-discrimination"
                  answer="for exercising your rights"
                />
              </li>
              <li>
                <PrivacyQA
                  question="Righ to opt out"
                  answer={`of the processing of your personal data if it is used for targed advertising (or sharing as defined under California's privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")`}
                />
              </li>
            </UL>
            <P>
              Depending upon the state where you live, you may also have the
              following rights:
            </P>
            <UL>
              <li className="mb-4">
                Right to obtain a list of the categories of third parties to
                which we have disclosed personal data (as permitted by
                applicable law, including California's and Delaware's privacy
                law)
              </li>
              <li className="mb-4">
                Right to obtain a list of specific third parties to which we
                have disclosed personal data (as permitted by applicable law,
                including Oregon’s privacy law)
              </li>
              <li className="mb-4">
                Right to limit use and disclosure of sensitive personal data (as
                permitted by applicable law, including California’s privacy law)
              </li>
              <li className="mb-4">
                Right to opt out of the collection of sensitive data and
                personal data collected through the operation of a voice or
                facial recognition feature (as permitted by applicable law,
                including Florida’s privacy law)
              </li>
            </UL>
            <H2>How to Exercise Your Rights</H2>
            <P>
              To exercise these rights, you can contact us by emailing us at{" "}
              <MailLink mail="support@poliqs.com" />.
            </P>
            <P>
              Under certain US state data protection laws, you can designate an
              authorized agent to make a request on your behalf. We may deny a
              request from an authorized agent that does not submit proof that
              they have been validly authorized to act on your behalf in
              accordance with applicable laws.
            </P>
            <H2>Appeals</H2>
            <P>
              Under certain US state data protection laws, if we decline to take
              action regarding your request, you may appeal our decision by
              emailing us at <MailLink mail="support@poliqs.com" />. We will
              inform you in writing of any action taken or not taken in response
              to the appeal, including a written explanation of the reasons for
              the decisions. If your appeal is denied, you may submit a
              complaint to your state attorney general.
            </P>
            <H2>California "Shine The Light" Law</H2>
            <P>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us by using the
              contact details provided in the section{" "}
              <SectionLink
                text="HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                link="#how-can-you-contact-us-about-this-notice"
              />
            </P>
            <H1>DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS</H1>
            <PrivacyQA
              question="In Short:"
              answer="You may have additional rights based on the country you reside in."
              i
            />
            <H2>Australia and New Zealand</H2>
            <P>
              We collect and process your personal information under the
              obligations and conditions set by Australia's Privacy Act 1988 and
              (Privacy Act).
            </P>
            <P>
              This privacy notice satisfies the notice requirements defined in
              both Privacy Acts, in particular: what personal information we
              collect from you, from which sources, for which purposes, and
              other recipients of your personal information.
            </P>
            <P>
              If you do not wish to provide the personal information necessary
              to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </P>
            <UL>
              <li className="mb-4">
                offer you the products or services that you want
              </li>
              <li className="mb-4">respond to or help with your requests</li>
              <li className="mb-4">manage your account with us</li>
              <li className="mb-4">
                confirm your identity and protect your account
              </li>
            </UL>
            <P>
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section{" "}
              <SectionLink
                text="HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
                link="#how-can-you-review-update-or-delete-the-data-we-collect-from-you"
              />
            </P>
            <P>
              If you believe we are unlawfully processing your personal
              information, you have the right to submit a complaint about a
              breach of the Australian Privacy Principles to the{" "}
              <SectionLink
                text="Office of the Australian Information Commissioner"
                link="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
              />
              and a breach of New Zealand's Privacy Principles to the{" "}
              <SectionLink
                text="Office of New Zealand Privacy Commisioner."
                link="https://www.privacy.org.nz/your-rights/making-a-complaint-to-the-privacy-commissioner/"
              />
            </P>
            <P>
              If you are unsatisfied with the manner in which we address any
              complaint with regard to our processing of personal information,
              you can contact the office of the regulator, the details of which
              are:
            </P>
            <div>
              <SectionLink
                text="The Information Regulator (South Africa)"
                link="https://inforegulator.org.za/"
              />
            </div>
            <div>
              General enquiries:{" "}
              <a
                className="text-blue-500"
                href="enquiries@inforegulator.org.za"
              >
                enquiries@inforegulator.org.za
              </a>
            </div>
            <div>
              Complaints (complete POPIA/PAIA form 5):{" "}
              <a
                className="text-blue-500"
                href="PAIAComplaints@inforegulator.org.za"
              >
                PAIAComplaints@inforegulator.org.za
              </a>
              {" & "}
              <a
                className="text-blue-500"
                href="POPIAComplaints@inforegulator.org.za"
              >
                POPIAComplaints@inforegulator.org.za
              </a>
            </div>
            <H1>DO WE MAKE UPDATES TO THIS NOTICE?</H1>
            <PrivacyQA
              question="In Short:"
              answer="Yes, we will update this notice as necessary to stay complian with relevant laws."
              i
            />
            <P>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date at the top
              of this privacy notice. If we make material changes to this
              privacy notice, we may notify you either by prominently posting a
              notice of such changes or by directly sending you a notification.
              We encourage you to review this privacy notice frequently to be
              informed of how we are protecting your information.
            </P>
            <H1>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</H1>
            <P>
              If you have questions or comments about this notice, you may email
              us at <MailLink mail="support@poliqs.com" />.
            </P>
            <H1>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </H1>
            <P>
              You have the right to request access to the personal information
              we collect from you, details about how we have processed it,
              correct inaccuracies, or delete your personal information. You can
              review or update your personal information in your Account
              Setttings and delete your information by terminating your account.
            </P>
            <div className="text-red-900 h-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
