import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";

import {
  closeModal,
  selectModal,
} from "../../../../../store/features/modalSlice";
import Loading from "../../../../Loading";
import { unblockUser } from "../../../../../api/privacyApi";
import { toaster } from "../../../../../utils/toast";
import { addUnblocked } from "../../../../../store/features/privacySlice";

interface BlockUsersModalModalProps {
  modalRef: any;
}

const UnblockUsersModal = ({ modalRef }: BlockUsersModalModalProps) => {
  const dispatch = useDispatch();

  const { blockUserId } = useSelector(selectModal);

  const resetModal = () => {
    dispatch(closeModal());
  };

  const confirmUnblock = async () => {
    blockUserId &&
      (await unblockUser(blockUserId)
        .then(() => {
          dispatch(addUnblocked(blockUserId));
          toaster({ text: "User unblocked" });
          resetModal();
        })
        .catch((error) => {
          console.error(error);
        }));
  };
  return (
    <div ref={modalRef} className="card w-[440px] min-w-[400px]">
      <div className="flex justify-between mb-3">
        <div className="text-lg font-semibold">Block User</div>

        <button onClick={resetModal}>
          <IoMdClose />
        </button>
      </div>
      <Suspense fallback={<Loading />}>
        <div className="text-normal mb-5">
          Are you sure you want to unblock this user?.
        </div>
        <div className="flex justify-end">
          <button className="button" onClick={resetModal}>
            Cancel
          </button>
          <button className="button-cancel" onClick={confirmUnblock}>
            Unblock
          </button>
        </div>
      </Suspense>
    </div>
  );
};

export default UnblockUsersModal;
