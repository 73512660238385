import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div className="center w-full">
      <ReactLoading type="bars" color="#ccc" />
    </div>
  );
};

export default Loading;
