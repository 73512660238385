import { useState } from "react";

import { commentOnQuestion } from "../../../../api/questionApi";
import CommentBox from "./CommentBox";
import { errorCatcher } from "../../../../utils/functions";
import "./comments.css";

interface CommentProps {
  questionId: string;
  reset: () => void;
}

const Comment = ({ questionId, reset }: CommentProps) => {
  const [commenting, setCommenting] = useState(false);
  const [comment, setComment] = useState("");

  const toggleCommenting = () => {
    setCommenting(!commenting);
  };

  const createComment = () => {
    commentOnQuestion({ questionId, text: comment })
      .then(() => {
        setComment("");
        reset();
      })
      .catch((error) => {
        errorCatcher(error, "You must be logged in to comment");
      });
  };

  return (
    <>
      {commenting ? (
        <CommentBox
          comment={comment}
          setComment={setComment}
          commenting={commenting}
          toggleCommenting={toggleCommenting}
          createComment={createComment}
        />
      ) : (
        <button className="add-comment-button" onClick={toggleCommenting}>
          Add a Comment...
        </button>
      )}
    </>
  );
};

export default Comment;
