import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";

import Feed from "./components/feed";
import Header from "./components/navigation/Header";
import QuestionPage from "./components/pages/QuestionPage";
import Premium from "./components/pages/Premium";
import { authenticate } from "./api/authApi";
import { UserState, removeUser, setUser } from "./store/features/authSlice";
import AuthRoute from "./components/navigation/AuthRoute";
import * as strings from "./constants/strings";
import VerifyEmail from "./components/pages/VerifyEmail";
import { errorCatcher } from "./utils/functions";
import ChangePassword from "./components/pages/ChangePassword";
import TOS from "./components/pages/TOS";
import PrivacyPolicy from "./components/pages/TOS/PrivacyPolicy";
import About from "./components/pages/TOS/About";
import ContentPolicy from "./components/pages/ContentPolicy";
import "react-toastify/dist/ReactToastify.css";

function App() {
  ReactGA.initialize("G-CDHPX0FTQN");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setLoginState = (user: UserState) => {
    dispatch(setUser(user));
  };

  const clearUserState = () => {
    dispatch(removeUser());
  };

  const cookieToken = Cookies.get(strings.COOKIE_TOKEN);

  const helmetContext = {};

  useQuery({
    queryKey: ["auth", cookieToken],
    queryFn: async () => {
      if (cookieToken)
        return authenticate()
          .then((response) => {
            const data = response.data.data;
            setLoginState({
              userId: data.id,
              email: data.email,
              username: data.username,
              picture: data.picture,
              premium: data.premium,
              emailVerified: data.emailVerified,
            });
            return data;
          })
          .catch((error) => {
            errorCatcher(error);
            clearUserState();
            Cookies.remove(strings.COOKIE_TOKEN);
            navigate("/");
            return null;
          });
      return null;
    },
    refetchInterval: 150000,
  });

  useEffect(() => {
    if (token) {
      Cookies.set(strings.COOKIE_TOKEN, token);
      const newURL = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", newURL);
    }
  }, [token]);

  return (
    <HelmetProvider context={helmetContext}>
      <ToastContainer />
      <Header>
        <div>
          <Routes>
            <Route path="/" element={<Feed />} />
            <Route path="/question/:questionId" element={<QuestionPage />} />
            <Route
              path="/profile/:username"
              element={<AuthRoute page={strings.PAGES_PROFILE} />}
            />
            <Route
              path="/settings"
              element={<AuthRoute page={strings.PAGES_SETTINGS} />}
            />
            <Route path="/premium" element={<Premium />} />
            <Route path="/verify/:token" element={<VerifyEmail />} />
            <Route path="/password/:token" element={<ChangePassword />} />
            <Route path="/about" element={<About />} />
            <Route path="/content" element={<ContentPolicy />} />
            <Route path="/tos" element={<TOS />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Header>
    </HelmetProvider>
  );
}

export default App;
