import { RefObject, useEffect, useState } from "react";
import { useHeadsObserver } from "../../../hooks/useHeadsObserver";
import Section, { BulletedList, MailLink, P, SectionLink } from "./Section";
import TableOfContents from "./TableOfContents";

interface TOSProps {
  scrollRef: RefObject<HTMLDivElement>;
}

const TOS_EU = ({ scrollRef }: TOSProps) => {
  const [headings, setHeadings] = useState<
    Array<{ id: string; text: string; level: number }>
  >([]);

  useEffect(() => {
    if (scrollRef?.current) {
      const elements = Array.from(
        scrollRef.current.querySelectorAll("h2, h3, h4")
      ).map((elem) => {
        const htmlElem = elem as HTMLElement; // Type assertion
        return {
          id: elem.id,
          text: htmlElem.innerText,
          level: Number(elem.nodeName.charAt(1)),
        };
      });
      setHeadings(elements);
    }
  }, []);

  const { activeId } = useHeadsObserver(scrollRef);
  return (
    <div className="flex justify-center h-full">
      <div className="scrollbar-hidden max-w-[800px] panel:mr-[350px] mr-0">
        <Section title="">
          <p className="text-[35px] font-bold mb-6">Terms of Service</p>
          <p className="text-lg font-semibold mb-4">
            If you live in the European Union, EFTA States, or the United
            Kingdom
          </p>
          This POLIQS User Agreement (“Terms”) applies to your access to and use
          of the websites, and other online products and services (collectively,
          the “Services”) provided by POLIQS. (”POLIQS”, “we,” “us,” or “our”).
        </Section>
        <Section title="Your Access to the services">
          <div className="text-lg">
            <P>No one under the age of 13 is allowed to use our Services.</P>
            <BulletedList
              head="By using our Services, you state that:"
              items={[
                "You are at least 13 years of age and over the minimum age required by the laws of your country of residence to use our Services",
                "You can form a binding contract with our site, or, if you are over 13 but under the age of majority in your jurisdiction, that your legal guardian has reviewed and agrees to these Terms",
                "You are no barred from using our Services under all applicable laws; and",
                "You have not been previously suspended or banned from using ourServices",
              ]}
            />
            <P>
              If you are accepting these Terms on behalf of another legal
              entity, including a business or government entity, you represent
              that you have full legal authority to bind such entity to these
              Terms.
            </P>
          </div>
        </Section>
        <Section title="Privacy">
          <P>
            POLIQS' <SectionLink text="Privacy Policy" link="/privacy" />{" "}
            explains how and why we collect, use and share information about you
            when you access our Services. By using our services, you consent to
            the collection and use of this information, including the transfer
            of this information to the United States for storage and processing.
          </P>
        </Section>
        <Section title="Content">
          <P>
            You are responsible for the use of the Services and any Content you
            provide, including compliance with applicable laws, rules, and
            regulations. You should only provide Content that you are
            comfortable sharing with others.
          </P>
          <P>
            Your use of or reliance on any content or materials shared through
            the Services is entirely at your own risk. We do not endorse,
            support, verify, or guarantee the completeness, truthfulness,
            accuracy, or reliability of any content or communications shared
            through the Services, nor do we endorse any opinions expressed
            within them. You acknowledge that by using the Services, you may
            encounter content that is offensive, harmful, inaccurate, or
            otherwise inappropriate, including posts that are mislabeled or
            misleading. All content is the sole responsibility of the individual
            who created it. We may not monitor or control the content posted
            through the Services and therefore cannot assume responsibility for
            it.
          </P>
          <P>
            We reserve the right to remove content that breaches the User
            Agreement, such as copyright or trademark infringements, other
            intellectual property theft, impersonation, illegal activities, or
            harassment.
          </P>
          <P>
            If you suspect that your content has been copied in a manner that
            violates copyright, please report it by sending an email to{" "}
            <MailLink mail="support@poliqs.com" />
          </P>
        </Section>
        <Section title="Use of our Services">
          <P>
            We are always improving our Services. At any point we may add or
            remove products, featurs or functionalities. We reserve the right to
            modify, suspend or discontinue the Services (in whole or in part) at
            any time, without notice. Any future addions to our Services will be
            subject to these Terms which may be updated from time to time. You
            agree we are not liable for any addition or remval of the Services
            or any part thereof.
          </P>
        </Section>
        <Section title="Limited Liability">
          By using the Services you agree that POLIQS, its parents, affiliates,
          related companies, officers, directors, employees, agents
          representatives, partners and licensors, liability is limited to the
          maximum extent permissible in your country of residence.
        </Section>
        <Section title="General">
          <P>
            We may revise these Terms from time to time. The changes will not be
            retroactive, and the most current version of the Terms, which will
            always be at poliqs.com/tos, will govern our relationship with you.
            Other than for changes addressing new functions or made for legal
            reasons, we will notify you 30 days in advance of making effective
            changes to these Terms that impact the rights or obligations of any
            party to these Terms, for example via a service notification or an
            email to the email associated with your account. By continuing to
            access or use the Services after those revisions become effective,
            you agree to be bound by the revised Terms.
          </P>
          <P>
            In the event that any provision of these Terms is held to be invalid
            or unenforceable, then that provision will be limited or eliminated
            to the minimum extent necessary, and the remaining provisions of
            these Terms will remain in full force and effect. Our failure to
            enforce any right or provision of these Terms will not be deemed a
            waiver of such right or provision.
          </P>
        </Section>
        <Section title="Termination">
          <P>
            You may terminate these Terms at any time and for any reason by
            deleting your Account and discontinuing use of all Services.
          </P>
          <P>
            We reserve the right to suspend or terminate your account at any
            time for any or no reason, including violating our
            <SectionLink text=" Content Policy" link="" />.
          </P>
        </Section>
        <div className="h-40" />
      </div>
      <div className="hidden panel:block fixed w-[350px] right-0 p-8">
        <TableOfContents
          headings={headings}
          activeId={activeId}
          scrollRef={scrollRef}
        />
      </div>
    </div>
  );
};

export default TOS_EU;
