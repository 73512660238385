import { useState } from "react";

import Modal from "../../common/Modal";
import ModalMenu from "./ModalMenu";
import { Religion } from "../../../../../types/enums";

interface ReligionModalProps {
  modalRef: any;
}

const ReligionModal = ({ modalRef }: ReligionModalProps) => {
  const [selection, setSelection] = useState("");
  return (
    <div ref={modalRef}>
      <Modal title="Religion">
        <ModalMenu
          type="religion"
          valuesEnum={Religion}
          selection={selection}
          setSelection={setSelection}
        />
      </Modal>
    </div>
  );
};

export default ReligionModal;
