interface LineBreakProps {
  text?: string;
}

const LineBreak = ({ text }: LineBreakProps) => {
  return (
    <div className="inline-flex items-center justify-center w-full">
      <hr className="w-full h-px bg-gray-200 border-0 dark:bg-gray-700" />
      <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-[#1f2937]">
        {text}
      </span>
    </div>
  );
};

export default LineBreak;
