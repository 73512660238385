import {
  RefObject,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { QuestionData } from "../types";

const useScrollFetch = (ref: RefObject<HTMLDivElement>) => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = element;
        if (scrollTop + clientHeight >= scrollHeight - 1000) {
          setLoad(true);
        }
      };

      element.addEventListener("scroll", handleScroll);

      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return { load, setLoad };
};

const useInitializeFeed = (
  setFeed: Dispatch<SetStateAction<Array<QuestionData>>>,
  loadMoreQuestions: (page: number) => Promise<void>,
  dependencies: Array<any> = []
) => {
  useEffect(() => {
    const initializeFeed = async () => {
      setFeed([]);
      await loadMoreQuestions(1).catch((error) => console.error(error));
    };

    initializeFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

const useFetchFeedOnFetchLoad = (
  load: boolean,
  setLoad: Dispatch<SetStateAction<boolean>>,
  loadMoreQuestions: (page: number) => Promise<void>,
  hasNext: boolean,
  page: number
) => {
  useEffect(() => {
    const fetchData = async () => {
      if (load) {
        await loadMoreQuestions(page);
        setLoad(false);
      }
    };

    if (hasNext) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);
};

export { useInitializeFeed, useScrollFetch, useFetchFeedOnFetchLoad };
