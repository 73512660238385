import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { register } from "../../api/authApi";
import { UserState, setUser } from "../../store/features/authSlice";
import FormikInputField from "../../utils/FormikInputField";
import { closeModal } from "../../store/features/modalSlice";
import Cookies from "js-cookie";
import { COOKIE_TOKEN } from "../../constants/strings";
import { toaster } from "../../utils/toast";
import LineBreak from "../../utils/LineBreak";
import GoogleButton from "./GoogleButton";

const RegistrationModal = () => {
  const dispatch = useDispatch();

  const setLoginState = (user: UserState) => {
    dispatch(setUser(user));
  };

  const resetModal = () => {
    dispatch(closeModal());
  };

  const schema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter your email!"),
    username: yup.string().required("Please enter a username!"),
    password: yup
      .string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Please enter a password!"),
    confirmPassword: yup
      .string()
      .required("Please confirm your password!")
      .oneOf([yup.ref("password")], "Passwords must match!"),
  });

  return (
    <>
      <div className="center text-2xl font-semibold">Sign Up</div>
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          email: "",
          username: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={async (values) => {
          register(
            values.email,
            values.username,
            values.password,
            values.confirmPassword
          )
            .then((response) => {
              const data = response.data.data;
              Cookies.set(COOKIE_TOKEN, data.token);
              setLoginState({
                userId: data.id,
                email: data.email,
                username: data.username,
                picture: "",
                premium: data.premium,
                emailVerified: data.emailVerified,
              });
              resetModal();
            })
            .catch((error) => {
              toaster({ text: error.response.data.message });
            });
        }}
      >
        {({ errors }) => (
          <Form>
            <FormikInputField name="email" error={errors.email} />
            <FormikInputField name="username" error={errors.username} />
            <FormikInputField
              name="password"
              error={errors.password}
              type="password"
              autocomplete="new-password"
            />
            <FormikInputField
              name="confirmPassword"
              error={errors.confirmPassword}
              type="password"
            />
            <div className="center">
              <button className="button" type="submit">
                Sign Up
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <LineBreak text="or" />
      <div className="center mt-5">
        <GoogleButton login={false} />
      </div>
    </>
  );
};

export default RegistrationModal;
