import { Field, FieldProps, Form, Formik } from "formik";
import Modal from "../../../common/Modal";
import { useQuery } from "@tanstack/react-query";

import { Fragment, useState } from "react";
import { getMe, updateProfile } from "../../../../../../api/profileApi";
import { toaster } from "../../../../../../utils/toast";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../store/features/modalSlice";

const BioModal = () => {
  const dispatch = useDispatch();

  const [changing, setChanging] = useState(false);
  const [initialValues, setInitialValues] = useState<{ bio: string }>({
    bio: "",
  });

  const updateBio = async (text: string) => {
    await updateProfile({ type: "bio", text }).then().catch();
  };

  const resetModal = () => {
    dispatch(closeModal());
  };

  const { data } = useQuery({
    queryKey: ["getMe", changing],
    queryFn: async () => {
      return getMe()
        .then((response) => {
          setInitialValues({ bio: response.data.bio || "" });
          return response.data;
        })
        .catch(() => {
          return null;
        });
    },
  });

  return (
    <Modal title="Bio">
      <div className="flex-col w-[400px] h-[300px]">
        {data?.bio && !changing ? (
          <div className="flex flex-col h-full justify-between">
            <p>
              {data?.bio.split("\n").map((line: string, index: number) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </p>
            <button
              type="button"
              className="button"
              onClick={() => setChanging(!changing)}
            >
              Change
            </button>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              updateBio(values.bio)
                .then(() => {
                  toaster({ text: "Updated Bio" });
                  setChanging(false);
                  resetModal();
                })
                .catch((error) => {
                  toaster({ text: error.response.data.message });
                });
            }}
          >
            {({ values }) => (
              <Form className="w-full h-3/4">
                <Field name="bio">
                  {(props: FieldProps) => (
                    <div className="flex flex-col h-full">
                      <textarea
                        maxLength={450}
                        className="textarea h-full"
                        {...props.field}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="text-end">{values.bio.length} / 450</div>
                    </div>
                  )}
                </Field>
                <button className="button mt-5">Update</button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

export default BioModal;
