import { useEffect, useRef, useState } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import { isEmpty } from "lodash";

import { blendMultipleColors, getBoundingBox } from "../../../../../utils/math";
import "mapbox-gl/dist/mapbox-gl.css";
import { P } from "../../../../pages/TOS/Section";
import { useQuery } from "@tanstack/react-query";

interface MapboxMapProps {
  locationData?: { [key: string]: Record<string, number> };
  colorMap: { [key: string]: string };
}

const MapboxMap = ({ locationData, colorMap }: MapboxMapProps) => {
  const initalFeatureState = { sub: "", name: "" };
  const [map, setMap] = useState<Map | null>(null);
  const [hoveredCountry, setHoveredCountry] = useState("");
  const [selectedFeature, setSelectedFeature] = useState<{
    [name: string]: any;
  }>(initalFeatureState);
  const [subDivision, setSubdivision] = useState("");
  const [level, setLevel] = useState("world");
  const [showSelect, setShowSelect] = useState(false);

  const mapNode = useRef(null);

  const getDominantColor = (countryColors: {
    [key: string]: Record<string, number>;
  }) => {
    const stateColorMap: { [key: string]: string } = {};

    Object.entries(countryColors).forEach(([country, values]) => {
      if (isEmpty(values)) {
        stateColorMap[country] = "#ccc";
      } else {
        const colors = Object.entries(values).map(([c, v]) => {
          return { [colorMap[c]]: v };
        });

        const blended = blendMultipleColors(colors);
        stateColorMap[country] = blended;
      }
    });
    return stateColorMap;
  };

  useEffect(() => {
    const node = mapNode.current;

    if (node === null) return;
    const initializeMap = () => {
      const mapboxMap = new mapboxgl.Map({
        container: node,
        accessToken: process.env.REACT_APP_MAP_BOX_ACCESS_KEY,
        center: [-71.0589, 42.3601],
        zoom: 2,
      });

      mapboxMap.on("load", () => {
        setMap(mapboxMap);
      });

      const navigation = new mapboxgl.NavigationControl();
      if (!mapboxMap.hasControl(navigation)) mapboxMap.addControl(navigation);
    };

    if (!map) initializeMap();
  }, [map]);

  const countryClick = (e: any) => {
    if (level === "world") {
      console.log(level);
      const selectedFeatures = map!.queryRenderedFeatures(e.point, {
        layers: ["country-fills"],
      });

      const selected = selectedFeatures[0];
      if (selected) {
        const geometry: any = selected.geometry;

        if (selected.properties?.hasSubUnit) {
          setShowSelect(true);
          setSelectedFeature(selected.properties);
          setSubdivision(selected.properties?.subUnit);
        } else {
          setShowSelect(false);
        }

        map!.fitBounds(getBoundingBox(geometry), {
          padding: { top: 20, left: 20, bottom: 20, right: 20 },
        });
      }
    }
  };

  const provinceClick = (e: any) => {
    const selectedFeatures = map!.queryRenderedFeatures(e.point, {
      layers: ["province-fills"],
    });

    const selected = selectedFeatures[0];

    console.log(selected);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    const dominantColors = isEmpty(locationData)
      ? {}
      : getDominantColor(locationData);

    const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

    const baseUrl = `${DOMAIN}/api/v1/maps/`;
    const fullUrl = selectedFeature.sub
      ? `${baseUrl}?${searchParams.toString()}`
      : baseUrl;

    if (map) {
      if (level === "world") {
        map.addSource("countries", {
          type: "geojson",
          data: fullUrl,
        });

        if (!map.getLayer("country-fills")) {
          map.addLayer({
            id: "country-fills",
            type: "fill",
            source: "countries",
            layout: {},
            paint: {
              "fill-color": [
                "match",
                ["get", "name"],
                ...Object.keys(dominantColors).reduce<string[]>(
                  (acc, countryId) => {
                    acc.push(countryId);
                    acc.push(dominantColors[countryId]);
                    return acc;
                  },
                  []
                ),
                "#ccc",
              ],
              "fill-opacity": 0.8,
            },
          });
        }

        if (!map.getLayer("country-border")) {
          map.addLayer({
            id: "country-border",
            type: "line",
            source: "countries",
            layout: {},
            paint: {
              "line-color": "black",
              "line-width": 1,
            },
          });
        }

        map.on("mousemove", "country-fills", (e) => {
          if (e.features.length > 0) {
            setHoveredCountry(e.features[0].properties.name);
          }
        });

        map.on("mouseleave", "country-fills", () => {
          setHoveredCountry("");
        });

        map.on("click", countryClick);
      }

      if (level === "province") {
        console.log("province");
        map.off("click", countryClick);
        if (!map.getSource("provinces")) {
          map.addSource("provinces", {
            type: "geojson",
            data: `${baseUrl}subMap?country=${subDivision}`,
          });
        }

        if (!map.getLayer("province-fills"))
          map.addLayer({
            id: "province-fills",
            type: "fill",
            source: "provinces",
            layout: {},
            paint: {
              "fill-color": [
                "match",
                ["get", "name"],
                ...Object.keys(dominantColors).reduce<string[]>(
                  (acc, countryId) => {
                    acc.push(countryId);
                    acc.push("#ccc");
                    return acc;
                  },
                  []
                ),
                "#ccc",
              ],
              "fill-opacity": 0.8,
            },
          });

        if (!map.getLayer("province-border"))
          map.addLayer({
            id: "province-border",
            type: "line",
            source: "provinces",
            layout: {},
            paint: {
              "line-color": "black",
              "line-width": 1,
            },
          });
      }
    }

    const removeSourceIfExists = (sourceId: string) => {
      if (map && map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };

    const removeLayerIfExists = (layerId: string) => {
      if (map && map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    };

    return () => {
      if (map) {
        map.off("click", countryClick);
        [
          "country-fills",
          "country-border",
          "province-fills",
          "province-border",
        ].forEach(removeLayerIfExists);
        ["countries", "provinces"].forEach(removeSourceIfExists);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, locationData, level]);

  useQuery({
    queryKey: ["locationData"],
    queryFn: async () => {},
  });

  if (isEmpty(locationData))
    return <div className="center">No Data to Display at this time</div>;

  return (
    <>
      <div className="mb-8 font-semibold">Location</div>
      {hoveredCountry !== "" ? (
        <div className="absolute bg-gray-200 z-10 right-0 bottom-0 m-2 w-40 text-center rounded-lg p-2 text-black">
          <div className="text-sm font-semibold">{hoveredCountry}</div>
          <div className="center p-2">
            <table>
              <tbody>
                {Object.entries(locationData[hoveredCountry])
                  .sort(([, av], [, bv]) => {
                    if (av > bv) return -1;
                    if (bv > av) return 1;
                    return 0;
                  })
                  .map(([k, v]) => {
                    return (
                      <tr key={k} className="flex">
                        <td
                          className="h-5 w-5 rounded-md mr-2"
                          style={{ background: colorMap[k] }}
                        />
                        <td className="text-sm">{v}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {false && (
        <div className="absolute bg-gray-200 z-10 m-2 p-3 rounded-lg left-0 top-32-0 flex flex-col items-center">
          <select value={level} onChange={(e) => setLevel(e.target.value)}>
            <option value="world">World</option>
            <option value="province">{selectedFeature?.name}</option>
          </select>
        </div>
      )}
      <div
        ref={mapNode}
        className="rounded-lg"
        style={{ minWidth: "100px", minHeight: "400px" }}
      />
    </>
  );
};

export default MapboxMap;
