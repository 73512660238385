import { useState, Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { FaHeart, FaChartSimple } from "react-icons/fa6";
import { MdChat, MdOutlineQuestionMark } from "react-icons/md";

import QuestionFooterButton from "./QuestionFooterButtons";
import { likeQuestion, voteOnQuestion } from "../../../api/questionApi";
import { QuestionType } from "../../../types/enums";
import { toaster } from "../../../utils/toast";
import { useDispatch } from "react-redux";
import { setModal } from "../../../store/features/modalSlice";
import {
  AUTH_MODAL_STATE,
  SETTINGS_ACCOUNT_EMAIL_MODAL,
} from "../../../constants/strings";
import { StatusCodes } from "http-status-codes";

interface QuestionFooterProps {
  id: string;
  flip: boolean;
  setFlip: Dispatch<SetStateAction<boolean>>;
  liked: boolean;
  likes?: number;
  comments?: number;
  questionType: QuestionType;
  votes: number;
  answered?: boolean;
  selectedChoice: string;
  selectedRating: number;
}

const QuestionFooter = ({
  id,
  flip,
  setFlip,
  liked,
  likes = 0,
  comments = 0,
  questionType,
  votes = 0,
  answered,
  selectedChoice,
  selectedRating,
}: QuestionFooterProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [submitted, setSubmitted] = useState(false);
  const [_liked, setLiked] = useState(liked);
  const [_likes, setLikes] = useState(likes);

  const currentPath = location.pathname;
  const onBackPage = currentPath.includes(`/question`);

  const setModalState = (modalState: string) => {
    dispatch(setModal({ isModalOpen: true, modalState }));
  };

  const toggleFlip = () => {
    if (onBackPage) {
      questionType !== QuestionType.OPEN && setFlip(!flip);
    } else {
      navigate(`/question/${id}`, {
        state: { stats: questionType === QuestionType.OPEN ? false : true },
      });
    }
  };

  const like = async () => {
    await likeQuestion(id)
      .then((res) => {
        setLiked(res.data.liked);
        if (!liked && res.data.liked) {
          setLikes(likes + 1);
        } else if (liked && !res.data.liked) {
          setLikes(likes - 1);
        } else {
          setLikes(likes);
        }
      })
      .catch((error) => {
        toaster({ text: error.response.data.message });
      });
  };

  const comment = () => {
    navigate(`/question/${id}`);
  };

  const results = () => {
    navigate(`/question/${id}`, {
      state: { stats: true },
    });
  };

  const handleVoting = () => {
    let errorMessage = "";
    let votePayload: any = {};

    if (questionType === QuestionType.RATING) {
      if (!selectedRating || selectedRating < 1) {
        errorMessage = "Please select a rating for a rating question";
      } else {
        votePayload = {
          questionId: id,
          questionType,
          rating: selectedRating,
        };
      }
    } else if (questionType === QuestionType.CHOICE) {
      if (!selectedChoice) {
        errorMessage = "Please select an option for a choice question";
      } else {
        votePayload = {
          questionId: id,
          questionType,
          choiceId: selectedChoice,
        };
      }
    } else {
      errorMessage = "Invalid question type";
    }

    if (errorMessage) {
      toaster({ text: errorMessage });
    } else {
      voteOnQuestion(votePayload)
        .then(() => setSubmitted(true))
        .catch((error: any) => {
          const shouldShowVerifyButton = error.response.data.verify === true;
          toaster({
            text: error.response.data.message,
          });
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            setModalState(AUTH_MODAL_STATE);
          } else if (shouldShowVerifyButton) {
            setModalState(SETTINGS_ACCOUNT_EMAIL_MODAL);
          }
        });
    }
  };

  const isOpenQuestion = questionType === QuestionType.OPEN;
  const hasComments = comments > 0;
  const isAnsweredOrSubmitted = answered || submitted;

  const renderButton = () => {
    if (isOpenQuestion) {
      return hasComments ? (
        <button className="button" onClick={comment}>
          See Comments
        </button>
      ) : (
        <button className="button" onClick={comment}>
          Start Discussion
        </button>
      );
    } else {
      return isAnsweredOrSubmitted ? (
        <button className="button flex items-center" onClick={results}>
          <span className="mr-2">Results</span>
          <FaChartSimple size={14} />
        </button>
      ) : (
        <button className="button flex items-center" onClick={handleVoting}>
          <span className="mr-2">Vote</span>
          <FaCheck size={14} />
        </button>
      );
    }
  };

  return (
    <div className="flex justify-between mt-0">
      <div className="flex flex-row gap-5">
        <QuestionFooterButton
          Icon={FaCheck}
          count={votes}
          color={answered ? "text-blue-500" : ""}
          disabled
        />
        <QuestionFooterButton
          Icon={FaHeart}
          action={like}
          count={_likes}
          color={_liked ? "text-red-500" : ""}
        />
        <QuestionFooterButton Icon={MdChat} action={comment} count={comments} />
        {/* <QuestionFooterButton Icon={RiShare2Line} action={share} /> */}
      </div>
      <div>
        {!onBackPage ? (
          <>{renderButton()}</>
        ) : (
          <>
            {!isOpenQuestion && (
              <>
                {flip ? (
                  <button
                    className="button flex items-center"
                    onClick={toggleFlip}
                  >
                    <span className="mr-2">Back to Question</span>
                    <MdOutlineQuestionMark size={14} />
                  </button>
                ) : (
                  <button
                    className="button flex items-center"
                    onClick={toggleFlip}
                  >
                    <span className="mr-2">Results</span>
                    <FaChartSimple size={14} />
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionFooter;
