import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";
import { QuestionData } from "../../types";

export interface ModalState {
  feed: string;
  addedQuestions?: Array<QuestionData>;
  deletedQuestions?: Array<string>;
  refreshFeed?: boolean;
}

const initialState: ModalState = {
  feed: "popular",
  addedQuestions: [],
  deletedQuestions: [],
  refreshFeed: false,
};

export const feedSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setFeed: (_, action: PayloadAction<ModalState>) => {
      return action.payload;
    },
    addToFeed: (state, action: PayloadAction<QuestionData>) => {
      const addedQuestions = state.addedQuestions ?? [];
      return {
        ...state,
        addedQuestions: [...addedQuestions, action.payload],
      };
    },
    addToDeleted: (state, action: PayloadAction<string>) => {
      const deletedQuestions = state.deletedQuestions ?? [];
      return {
        ...state,
        deletedQuestions: [...deletedQuestions, action.payload],
      };
    },
    updateFeed: (state) => {
      return { ...state, refreshFeed: !state.refreshFeed };
    },
  },
});

export const { setFeed, addToFeed, addToDeleted, updateFeed } =
  feedSlice.actions;

export const selectFeed = (state: RootState) => state.feed;

export default feedSlice.reducer;
