import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import Modal from "../../pages/Settings/common/Modal";
import { selectModal } from "../../../store/features/modalSlice";

interface QuestionImagePreviewModalProps {
  modalRef: any;
}

const QuestionImagePreviewModal = ({
  modalRef,
}: QuestionImagePreviewModalProps) => {
  const [index, setIndex] = useState(0);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const { imagePreviews } = useSelector(selectModal);

  const incrementChoices = () => {
    if (imagePreviews)
      if (index < imagePreviews?.length - 1) {
        setIndex(index + 1);
      }
  };

  const decrementChoices = () => {
    if (index >= 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div className={`flex justify-center items-center w-full`} ref={modalRef}>
      <Modal title="Image Previews">
        <div>
          {imagePreviews && (
            <img
              src={imagePreviews[index]}
              alt="preview"
              ref={imgRef}
              width={600}
            />
          )}
        </div>
        {imagePreviews && imagePreviews.length > 1 && (
          <div className="center justify-around p-3">
            <button
              className={index <= 0 ? "button-disabled" : "button"}
              onClick={decrementChoices}
              disabled={index <= 0}
            >
              Prev
            </button>
            <button
              className={
                index >= imagePreviews.length - 1 ? "button-disabled" : "button"
              }
              onClick={incrementChoices}
              disabled={index >= imagePreviews.length - 1}
            >
              Next
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QuestionImagePreviewModal;
