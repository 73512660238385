import { useEffect } from "react";

const useHandleOutslideClick = (
  condition: boolean,
  handleClickOutside: (event: MouseEvent) => void
) => {
  useEffect(() => {
    if (condition) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);
};

export default useHandleOutslideClick;
