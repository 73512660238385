import { ChoiceAnswers } from "../../../../../types/answers";
import StackedBarChart from "../StackedBarChart";

interface OverallDataProps {
  answers: ChoiceAnswers;
  choiceKeyMap: any;
  colorMap: any;
}

const OverallData = ({ answers, choiceKeyMap, colorMap }: OverallDataProps) => {
  if (answers?.overall) {
    const overallData = Object.entries(answers.overall).map(([key, value]) => {
      return {
        key: key,
        name: choiceKeyMap[key],
        count: value,
        color: colorMap[key],
      };
    });

    return (
      <div className="center flex flex-col">
        <div className="mb-8 font-semibold">Overall Data</div>
        <StackedBarChart data={overallData} demographic={"all"} />
      </div>
    );
  } else {
    return <div className="center">No Data to Display at this time</div>;
  }
};

export default OverallData;
