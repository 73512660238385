import { useState, useMemo } from "react";
import { isEmpty } from "lodash";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ChoiceAnswers } from "../../../../../types/answers";
import StackedBarChart from "../StackedBarChart";

type DataType =
  | "gender"
  | "ethnicity"
  | "orientation"
  | "religion"
  | "politics";

export const nonScalarDemographics: { [key: string]: string } = {
  gender: "Gender",
  ethnicity: "Ethnicity",
  orientation: "Sexual Orientation",
  religion: "Religion / Faith",
  politics: "Political Leaning",
};

export const castToDataType = (value: string): DataType => {
  if (Object.keys(nonScalarDemographics).includes(value)) {
    return value as DataType;
  }
  throw new Error(`Invalid value for DataType: ${value}`);
};

export const colorArray = [
  "#008ffb",
  "#00e396",
  "#ff4560",
  "#ffb01a",
  "#775dd0",
  "#81d9eb",
];

interface GetDataProps {
  answers: ChoiceAnswers;
  type: DataType;
  title: string;
  chartHeight: number;
  choiceKeyMap: any;
  colorMap: any;
}

export const GetData = ({
  answers,
  type,
  title,
  chartHeight,
  choiceKeyMap,
  colorMap,
}: GetDataProps) => {
  const [hoveredKey, setHoveredKey] = useState<string | null>(null);

  const handleMouseEnter = (key: string) => {
    setHoveredKey(key);
  };

  const handleMouseLeave = () => {
    setHoveredKey(null);
  };

  const data = answers?.[type];

  const dataMap = useMemo(() => {
    if (isEmpty(data)) {
      return null;
    }

    return Object.entries(data).map(([eKey, eValue]) => {
      const dataPoint = Object.entries(eValue).map(([dKey, dValue]) => {
        return {
          key: dKey,
          name: choiceKeyMap[dKey],
          count: dValue,
          color: colorMap[dKey],
        };
      });

      return (
        <div className="center flex flex-row mb-4" key={eKey}>
          <div data-tooltip-id={eKey} className="w-28 mr-4 text-sm truncate">
            {eKey}
          </div>
          <ReactTooltip id={eKey} place="right" content={`${eKey}`} />
          <StackedBarChart
            data={dataPoint}
            height={chartHeight}
            demographic={eKey}
            hoverKey={hoveredKey}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
      );
    });
  }, [hoveredKey, chartHeight, choiceKeyMap, colorMap, data]);

  if (isEmpty(data)) {
    return <div className="center">No Data to Display at this time</div>;
  }

  return (
    <div>
      <div className="mb-8 font-semibold">{title}</div>
      {dataMap}
    </div>
  );
};
