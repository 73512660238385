import { useSelector } from "react-redux";
import { FaCaretRight } from "react-icons/fa6";
import { format, parseISO } from "date-fns";

import { selectDemographics } from "../../../../store/features/demographicsInfoSlice";
import { HeightUnits } from "../../../../types/enums";
import { cmToFeetInches } from "../../../../utils/math";
import { selectUser } from "../../../../store/features/authSlice";
import { selectPrivacy } from "../../../../store/features/privacySlice";

interface SettingBarProps {
  text: string;
  modalState?: string;
  setModalState: (modalState: string) => void;
}

const SettingsBar = ({ text, modalState, setModalState }: SettingBarProps) => {
  const {
    dob,
    height,
    gender,
    ethnicity,
    orientation,
    religion,
    politics,
    location,
  } = useSelector(selectDemographics);
  const { email, emailVerified, username } = useSelector(selectUser);
  const { questions, comments } = useSelector(selectPrivacy);

  const prevDob = () => {
    if (dob) {
      const date = parseISO(String(dob.split("T")[0]));
      const formattedDate = format(date, "EEEE, MMMM do, yyyy");
      return formattedDate;
    }

    return "";
  };

  const previousHeight = () => {
    if (height) {
      if (height.units === HeightUnits.INCHES) {
        const { feet, inches } = cmToFeetInches(height.normalized);
        return `${feet}ft ${inches}in`;
      }
      return `${height?.normalized}cm`;
    }
    return "";
  };

  const previousLocation = () => {
    if (location) {
      return location.city;
    }
    return "";
  };

  const previouslyMap = {
    Email: `${email}${emailVerified === false ? " - unverified" : ""}`,
    Username: username,
    Age: prevDob(),
    Height: previousHeight(),
    Gender: gender,
    "Race / Ethnicicty": ethnicity,
    "Sexual Orientation": orientation,
    Religion: religion,
    "Political Affliation": politics,
    Location: previousLocation(),
    "Who can view my questions": questions,
    "Who can view my comments": comments,
  };

  return (
    <button
      className="flex flex-row w-full justify-between p-5 hover:bg-gray-100 dark:hover:bg-gray-900 text-sm"
      onClick={() => {
        modalState && setModalState(modalState);
      }}
    >
      <div>{text}</div>
      <div className="flex items-center">
        {/* @ts-ignore */}
        <div className="text-icon mr-3">{previouslyMap[text]}</div>
        <FaCaretRight />
      </div>
    </button>
  );
};

export default SettingsBar;
