import { Dispatch, MutableRefObject, SetStateAction } from "react";

interface SliderButtonsProps {
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
  pages: number;
  prevRef: MutableRefObject<null>;
  nextRef: MutableRefObject<null>;
}

const SliderButtons = ({
  active,
  setActive,
  pages,
  prevRef,
  nextRef,
}: SliderButtonsProps) => {
  const tabs = [
    "All",
    "Age",
    "Location",
    "Gender",
    "Race",
    "Orientation",
    "Faith",
    "Politics",
    "Height",
  ];

  return (
    <div className="flex justify-between my-4">
      <button
        className={`${
          active === 0 ? "button-disabled" : "button"
        } text-sm !px-3`}
        ref={prevRef}
        disabled={active === 0}
      >
        Prev
      </button>
      <div className="flex overflow-x-auto space-x-1">
        {tabs.map((tab, index) => (
          <div
            key={tab}
            className={`cursor-pointer px-2 py-1 rounded-full 
            ${
              active === index
                ? "font-semibold text-black dark:text-white"
                : "text-gray-500"
            }
            hover:bg-blue-400 hover:text-white transition-colors`}
            onClick={() => {
              setActive(index);
            }}
          >
            <span className="hidden tablet:block">{tab}</span>
            <span className="block tablet:hidden">•</span>
          </div>
        ))}
      </div>
      <button
        className={`${
          active === pages - 1 ? "button-disabled" : "button"
        } text-sm !px-3`}
        ref={nextRef}
        disabled={active === pages - 1}
      >
        Next
      </button>
    </div>
  );
};

export default SliderButtons;
