import { LngLatBoundsLike } from "mapbox-gl";
import tinycolor from "tinycolor2";

export const cmToFeetInches = (cm: number) => {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);
  return { feet, inches: inches === 12 ? 11 : inches };
};

export const feetInchesToCm = (feet: number, inches: number) => {
  return Math.round(feet * 30.48 + inches * 2.54);
};

type DataEntry = { [key: string]: number };
type Data = { [key: string]: DataEntry };

export interface IAgeRange {
  [key: string]: number;
}

export interface GroupedAgeResult {
  [range: string]: IAgeRange;
}

export const groupByAge = (data: Data, interval: number): GroupedAgeResult => {
  const min = Math.min(...Object.keys(data).map(Number));
  const max = Math.max(...Object.keys(data).map(Number));

  const result: any = {};
  const startAge = 20;

  for (let i = startAge; i >= min; i -= interval) {
    const key = `${Math.max(i - interval, 13)}-${i}`;
    result[key] = {};
  }
  for (let i = startAge; i <= max; i += interval) {
    const key = `${i + 1}-${i + interval}`;
    result[key] = {};
  }

  for (const key of Object.keys(data)) {
    const entries = data[key];
    const numVal = Number(key);

    for (const segement in result) {
      const [start, end] = segement.split("-").map(Number);

      if (numVal >= start && numVal <= end) {
        for (const [k, v] of Object.entries(entries)) {
          if (!result[segement][k]) {
            result[segement][k] = 0;
          }
          result[segement][k] += v;
        }
        break;
      }
    }
  }

  return result;
};

export interface IHeightRange {
  [key: string]: number;
}

export interface GroupedHeightResult {
  [range: string]: IHeightRange;
}

export const groupByHeight = (
  data: Data,
  interval: number
): GroupedHeightResult => {
  const min = Math.min(...Object.keys(data).map(Number));
  const max = Math.max(...Object.keys(data).map(Number));

  const result: any = {};
  const startHeight = 175;

  for (let i = startHeight; i > min; i -= interval) {
    const key = `${Math.max(i - interval, 13)}-${i}`;
    result[key] = {};
  }
  for (let i = startHeight; i < max; i += interval) {
    const key = `${i + 1}-${i + interval}`;
    result[key] = {};
  }

  for (const key of Object.keys(data)) {
    const entries = data[key];
    const numVal = Number(key);

    for (const segement in result) {
      const [start, end] = segement.split("-").map(Number);

      if (numVal > start && numVal <= end) {
        for (const [k, v] of Object.entries(entries)) {
          if (!result[segement][k]) {
            result[segement][k] = 0;
          }
          result[segement][k] += v;
        }
        break;
      }
    }
  }

  return result;
};

export const cmToFeetAndInches = (cmRange: string) => {
  const [minCm, maxCm] = cmRange.split("-").map(Number);

  function convertCmToFeetAndInches(cm: number) {
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);
    return `${feet}'${inches}`;
  }

  const minHeight = convertCmToFeetAndInches(minCm);
  const maxHeight = convertCmToFeetAndInches(maxCm);

  return `${minHeight}-${maxHeight}`;
};

export interface IGeoJSON {
  type: string;
  coordinates: Array<any>;
}

export const getBoundingBox = (geojson: IGeoJSON): LngLatBoundsLike => {
  if (geojson.type === "Polygon") {
    let minLng = Infinity;
    let minLat = Infinity;
    let maxLng = -Infinity;
    let maxLat = -Infinity;

    geojson.coordinates.forEach((polygon) => {
      polygon.forEach((coord: Array<any>) => {
        const [lng, lat] = coord;

        minLng = Math.min(minLng, lng);
        minLat = Math.min(minLat, lat);
        maxLng = Math.max(maxLng, lng);
        maxLat = Math.max(maxLat, lat);
      });
    });

    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ];
  } else if (geojson.type === "MultiPolygon") {
    let minLng = Infinity;
    let minLat = Infinity;
    let maxLng = -Infinity;
    let maxLat = -Infinity;

    const largest = findLargestRegion(geojson.coordinates);

    largest.forEach((polygon, j) => {
      // @ts-ignore
      polygon.forEach((coord: [number, number]) => {
        const [lng, lat] = coord;
        minLng = Math.min(minLng, lng);
        minLat = Math.min(minLat, lat);
        maxLng = Math.max(maxLng, lng);
        maxLat = Math.max(maxLat, lat);
      });
    });

    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ];
  }

  return [
    [0, 1],
    [0, 5],
  ];
};

function calculateArea(polygon: Array<any>) {
  let area = 0;
  const n = polygon.length;

  for (let i = 0; i < n; i++) {
    const j = (i + 1) % n; // Wrap around to the first point
    area += polygon[i][0] * polygon[j][1];
    area -= polygon[j][0] * polygon[i][1];
  }

  return Math.abs(area) / 2;
}

function findLargestRegion(regions: Array<any>): Array<[number, number]> {
  let largestArea = 0;
  let largestRegion = null;

  regions.forEach((region) => {
    const area = calculateArea(region[0]);
    if (area > largestArea) {
      largestArea = area;
      largestRegion = region;
    }
  });

  return (
    largestRegion || [
      [0, 0],
      [5, 5],
    ]
  );
}

type ColorWithWeight = { [color: string]: number };
export const blendMultipleColors = (
  colorsWithWeights: ColorWithWeight[]
): string => {
  let totalWeight = colorsWithWeights.reduce((sum, colorWithWeight) => {
    const weight = Object.values(colorWithWeight)[0];
    return sum + weight;
  }, 0);

  let blendedRgb = [0, 0, 0];

  for (const colorWithWeight of colorsWithWeights) {
    const color = Object.keys(colorWithWeight)[0];
    const weight = Object.values(colorWithWeight)[0];

    const rgb = [
      parseInt(color.slice(1, 3), 16),
      parseInt(color.slice(3, 5), 16),
      parseInt(color.slice(5, 7), 16),
    ];

    blendedRgb = blendedRgb.map(
      (component, i) => component + (rgb[i] * weight) / totalWeight
    );
  }

  const blendedColor = `#${blendedRgb
    .map((component) => Math.round(component).toString(16).padStart(2, "0"))
    .join("")}`;

  return blendedColor;
};

export function interpolateColor(
  startColor: string,
  endColor: string,
  value: number
): string {
  const start = new tinycolor(startColor);
  const end = new tinycolor(endColor);

  const startRgb = start.toRgb();
  const endRgb = end.toRgb();

  const interpolate = (start: number, end: number, factor: number): number => {
    return start + (end - start) * factor;
  };

  const r = Math.round(interpolate(startRgb.r, endRgb.r, value));
  const g = Math.round(interpolate(startRgb.g, endRgb.g, value));
  const b = Math.round(interpolate(startRgb.b, endRgb.b, value));

  return new tinycolor({ r, g, b }).toHexString();
}
