import { Navigate } from "react-router-dom";

import Settings from "../pages/Settings";
import * as strings from "../../constants/strings";
import { useEffect, useState } from "react";
import { authenticate } from "../../api/authApi";
import ProfilePage from "../pages/ProfilePage";

interface AuthRouteProps {
  page: string;
}

const AuthRoute = ({ page }: AuthRouteProps) => {
  let [isAuthenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    authenticate()
      .then(() => {
        setAuthenticated(true);
      })
      .catch(() => {
        setAuthenticated(false);
      });
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (page === strings.PAGES_SETTINGS) return <Settings />;
  if (page === strings.PAGES_PROFILE) return <ProfilePage />;

  return null;
};

export default AuthRoute;
