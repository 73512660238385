import { Dispatch, SetStateAction } from "react";

import QuestionTop from "./QuestionTop";
import { Author } from "../../../types";

interface NewOpenQuestionProps {
  author: Author;
  title: string;
  description: string;
  filePreviews: Array<string>;
  setFilePreviews: Dispatch<SetStateAction<Array<string>>>;
  setFieldValue: any;
}

const NewOpenQuestion = ({
  author,
  title,
  description,
  filePreviews,
  setFilePreviews,
  setFieldValue,
}: NewOpenQuestionProps) => {
  return (
    <QuestionTop
      author={author}
      title={title}
      description={description}
      filePreviews={filePreviews}
      setFilePreviews={setFilePreviews}
      setFieldValue={setFieldValue}
    />
  );
};

export default NewOpenQuestion;
