export const collectedInfo: Array<{
  category: string;
  examples: string;
  collected: boolean;
}> = [
  {
    category: "A. Identifiers",
    examples:
      "Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name",
    collected: false,
  },
  {
    category:
      "B. Personal information as defined in the California Customer Records statute",
    examples:
      "Name, contact information, education, employment, employment history, and financial information",
    collected: false,
  },
  {
    category:
      "C. Protected classification characteristics under state or federal law",
    examples:
      "Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data",
    collected: true,
  },
  {
    category: "D. Commercial information",
    examples:
      "Transaction information, purchase history, financial details, and payment information",
    collected: false,
  },
  {
    category: "E. Biometric information",
    examples: "Fingerprints and voiceprints",
    collected: false,
  },
  {
    category: "F. Internet or other similar network activity",
    examples:
      "Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements",
    collected: false,
  },
  {
    category: "G. Geolocation data",
    examples: "Device location",
    collected: true,
  },
  {
    category: "H. Audio, electronic, sensory, or similar information",
    examples:
      "Images and audio, video or call recordings created in connection with our business activites",
    collected: true,
  },
  {
    category: "I. Professional or employment-related information",
    examples:
      "Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us",
    collected: false,
  },
  {
    category: "J. Education information",
    examples: "Student records and directory information",
    collected: false,
  },
  {
    category: "K. Inferences drawn from collected personal information",
    examples:
      "Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics",
    collected: false,
  },
  {
    category: "L. Sensitive personal information",
    examples:
      "Religious or philisophical beliefs, sex life or sexual orientation, racial or ethnic origin, precise geolocation and debit or credit card numbers",
    collected: true,
  },
];
