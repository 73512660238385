import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa6";

import {
  unshareDemographics,
  updateDemographics,
} from "../../../../../api/demographicsApi";
import { selectUser } from "../../../../../store/features/authSlice";
import {
  selectDemographics,
  setAllDemographics,
} from "../../../../../store/features/demographicsInfoSlice";
import { closeModal } from "../../../../../store/features/modalSlice";
import { toaster } from "../../../../../utils/toast";
import "./modal.css";

interface ModalMenuProps {
  type: string;
  valuesEnum: Record<string, string>;
  selection: string;
  setSelection: Dispatch<SetStateAction<string>>;
}

const ModalMenu = ({
  type,
  valuesEnum,
  selection,
  setSelection,
}: ModalMenuProps) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);
  const { gender, ethnicity, orientation, religion, politics } =
    useSelector(selectDemographics);

  const typeMapping: { [key: string]: string } = {
    gender: "gender",
    ethnicity: "ethnicity",
    orientation: "orientation",
    religion: "religion",
    politics: "politics",
  };

  const setDemographic = () => {
    const newSelection = typeMapping[type]
      ? { [typeMapping[type]]: selection }
      : {};

    updateDemographics({ userId, ...newSelection })
      .then(() => {
        dispatch(setAllDemographics(newSelection));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster({ text: error.response.data.message });
      });
  };

  const unshare = () => {
    unshareDemographics({ userId, field: type })
      .then(() => {
        dispatch(setAllDemographics({ [typeMapping[type]]: "" }));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster({ text: error.response.data.message });
      });
  };

  const demographicsMap: { [key: string]: string | undefined } = {
    gender: gender,
    ethnicity: ethnicity,
    orientation: orientation,
    religion: religion,
    politics: politics,
  };

  const previousValue = demographicsMap[type];
  const isDisabled = !selection || previousValue === selection;

  return (
    <div className="w-80 flex flex-col text-normal">
      <div className="max-h-[70vh] overflow-scroll overflow-x-hidden">
        {Object.values(valuesEnum).map((value) => {
          return (
            <button
              key={value}
              className="flex justify-between w-full items-center hover:bg-gray-100 dark:hover:bg-gray-900 p-2"
              onClick={() => setSelection(value)}
            >
              <div>{value}</div>
              <div>
                {((previousValue === value && !selection) ||
                  selection === value) && <FaCheck />}
              </div>
            </button>
          );
        })}
      </div>

      <button
        className={`${isDisabled ? "button-disabled" : "button"}  w-full mt-5`}
        onClick={setDemographic}
        disabled={isDisabled}
      >
        Save
      </button>

      {previousValue && (
        <button className="button-cancel w-full mt-5" onClick={unshare}>
          Unshare
        </button>
      )}
    </div>
  );
};

export default ModalMenu;
