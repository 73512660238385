import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import * as yup from "yup";

import Modal from "../../common/Modal";
import { updatePassword } from "../../../../../api/profileApi";
import { closeModal } from "../../../../../store/features/modalSlice";
import { toaster } from "../../../../../utils/toast";
import FormikInputField from "../../../../../utils/FormikInputField";

const PasswordModal = () => {
  const dispatch = useDispatch();

  const schema = yup.object({
    oldPass: yup.string().required("Please enter your current password"),
    newPass: yup
      .string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Please enter a new password"),
    confirmPass: yup
      .string()
      .required("Please confirm your new password")
      .oneOf([yup.ref("newPass")], "Passwords must match!"),
  });

  const resetModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal title="Password">
      <div className="flex flex-col">
        <Formik
          validationSchema={schema}
          initialValues={{
            oldPass: "",
            newPass: "",
            confirmPass: "",
          }}
          onSubmit={async (values) => {
            await updatePassword({
              oldPass: values.oldPass,
              newPass: values.newPass,
              confirmPass: values.confirmPass,
            })
              .then(() => {
                resetModal();
                toaster({ text: "Password succesfully updated" });
              })
              .catch((error) => {
                toaster({ text: error.response.data.message });
              });
          }}
        >
          {({ errors }) => {
            return (
              <Form>
                <FormikInputField
                  name="oldPass"
                  placeholder="Current Password"
                  error={errors.oldPass}
                  type="password"
                />
                <FormikInputField
                  name="newPass"
                  placeholder="New Password"
                  error={errors.newPass}
                  type="password"
                />
                <FormikInputField
                  name="confirmPass"
                  placeholder="Confirm Password"
                  error={errors.confirmPass}
                  type="password"
                />
                <button className="button mt-6" type="submit">
                  Change
                </button>
              </Form>
            );
          }}
        </Formik>
        <p className="mt-4 text-xs text-gray-700">
          *If you are using signle sign-on, you will not be able to create /
          change your password.
        </p>
      </div>
    </Modal>
  );
};

export default PasswordModal;
