import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import FormikInputField from "../../utils/FormikInputField";
import { newPassword } from "../../api/authApi";
import { toaster } from "../../utils/toast";
import Cookies from "js-cookie";
import { COOKIE_TOKEN } from "../../constants/strings";
import { UserState, setUser } from "../../store/features/authSlice";

const ChangePassword = () => {
  const { token } = useParams<{ token: string }>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setLoginState = (user: UserState) => {
    dispatch(setUser(user));
  };

  const schema = yup.object({
    newPass: yup
      .string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Please enter a new password"),
    confirmPass: yup
      .string()
      .required("Please confirm your new password")
      .oneOf([yup.ref("newPass")], "Passwords must match!"),
  });

  return (
    <div className="center h-[80vh] items-center text-center">
      <div className="card">
        <div className="center text-2xl font-semibold">New Password</div>
        <Formik
          validationSchema={schema}
          initialValues={{
            token: "",
            newPass: "",
            confirmPass: "",
          }}
          onSubmit={async (values) => {
            await newPassword({
              token: String(token),
              newPass: values.newPass,
              confirmPass: values.confirmPass,
            })
              .then((response) => {
                const data = response.data.data;
                Cookies.set(COOKIE_TOKEN, data.token);
                setLoginState({
                  userId: data.id,
                  email: data.email,
                  username: data.username,
                  picture: data.picture,
                  premium: data.premium,
                  emailVerified: data.emailVerified,
                });
                navigate("/");
              })
              .catch((error) => {
                console.error(error.response.data.message);
                toaster({ text: error.response.data.message });
              });
          }}
        >
          {({ errors }) => {
            return (
              <Form>
                <FormikInputField
                  name="newPass"
                  placeholder="New Password"
                  error={errors.newPass}
                  type="password"
                />
                <FormikInputField
                  name="confirmPass"
                  placeholder="Confirm Password"
                  error={errors.confirmPass}
                  type="password"
                />
                <button className="button mt-6" type="submit">
                  Change
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
