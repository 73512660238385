import { useRef, useState } from "react";
import TOS_EU from "./TOS_EU";
import TOS_USA from "./TOS_US";
import LeftPanel from "../../feed/LeftPanel";

const TOS = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState("eu");

  const options = [
    {
      type: "usa",
      text: "Live outside the European Union, EFTA States, or the United Kingdom, including if you live in the United States",
    },
    {
      type: "eu",
      text: "Live in the European Union, EFTA States, or the United Kingdom",
    },
  ];

  return (
    <div className="flex">
      <LeftPanel />
      <div className="flex flex-col">
        <div className="absolute flex flex-row items-center bg-white">
          {options.map((setting, index) => {
            return (
              <button
                key={setting.type}
                className={`text-sm settings-button w-1/2 ${
                  page !== setting.type && "text-[#576F76]"
                }`}
                onClick={() => setPage(setting.type)}
              >
                {setting.text}
              </button>
            );
          })}
        </div>
        <div
          ref={scrollRef}
          id="test"
          className="mt-8 h-screen overflow-scroll scrollbar-hidden"
        >
          {page === "usa" && <TOS_USA scrollRef={scrollRef} />}
          {page === "eu" && <TOS_EU scrollRef={scrollRef} />}
        </div>
      </div>
    </div>
  );
};

export default TOS;
