import { CgProfile } from "react-icons/cg";

interface AvatarProps {
  picture?: string;
}

const Avatar = ({ picture }: AvatarProps) => {
  return <CgProfile size={24} />;
};

export default Avatar;
