import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { IoCalendar } from "react-icons/io5";
import { FaPencilAlt } from "react-icons/fa";
import { MdBlock } from "react-icons/md";

import { myProfileMenu, profileMenu } from "../../../constants/menus";
import * as strings from "../../../constants/strings";
import LeftPanel from "../../feed/LeftPanel";
import Questions from "./Questions";
import Comments from "./Comments";
import noAvatar from "../../../assets/noAvatar.png";
import noBanner from "../../../assets/noBanner.png";
import { requestToFolllow } from "../../../api/followApi";
import { FollowState } from "../../../types/enums";
import FollowButton from "./FollowButton";
import { selectUser } from "../../../store/features/authSlice";
import Followers from "./Followers";
import Following from "./Following";
import SidePanel from "../../feed/RightPanel";
import { getUser } from "../../../api/profileApi";
import { setBlockUserId, setModal } from "../../../store/features/modalSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const { username } = useParams<{ username: string }>();
  const { userId, username: myUsername } = useSelector(selectUser);

  const navigate = useNavigate();

  const [page, setPage] = useState(strings.PROFILE_QUESTIONS);
  const [followState, setFollowState] = useState(FollowState.NONE);
  const [myProfile, setMyProfile] = useState(false);
  const [followHovered, setFollowHovered] = useState(false);

  const setModalState = (state: string) => {
    dispatch(setModal({ isModalOpen: true, modalState: state }));
  };

  const toProfileEdit = () => {
    navigate("/settings", { state: { page: strings.SETTINGS_ACCOUNT } });
  };

  const followRequest = () => {
    requestToFolllow(data._id)
      .then(() => setFollowState(FollowState.REQUESTED))
      .catch((error) => console.error(error));
  };

  const block = () => {
    dispatch(setBlockUserId(data._id));
    setModalState(strings.BLOCK_USERS_MODAL);
  };

  const { data } = useQuery({
    queryKey: ["getProfile", username],
    queryFn: () => {
      if (username)
        return getUser(username)
          .then((response) => {
            const data = response.data;
            setFollowState(data.followState);
            return data;
          })
          .catch((error) => {
            console.error(error);
            return { user: null };
          });
      return { username: "", picture: "", header: "", bio: "" };
    },
  });

  useEffect(() => {
    if (userId === data?._id && username === myUsername) setMyProfile(true);
    else {
      setMyProfile(false);
      setPage(strings.PROFILE_QUESTIONS);
    }
  }, [userId, data?._id, username, myUsername]);

  const isoDateString = data?.createdAt;
  const date = parseISO(isoDateString || "1994-08-02T08:58:30");
  const formattedDate = format(date, "MMM d yyyy");

  return (
    <div className="flex flex-row">
      <LeftPanel />
      {!data || data?.user === null ? (
        <div className="center !items-start p-12 w-[800px] text-lg font-semibold">
          Cannot find user
        </div>
      ) : (
        <div className="h-screen py-2 px-4 overflow-scroll scrollbar-hidden w-full">
          <div className="card !mt-0">
            <div className="relative">
              <img
                src={data?.header ? data.header : noBanner}
                alt="banner"
                height="auto"
                width="100%"
                className="rounded-lg"
              />
              <img
                className="absolute left-[2%] top-[16.6%] w-1/6 h-4/6 rounded-full border-2 border-black"
                src={data?.avatar ? data.avatar : noAvatar}
                alt="avatar"
              />
            </div>

            <div className="flex justify-between items-center mt-3 ml-2">
              <div className="text-xl font-bold">{data?.username}</div>
              {myProfile ? (
                <button
                  className="flex items-center button"
                  onClick={toProfileEdit}
                >
                  <FaPencilAlt size={14} className="mr-3" /> Edit
                </button>
              ) : (
                <div className="flex gap-2">
                  <button
                    className="flex items-center button-cancel"
                    onClick={block}
                  >
                    <MdBlock size={22} />
                    <span className="ml-2">Block</span>
                  </button>
                  <button
                    className={`flex items-center ${
                      followState === FollowState.FOLLOWING && followHovered
                        ? "button-cancel"
                        : "button"
                    }`}
                    onClick={followRequest}
                    onMouseEnter={() => setFollowHovered(true)}
                    onMouseLeave={() => setFollowHovered(false)}
                  >
                    <FollowButton
                      followState={followState}
                      followHovered={followHovered}
                    />
                  </button>
                </div>
              )}
            </div>

            <div className="mt-3 ml-2 text-sm">
              <p>
                {data?.bio &&
                  data?.bio.split("\n").map((line: string, index: number) => (
                    <Fragment key={index}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
              </p>
              <p className="mt-4 text-gray-600 flex items-center">
                <IoCalendar className="mr-1" />
                {data?.createdAt && formattedDate}
              </p>
            </div>
          </div>
          <div className="flex flex-row mt-6 overflow-x-scroll scrollbar-hidden">
            {profileMenu.map((setting) => {
              return (
                <button
                  key={setting}
                  className={`text-sm settings-button ${
                    page !== setting && "text-[#576F76]"
                  }`}
                  onClick={() => setPage(setting)}
                >
                  {setting}
                </button>
              );
            })}
            {myProfile &&
              myProfileMenu.map((setting) => {
                return (
                  <button
                    key={setting}
                    className={`text-sm settings-button ${
                      page !== setting && "text-[#576F76]"
                    }`}
                    onClick={() => setPage(setting)}
                  >
                    {setting}
                  </button>
                );
              })}
          </div>
          <div className="container min-w-[320px] overflow-y-scroll h-screen scrollbar-hidden sticky top-0">
            {page === strings.PROFILE_QUESTIONS && <Questions />}
            {page === strings.PROFILE_COMMENTS && (
              <Comments username={username as string} />
            )}
            {page === strings.PROFILE_FOLLOWING && <Following />}
            {page === strings.PROFILE_FOLLOWERS && <Followers />}
          </div>
        </div>
      )}
      <SidePanel />
    </div>
  );
};

export default ProfilePage;
