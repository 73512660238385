import { useState } from "react";
import { Formik, Form } from "formik";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import Modal from "../../common/Modal";
import { getMe, updateEmail } from "../../../../../api/profileApi";
import { setPartialUser } from "../../../../../store/features/authSlice";
import { closeModal } from "../../../../../store/features/modalSlice";
import { toaster } from "../../../../../utils/toast";
import FormikInputField from "../../../../../utils/FormikInputField";
import { resendVerification } from "../../../../../api/authApi";

const EmailModal = () => {
  const dispatch = useDispatch();

  const [changing, setChanging] = useState(false);
  const [sent, setSent] = useState(false);

  const { data } = useQuery({
    queryKey: ["getMe"],
    queryFn: async () => {
      return getMe()
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return null;
        });
    },
  });

  const resetModal = () => {
    dispatch(closeModal());
  };

  const sendVerify = async () => {
    await resendVerification()
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        toaster({ text: error.response.data.message });
      });
  };

  return (
    <Modal title="Email">
      <div className="center flex-col w-[400px]">
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div>{data?.email}</div>
            <div className="ml-6">
              <button
                className="font-semibold"
                onClick={() => setChanging(!changing)}
              >
                {changing ? "Cancel" : "Change"}
              </button>
            </div>
          </div>
          {changing && (
            <div className="center flex-col mt-4">
              <Formik
                initialValues={{
                  newEmail: "",
                  confirmEmail: "",
                }}
                onSubmit={async (values) => {
                  await updateEmail({
                    newEmail: values.newEmail,
                    confirmEmail: values.confirmEmail,
                  })
                    .then(() => {
                      dispatch(setPartialUser({ email: values.newEmail }));
                      resetModal();
                    })
                    .catch((error) => {
                      toaster({ text: error.response.data.message });
                    });
                }}
              >
                {({ errors }) => {
                  return (
                    <Form>
                      <FormikInputField
                        name="newEmail"
                        placeholder="New Email Address"
                        error={errors.newEmail}
                      />
                      <FormikInputField
                        name="confirmEmail"
                        placeholder="Confirm Email Address"
                      />
                      <button className="button mt-5" type="submit">
                        Submit
                      </button>
                      <p className="mt-4 text-xs text-gray-700">
                        *If you change your email, you will have to verify your
                        email again before answering questions.
                      </p>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
        {data?.email && !data?.emailVerified && (
          <>
            {sent ? (
              <div className="mt-6">Email Sent! Please check your inbox</div>
            ) : (
              <button className="button mt-6" onClick={sendVerify}>
                Resend Verifcation email
              </button>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default EmailModal;
