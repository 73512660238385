import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { FaCheck } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { MdChat } from "react-icons/md";
import { GiRoundStar } from "react-icons/gi";
import { BsUiRadiosGrid } from "react-icons/bs";
import { GoCommentDiscussion } from "react-icons/go";

import { getPopularQuestions } from "../../../api/questionApi";
import { QuestionData } from "../../../types";
import QuestionFooterButton from "../Question/QuestionFooterButtons";
import { QuestionType } from "../../../types/enums";
import Loading from "../../Loading";

const Popular = () => {
  const navigate = useNavigate();

  const goToQuestion = (id: string) => {
    navigate(`/question/${id}`);
  };

  const { isLoading, data } = useQuery({
    queryKey: ["getPopularQuestions"],
    queryFn: () =>
      getPopularQuestions()
        .then((response) => {
          return response.data.data;
        })
        .catch(),
  });

  if (isLoading) return <Loading />;

  if (data?.length > 0) {
    return (
      <>
        <div className="flex flex-col">
          <div className="font-extralight mb-2 text-center">Popular</div>
          {data.map((pq: QuestionData) => {
            return (
              <div
                onClick={() => goToQuestion(pq._id)}
                className="mb-4 hover:bg-onahu px-3 rounded-md cursor-pointer"
                key={pq._id}
              >
                <div className="text-sm font-semibold">{pq.title}</div>
                <div className="text-xs truncate">{pq.description}</div>
                <div className="flex justify-between">
                  <div className="flex gap-3">
                    <QuestionFooterButton
                      Icon={FaCheck}
                      count={pq.votes}
                      size={15}
                      disabled
                    />
                    <QuestionFooterButton
                      Icon={FaHeart}
                      count={pq.likes}
                      size={15}
                      disabled
                    />
                    <QuestionFooterButton
                      Icon={MdChat}
                      count={pq.comments}
                      size={15}
                      disabled
                    />
                  </div>
                  <div className="center">
                    {pq.questionType === QuestionType.RATING && (
                      <GiRoundStar color="#ffbc09" size={14} />
                    )}
                    {pq.questionType === QuestionType.CHOICE && (
                      <BsUiRadiosGrid color="#3b82f6" size={14} />
                    )}
                    {pq.questionType === QuestionType.OPEN && (
                      <GoCommentDiscussion color="#ff0000" size={14} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return null;
};

export default Popular;
