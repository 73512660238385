import { useState } from "react";

import Modal from "../../common/Modal";
import ModalMenu from "./ModalMenu";
import { Orientation } from "../../../../../types/enums";

interface OrientationModalProps {
  modalRef: any;
}

const OrientationModal = ({ modalRef }: OrientationModalProps) => {
  const [selection, setSelection] = useState("");
  return (
    <div ref={modalRef}>
      <Modal title="Sexual Orientation">
        <ModalMenu
          type="orientation"
          valuesEnum={Orientation}
          selection={selection}
          setSelection={setSelection}
        />
      </Modal>
    </div>
  );
};

export default OrientationModal;
