import { RefObject } from "react";

type Heading = {
  id: string;
  text: string;
  level: number;
};

interface TableOfContentsProps {
  headings: Array<Heading>;
  activeId: string;
  scrollRef: RefObject<HTMLDivElement>;
}

const TableOfContents = ({
  headings,
  activeId,
  scrollRef,
}: TableOfContentsProps) => {
  return (
    <nav>
      <ul>
        {headings.map((heading) => (
          <li
            key={heading.id}
            className={`text-xl pb-3 font-semibold ${
              activeId === heading.id ? "text-blue-500" : ""
            }`}
          >
            <a
              href={`#${heading.id}`}
              onClick={(e) => {
                // @ts-ignore
                // const element = scrollRef.current.querySelector(
                //   `#${heading.id}`
                // ) as HTMLElement | null;
                e.preventDefault();
                // if (scrollRef.current) {
                //   scrollRef?.current.scrollIntoView({
                //     behavior: "smooth",
                //   });
                // }
              }}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableOfContents;
