import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

export interface DemographicsInfoState {
  dob?: string | null;
  height?: {
    normalized: number;
    units: string;
  };
  gender?: string;
  ethnicity?: string;
  orientation?: string;
  religion?: string;
  politics?: string;
  location?: {
    city: string;
    district: string;
    province: string;
    country: string;
    latitude: number;
    longitude: number;
  };
}

const initialState: DemographicsInfoState = {
  dob: null,
  height: undefined,
  gender: "",
  ethnicity: "",
  orientation: "",
  religion: "",
  politics: "",
  location: undefined,
};

export const demographicsSlice = createSlice({
  name: "demographics",
  initialState,
  reducers: {
    setAllDemographics: (
      state,
      action: PayloadAction<Partial<DemographicsInfoState>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setAllDemographics } = demographicsSlice.actions;

export const selectDemographics = (state: RootState) => state.demographics;

export default demographicsSlice.reducer;
