import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { getPersonalizedFeed } from "../../../api/questionApi";
import { QuestionData } from "../../../types";
import Question from "../../feed/Question";
import {
  useFetchFeedOnFetchLoad,
  useInitializeFeed,
  useScrollFetch,
} from "../../../hooks/FeedHooks";
import { FeedTypes } from "../../../types/enums";

const Questions = () => {
  const { username } = useParams();
  const feedRef = useRef<HTMLDivElement>(null);

  const [nextPage, setNextPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [feed, setFeed] = useState<Array<QuestionData>>([]);
  const [, setLoading] = useState(true);

  const loadMoreQuestions = async () => {
    setLoading(true);
    await getPersonalizedFeed(nextPage, FeedTypes.PROFILE, username)
      .then((response) => {
        const data = response.data.data;
        setHasNext(data.hasNext);
        setNextPage((prevPage) => prevPage + 1);
        const questions = data.questions;
        setFeed((prevFeed) => [...prevFeed, ...questions]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useInitializeFeed(setFeed, loadMoreQuestions, [username]);
  const { load, setLoad } = useScrollFetch(feedRef);
  useFetchFeedOnFetchLoad(load, setLoad, loadMoreQuestions, hasNext, nextPage);

  return (
    <div ref={feedRef}>
      {feed.length > 0 ? (
        feed.map((question) => {
          return (
            <Question
              key={question._id}
              id={question._id}
              author={question.author}
              createdAt={question.createdAt}
              questionType={question.questionType}
              title={question.title}
              description={question.description}
              liked={question.liked}
              likes={question.likes}
              choices={question.choices}
              comments={question.comments}
              votes={question.votes}
            />
          );
        })
      ) : (
        <div className="center mt-4">No questions yet</div>
      )}
      <div className="h-[80px]" />
    </div>
  );
};

export default Questions;
