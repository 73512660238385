import { ModalState } from "./AuthModal";

interface AuthHeaderProps {
  state: ModalState;
}

const AuthHeader = ({ state }: AuthHeaderProps) => {
  return (
    <div>
      <div className="center text-2xl font-bold mb-6">PoliQ's</div>
      <div className="text-xl font-semibold mb-2">
        {state === ModalState.SIGN_IN
          ? "Welcome Back!👋"
          : "Adventure starts here!🚀"}
      </div>
      <div className="text-normal">
        {state === ModalState.SIGN_IN
          ? "Please log in to continue!"
          : "Create an account to start asking and answering questions!"}
      </div>
    </div>
  );
};

export default AuthHeader;
