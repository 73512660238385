import { FaQuestion } from "react-icons/fa";
import { SiAnswer } from "react-icons/si";
import { BiSolidUpvote } from "react-icons/bi";

import { SITE_NAME } from "../../../constants/strings";
import people from "../../../assets/about.jpg";
import { IconType } from "react-icons";
import LeftPanel from "../../feed/LeftPanel";

const About = () => {
  return (
    <div className="flex">
      <LeftPanel />
      <div className="bg-[#E4E8E9] h-screen overflow-scroll">
        <div>
          <img src={people} alt="People" />
          <h1 className="center text-[60px] font-semibold py-16 text-black">
            About Us
          </h1>
          <div className="center pb-20">
            <div className="text-center w-[600px] font-semibold text-[20px] leading-6 text-black">
              At {SITE_NAME}, we’re passionate about harnessing the power of
              data. Our mission is to empower everyone to conduct insightful
              research by connecting with people from all corners of the globe
              and gaining a deep understanding through comprehensive demographic
              analysis.
            </div>
          </div>
          <div className="bg-blue-500 text-[#e4e8e9] pb-20">
            <h1 className="center py-16 pb-8 text-[40px] font-semibold leading-6">
              How POLIQS Works
            </h1>
            <div className="center">
              <div className="font-semibold text-[20px] p-6 leading-6 w-[800px] text-center">
                All demographic information is voluntarily provided by users.
                While we strive for accuracy, please be aware that we cannot
                guarantee the absolute precision of demographic data or
                responses. We encourage you to keep this in mind as you engage
                with our platform.
              </div>
            </div>
            <div className="flex tablet:flex-row flex-col justify-evenly p-8 gap-5">
              <AboutCard
                Icon={FaQuestion}
                title="Ask"
                text="Pose questions and start polls to gather opinions and insights from the community and start
              discussions."
              />
              <AboutCard
                Icon={SiAnswer}
                title="Answer"
                text="Participate by responding to questions. Your answers help us
              gather diverse perspectives from the community."
              />
              <AboutCard
                Icon={BiSolidUpvote}
                title="Engage"
                text="Your votes and comments enrich conversations and contribute to a vibrant, dynamic exchange of ideas."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

interface AboutCardProps {
  Icon: IconType;
  title: string;
  text: string;
}

const AboutCard = ({ Icon, title, text }: AboutCardProps) => {
  return (
    <div className="card flex flex-col text-black dark:text-[#e4e8e9] max-w-full tablet:max-w-60">
      <div className="center text-xl font-semibold !justify-start items-center">
        <Icon className="ml-4" color="#3b82f6" />
        <span className="ml-4">{title}</span>
      </div>
      <div className="p-4">{text}</div>
    </div>
  );
};
