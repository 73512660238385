import { Dispatch, SetStateAction, useState } from "react";
import Select from "react-select";
import { startCase } from "lodash";

import {
  Ethnicity,
  Gender,
  Orientation,
  Politics,
  Religion,
} from "../../../../types/enums";

export type OptionType = {
  value: string;
  label: string;
};

type GroupType = {
  label: string;
  options: OptionType[];
};

interface DemoFilterProps {
  setFilters?: Dispatch<SetStateAction<Array<any>>>;
}

const DemoFilter = ({ setFilters }: DemoFilterProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<OptionType>>([]);

  const handleChange = (selected: any) => {
    setSelectedOptions(selected || []);
  };

  const handleFilter = () => {
    setFilters && setFilters(selectedOptions);
  };

  const genderFilter = Object.entries(Gender).map(([key, value]) => {
    return { filter: "gender", value: key, label: startCase(value) };
  });

  const ethnicityFilter = Object.entries(Ethnicity).map(([key, value]) => {
    return { filter: "ethnicity", value: key, label: startCase(value) };
  });

  const orientationFilter = Object.entries(Orientation).map(([key, value]) => {
    return { filter: "oreintation", value: key, label: startCase(value) };
  });

  const religionFilter = Object.entries(Religion).map(([key, value]) => {
    return { filter: "religion", value: key, label: startCase(value) };
  });

  const politicsFilter = Object.entries(Politics).map(([key, value]) => {
    return { filter: "politics", value: key, label: startCase(value) };
  });

  const options: Array<GroupType> = [
    { label: "Gender", options: genderFilter },
    { label: "Race", options: ethnicityFilter },
    { label: "Sexual Orientation", options: orientationFilter },
    { label: "Faith / Religion", options: religionFilter },
    { label: "Politics", options: politicsFilter },
  ];

  const disabledOptions = options.reduce((acc: any, group) => {
    const selectedInGroup = selectedOptions.find((option) =>
      group.options.some((opt) => opt.value === option.value)
    );

    if (selectedInGroup) {
      acc[group.label] = group.options
        .filter((opt) => opt.value !== selectedInGroup.value)
        .map((opt) => opt.value);
    }

    return acc;
  }, {});

  const mappedOptions: GroupType[] = options.map((group) => ({
    ...group,
    options: group.options.map((option) => ({
      ...option,
      isDisabled: disabledOptions[group.label]?.includes(option.value),
    })),
  }));

  return (
    <div className="flex p-6">
      <Select
        className="w-full z-10"
        placeholder="Filter demographics"
        options={mappedOptions}
        value={selectedOptions}
        onChange={handleChange}
        isMulti
      />
      <button className="button !ml-6" onClick={handleFilter}>
        Filter
      </button>
    </div>
  );
};

export default DemoFilter;
