import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";

import {
  closeModal,
  selectModal,
} from "../../../../../store/features/modalSlice";
import Loading from "../../../../Loading";
import { blockUser } from "../../../../../api/privacyApi";
import { toaster } from "../../../../../utils/toast";

interface BlockUsersModalModalProps {
  modalRef: any;
}

const BlockUsersModal = ({ modalRef }: BlockUsersModalModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { blockUserId } = useSelector(selectModal);

  const resetModal = () => {
    dispatch(closeModal());
  };

  const confirmBlock = () => {
    blockUserId &&
      blockUser(blockUserId)
        .then(() => {
          resetModal();
          toaster({ text: "User blocked" });
          navigate("/");
        })
        .catch((error) => console.error(error));
  };
  return (
    <div ref={modalRef} className="card w-[440px] min-w-[400px]">
      <div className="flex justify-between mb-3">
        <div className="text-lg font-semibold">Block User</div>

        <button onClick={resetModal}>
          <IoMdClose />
        </button>
      </div>
      <Suspense fallback={<Loading />}>
        <div className="text-normal mb-5">
          Are you sure you want to block this user? You will no longer see this
          users content.
        </div>
        <div className="flex justify-end">
          <button className="button" onClick={resetModal}>
            Cancel
          </button>
          <button className="button-cancel" onClick={confirmBlock}>
            Block
          </button>
        </div>
      </Suspense>
    </div>
  );
};

export default BlockUsersModal;
