import { Dispatch, SetStateAction } from "react";
import { Rating as ReactRating } from "react-simple-star-rating";
import { GiRoundStar } from "react-icons/gi";

import QuestionTop from "./QuestionTop";
import { Author } from "../../../types";

interface NewRatingsQuestionProps {
  author: Author;
  title: string;
  description: string;
  filePreviews: Array<string>;
  setFilePreviews: Dispatch<SetStateAction<Array<string>>>;
  setFieldValue: any;
}

const NewRatingsQuestion = ({
  author,
  title,
  description,
  filePreviews,
  setFilePreviews,
  setFieldValue,
}: NewRatingsQuestionProps) => {
  return (
    <QuestionTop
      author={author}
      title={title}
      description={description}
      filePreviews={filePreviews}
      setFilePreviews={setFilePreviews}
      setFieldValue={setFieldValue}
    >
      {filePreviews.length > 0 && (
        <>
          <img
            src={filePreviews[0]}
            alt={`Preview`}
            style={{
              maxWidth: "60%",
              maxHeight: "300px",
              objectFit: "cover",
              margin: "5px",
              borderRadius: 8,
            }}
          />
        </>
      )}
      <ReactRating
        size={30}
        emptyIcon={<GiRoundStar size={30} />}
        fillIcon={<GiRoundStar size={30} />}
        initialValue={0}
        iconsCount={10}
        emptyStyle={{ display: "flex" }}
        fillStyle={{ display: "-webkit-inline-box" }}
        style={{ marginTop: 18 }}
      />
    </QuestionTop>
  );
};

export default NewRatingsQuestion;
