import { useEffect, useState } from "react";

import Popular from "./Popular";

const SidePanel = () => {
  const [, setVisible] = useState(window.innerWidth >= 920);

  useEffect(() => {
    const handleResize = () => {
      setVisible(window.innerWidth >= 920);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="hidden panel:block min-w-[320px] max-w-[320px] overflow-y-auto scrollbar-hidden">
      <div className="card m-2 ml-0 bg-white !p-2">
        <Popular />
      </div>

      <div className="h-16" />
    </div>
  );
};

export default SidePanel;
