import axios from "axios";
import Cookies from "js-cookie";

import { COOKIE_TOKEN } from "../constants/strings";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const API_ROUTE = `${DOMAIN}/api/v1`;
const PROFILE_ROUTE = `${API_ROUTE}/profile`;
const QUESTION_ROUTE = `${API_ROUTE}/question`;

export const getProfileComments = async (filterUser: string) => {
  return await axios({
    method: "GET",
    url: `${QUESTION_ROUTE}/profileComments`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params: {
      username: filterUser,
    },
  });
};

export const getUser = async (username: string) => {
  return await axios({
    method: "GET",
    url: `${PROFILE_ROUTE}/`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params: {
      username,
    },
  });
};

export const getMe = async () => {
  return await axios({
    method: "GET",
    url: `${PROFILE_ROUTE}/me`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

interface UpdateProfileData {
  type: string;
  text?: string;
  image?: File;
}

export const updateProfile = async ({
  type,
  text,
  image,
}: UpdateProfileData) => {
  const formData = new FormData();

  formData.append("type", type);
  text && formData.append("text", text);
  image && formData.append("image", image);

  return await axios({
    method: "POST",
    url: `${PROFILE_ROUTE}/update`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const getPrivacy = async () => {
  return await axios({
    method: "GET",
    url: `${PROFILE_ROUTE}/privacy`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

interface PrivacyProps {
  userId: string;
  showQuestions?: string;
  showComments?: string;
}

export const setPrivacy = async ({
  userId,
  showQuestions,
  showComments,
}: PrivacyProps) => {
  return await axios({
    method: "POST",
    url: `${PROFILE_ROUTE}/privacy`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      userId,
      ...(showQuestions && { questions: showQuestions }),
      ...(showComments && { comments: showComments }),
    },
  });
};

interface UpdateEmailProps {
  newEmail: string;
  confirmEmail: string;
}
export const updateEmail = async ({
  newEmail,
  confirmEmail,
}: UpdateEmailProps) => {
  return await axios({
    method: "PATCH",
    url: `${PROFILE_ROUTE}/password`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      newEmail,
      confirmEmail,
    },
  });
};

export const updateUsername = async (username: string) => {
  return await axios({
    method: "PATCH",
    url: `${PROFILE_ROUTE}/username`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      username,
    },
  });
};

interface UpdatePasswordProps {
  oldPass: string;
  newPass: string;
  confirmPass: string;
}
export const updatePassword = async ({
  oldPass,
  newPass,
  confirmPass,
}: UpdatePasswordProps) => {
  return await axios({
    method: "PATCH",
    url: `${PROFILE_ROUTE}/password`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      oldPass,
      newPass,
      confirmPass,
    },
  });
};
