import { GiRoundStar } from "react-icons/gi";
import { Rating as ReactRating } from "react-simple-star-rating";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface OverallDataProps {
  answers: any;
}

const OverallData = ({ answers }: OverallDataProps) => {
  const overallCount = answers?.overall?.count || 0;
  const overallTotal = answers?.overall?.total;
  const average = (overallTotal || 0) / (overallCount || 1);

  if (overallCount === 0)
    return <div className="center my-6">No data to display at this time</div>;

  return (
    <div className="text-center my-8">
      All
      <div data-tooltip-id="overall">
        <ReactRating
          size={30}
          emptyIcon={<GiRoundStar size={30} />}
          fillIcon={<GiRoundStar size={30} color="lightblue" />}
          initialValue={average || 0}
          iconsCount={10}
          emptyStyle={{ display: "flex" }}
          fillStyle={{ display: "-webkit-inline-box" }}
          allowHover={false}
          allowTitleTag={false}
          allowFraction
          readonly
        />
      </div>
      <ReactTooltip
        id="overall"
        place="bottom"
        content={`${average.toFixed(2)} out of 10 | ${overallCount} votes`}
        style={{ zIndex: 10 }}
      />
    </div>
  );
};

export default OverallData;
