import axios from "axios";

const accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_KEY;

export const reverseGeocode = async (latitude: number, longitude: number) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;

  const response = await axios({
    method: "GET",
    url,
  })
    .then((data) => data)
    .catch((error) => {
      console.error(error);
    });

  return response;
};
