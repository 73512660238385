import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Formik, Form, Field, FormikProps } from "formik";
import Select from "react-select";
import * as yup from "yup";

import { createNewQustion } from "../../../api/questionApi";
import { selectUser } from "../../../store/features/authSlice";
import NewRatingsQuestion from "./NewRatingsQuestion";
import FileUpload from "../../../utils/FileUpload";
import Scheduler from "../../../utils/Scheduler";
import LineBreak from "../../../utils/LineBreak";
import NewOpenQuestion from "./OpenQuestion";
import FormikInputField from "../../../utils/FormikInputField";
import NewChoiceQuestion from "./NewChoiceQuestion";
import { QuestionType } from "../../../types/enums";
import { toaster } from "../../../utils/toast";
import { addToFeed } from "../../../store/features/feedSlice";
import { QuestionData } from "../../../types";
import { IoMdClose } from "react-icons/io";

interface NewQuestionProps {
  modalRef: any;
  resetModal: () => void;
}

const NewQuestion = ({ modalRef, resetModal }: NewQuestionProps) => {
  const dispatch = useDispatch();

  const { username, picture } = useSelector(selectUser);

  const [choices, setChoices] = useState(2);
  const [filePreviews, setFilePreviews] = useState<Array<string>>([]);
  // const [hyperlinks, setHyperlinks] = useState<Array<Link>>([]); TODO: Hyperlinks
  const [schedule, setSchedule] = useState(0); // TODO: Make this a date

  const schema = yup.object({
    title: yup.string().required("Please enter a title"),
    questionType: yup.string().required("Please select a question type"),
  });

  const validateDescription = (values: any) => {
    if (values.questionType === QuestionType.OPEN) {
      if (values.description.length < 1) {
        return "For open ended questions, please add a description";
      }
    }
  };

  const updateFeed = (data: QuestionData) => {
    dispatch(
      addToFeed({
        _id: data._id,
        author: data.author,
        questionType: data.questionType,
        title: data.title,
        description: data.description,
        createdAt: data.createdAt,
        ...(filePreviews[0] && { image: filePreviews[0] }),
        liked: data.liked,
        likes: data.likes,
        comments: data.comments,
        choices: data.choices,
        votes: data.votes,
        myAnswer: undefined,
        feedType: "question",
      })
    );
  };

  return (
    <div ref={modalRef} className="container max-w-5xl">
      <div className="card w-full">
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            title: "",
            description: "",
            questionType: "",
            private: false,
            groups: [],
            choices: [],
            files: undefined,
          }}
          onSubmit={async (values) => {
            createNewQustion({
              title: values.title,
              description: values.description,
              questionType: values.questionType,
              private: values.private,
              groups: values.groups,
              choices: values.choices,
              files: values.files,
            })
              .then((response) => {
                const data: QuestionData = response.data.data;
                updateFeed(data);
                resetModal();
              })
              .catch((error) => {
                console.error(error.response.data.message);
                toaster({
                  text: "Error submitting your question. Please refresh and try again.",
                });
              });
          }}
        >
          {({ values, setFieldValue, errors }: FormikProps<any>) => {
            const incrementChoices = () => {
              if (choices < 6) {
                setChoices(choices + 1);
              }
            };

            const decrementChoices = () => {
              if (choices > 2) {
                setChoices(choices - 1);
              }
            };

            const handleChoiceChange = (
              index: number,
              event: ChangeEvent<HTMLInputElement>
            ) => {
              const newChoices = values.choices.slice();
              newChoices[index] = {
                ...newChoices[index],
                text: event.target.value,
              };
              setFieldValue("choices", newChoices);
            };

            return (
              <Form>
                <div className="flex justify-between mb-3">
                  <div className="text-base font-semibold">New Question</div>
                  <button onClick={resetModal}>
                    <IoMdClose />
                  </button>
                </div>
                <FormikInputField name="title" error={errors.title as string} />
                <FormikInputField
                  name="description"
                  type="textarea"
                  error={errors.description as string}
                  validate={() => validateDescription(values)}
                />
                <div className="flex justify-between w-full">
                  <Field as="select" name="questionType" className="select">
                    {() => (
                      <Select
                        placeholder="Question Type"
                        className="text-sm w-1/3"
                        isClearable
                        options={Object.values(QuestionType).map((type) => ({
                          value: type,
                          label: type,
                        }))}
                        onChange={(e) =>
                          setFieldValue("questionType", e?.value)
                        }
                      />
                    )}
                  </Field>
                  <div className="flex justify-end items-center w-1/2">
                    <input
                      id="private"
                      type="checkbox"
                      checked={values.private}
                      onChange={(e) =>
                        setFieldValue("private", e.target.checked)
                      }
                    />
                    <label className="ml-2 mr-6" htmlFor="private">
                      Followers only
                    </label>
                  </div>
                </div>
                <div className="pl-1 mt-1 mb-4 text-red-600">
                  <>{errors.questionType}</>
                </div>
                {values.questionType === QuestionType.CHOICE && (
                  <div className="flex flex-row justify-between">
                    <button type="button" onClick={decrementChoices}>
                      <FaMinusCircle size={28} color="#3b82f6" />
                    </button>
                    <div className="w-4/5 grid grid-cols-2 gap-4 max-sm:grid-cols-1">
                      {Array.from({ length: choices }).map((_, index) => {
                        return (
                          <input
                            key={index}
                            type="text"
                            className="input"
                            value={values.choices[index]?.text}
                            onChange={(event) =>
                              handleChoiceChange(index, event)
                            }
                          />
                        );
                      })}
                    </div>
                    <button type="button" onClick={incrementChoices}>
                      <FaPlusCircle size={28} color="#3b82f6" />
                    </button>
                  </div>
                )}
                {values.questionType && (
                  <>
                    <LineBreak text="preview" />
                    {values.questionType === QuestionType.RATING && (
                      <NewRatingsQuestion
                        author={{
                          _id: "",
                          username,
                          avatar: String(picture),
                        }}
                        title={values.title}
                        description={values.description}
                        filePreviews={filePreviews}
                        setFilePreviews={setFilePreviews}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {values.questionType === QuestionType.OPEN && (
                      <NewOpenQuestion
                        author={{
                          _id: "",
                          username,
                          avatar: String(picture),
                        }}
                        title={values.title}
                        description={values.description}
                        filePreviews={filePreviews}
                        setFilePreviews={setFilePreviews}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {values.questionType === QuestionType.CHOICE && (
                      <NewChoiceQuestion
                        author={{
                          _id: "",
                          username,
                          avatar: String(picture),
                        }}
                        title={values.title}
                        description={values.description}
                        filePreviews={filePreviews}
                        setFilePreviews={setFilePreviews}
                        setFieldValue={setFieldValue}
                        choices={values.choices}
                      />
                    )}
                    <LineBreak />
                  </>
                )}
                {values.questionType && (
                  <div className="flex flex-row justify-between">
                    <div className="center flex flex-row justify-evenly">
                      <FileUpload
                        minAllowed={0}
                        maxAllowed={choices ? choices : 1}
                        setFilePreviews={setFilePreviews}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div>
                      <button
                        className="button-cancel"
                        type="button"
                        onClick={resetModal}
                      >
                        Cancel
                      </button>
                      <button className="button" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default NewQuestion;
