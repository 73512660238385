import { useState } from "react";

import RegistrationModal from "./RegistrationModal";
import LoginModal from "./LoginModal";
import AuthHeader from "./AuthHeader";
import ForgotPasswordModal from "./ForgotPasswordModal";

interface AuthModalProps {
  modalRef: any;
}

export enum ModalState {
  SIGN_IN = "sign_in",
  SIGN_UP = "sign_up",
  FORGOT = "forgot",
}

const AuthModal = ({ modalRef }: AuthModalProps) => {
  const [modalState, setModalState] = useState<ModalState>(ModalState.SIGN_IN);

  const handleModalStateChange = () => {
    if (modalState === ModalState.SIGN_IN) {
      setModalState(ModalState.SIGN_UP);
    } else {
      setModalState(ModalState.SIGN_IN);
    }
  };

  return (
    <div
      ref={modalRef}
      className="center h-full overflow-y-scroll scrollbar-hidden"
    >
      <div className="card w-80 text-sm h-5/6 flex flex-col justify-between min-h-[550px]">
        {modalState === ModalState.FORGOT ? (
          <ForgotPasswordModal setModalState={setModalState} />
        ) : (
          <>
            <AuthHeader state={modalState} />
            <div className="align-middle">
              {modalState === ModalState.SIGN_IN ? (
                <LoginModal setModalState={setModalState} />
              ) : (
                <RegistrationModal />
              )}
            </div>
            <div className="flex flex-col justify-center">
              <div className="center">
                <span className="mr-2">
                  {modalState === ModalState.SIGN_IN
                    ? "Not a member? "
                    : "Already a member? "}
                </span>
                <span
                  className="cursor-pointer text-blue-400"
                  onClick={handleModalStateChange}
                >
                  {modalState === ModalState.SIGN_IN ? "Sign Up" : "Sign In"}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthModal;
