import { ComponentType } from "react";

interface QuestionHeaderMenuItemProps {
  handleMenuItemClick: () => void;
  Icon: ComponentType<{ size: number }>;
  text: string;
  size?: number;
  red?: boolean;
}

const QuestionHeaderMenuItem = ({
  handleMenuItemClick,
  Icon,
  text,
  size,
  red,
}: QuestionHeaderMenuItemProps) => {
  const ICON_SIZE = 15;
  return (
    <button onClick={handleMenuItemClick} className="question-header-menu-item">
      <div className="flex items-center">
        <span className={red ? "text-red-500" : ""}>
          <Icon size={size || ICON_SIZE} />
        </span>
        <span className="ml-2 text-sm">{text}</span>
      </div>
    </button>
  );
};

export default QuestionHeaderMenuItem;
