import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";
import { PrivacyViewing } from "../../types/enums";

export interface PrivacyState {
  questions: PrivacyViewing;
  comments: PrivacyViewing;
  unblocked: Array<string>;
}

const initialState: PrivacyState = {
  questions: PrivacyViewing.NONE,
  comments: PrivacyViewing.NONE,
  unblocked: [],
};

export const privacySlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPrivacySettings: (
      state,
      action: PayloadAction<Partial<PrivacyState>>
    ) => {
      return { ...state, ...action.payload };
    },
    addUnblocked: (state, action: PayloadAction<string>) => {
      const unblocked = state.unblocked ?? [];
      return {
        ...state,
        unblocked: [...unblocked, action.payload],
      };
    },
  },
});

export const { setPrivacySettings, addUnblocked } = privacySlice.actions;

export const selectPrivacy = (state: RootState) => state.privacy;

export default privacySlice.reducer;
