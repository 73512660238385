import { FaUserPlus, FaUserCheck, FaUserClock } from "react-icons/fa";

import { FollowState } from "../../../types/enums";
import { RiUserUnfollowFill } from "react-icons/ri";

interface FollowButtonProps {
  followState: FollowState;
  followHovered: boolean;
}

const FollowButton = ({ followState, followHovered }: FollowButtonProps) => {
  if (followState === FollowState.FOLLOWING)
    return (
      <div className="flex items-center cursor-pointer">
        {followHovered ? (
          <RiUserUnfollowFill size={22} />
        ) : (
          <FaUserCheck size={22} />
        )}
        <span className="ml-2">{followHovered ? "Unfollow" : "Following"}</span>
      </div>
    );

  if (followState === FollowState.REQUESTED)
    return (
      <div className="flex items-center">
        <FaUserClock size={22} />
        <span className="ml-2">Requested</span>
      </div>
    );

  return (
    <div className="flex items-center">
      <FaUserPlus size={22} />
      <span className="ml-2">Follow</span>
    </div>
  );
};

export default FollowButton;
