import { useState } from "react";
import { isEmpty } from "lodash";

import { ChoiceAnswers } from "../../../../../types/answers";
import { cmToFeetAndInches, groupByHeight } from "../../../../../utils/math";
import VerticalBars from "../VerticalBar";
import RangeSlider from "../../../../pages/Settings/Demographics/Modals/RangeSlider";
import useRangeSetter from "../../../../../hooks/useRangeSetter";

interface HeightDataProps {
  answers: ChoiceAnswers;
  colorMap: any;
}

const HeightData = ({ answers, colorMap }: HeightDataProps) => {
  const [interval, setInterval] = useState(10);
  const [hoveredKey, setHoveredKey] = useState<string | null>(null);

  const handleMouseEnter = (key: string) => {
    setHoveredKey(key);
  };

  const handleMouseLeave = () => {
    setHoveredKey(null);
  };

  const { newRanges } = useRangeSetter(
    interval,
    answers?.height,
    groupByHeight
  );

  return (
    <>
      {!isEmpty(answers?.height) ? (
        <>
          <div className="mb-8 font-semibold">Height</div>
          <div className="flex justify-evenly">
            {newRanges &&
              Object.entries(newRanges)
                .sort(([aKey], [bKey]) => {
                  if (aKey > bKey) return 1;
                  if (bKey > aKey) return -1;
                  return 0;
                })
                .map(([key, value]) => {
                  if (isEmpty(value)) return null;
                  return (
                    <div key={key} className="w-f">
                      <div>
                        <VerticalBars
                          data={value}
                          colorMap={colorMap}
                          hoverKey={hoveredKey}
                          handleMouseEnter={handleMouseEnter}
                          handleMouseLeave={handleMouseLeave}
                          range={key}
                        />
                      </div>
                      <div
                        className="text-sm mt-5 pb-7 text-nowrap"
                        style={{ transform: "rotate(-90deg)" }}
                      >
                        {cmToFeetAndInches(key)}
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className="center mt-6">
            <div className="w-full">
              <RangeSlider
                id="height-slider"
                min={5}
                max={10}
                value={interval}
                onChange={(e) => setInterval(e)}
              />
            </div>
            <span className="text-nowrap mb-2">Height Range</span>
          </div>
        </>
      ) : (
        <div className="center">No Data to Display at this time</div>
      )}
    </>
  );
};

export default HeightData;
