import { useQuery } from "@tanstack/react-query";

import { getFollowing } from "../../../api/followApi";
import noAvatar from "../../../assets/noAvatar.png";
import noBanner from "../../../assets/noBanner.png";
import { formatDistanceToNow } from "date-fns";
import { authenticate } from "../../../api/authApi";
import { useNavigate } from "react-router-dom";
import { errorCatcher } from "../../../utils/functions";

const Following = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["getFollowing"],
    queryFn: () => {
      return getFollowing().then((response) => {
        const data = response.data;
        return data;
      });
    },
  });

  const goToProfile = (username: string) => {
    authenticate()
      .then(() => {
        navigate(`/profile/${username}`);
      })
      .catch((error) => {
        errorCatcher(error, "Must be logged in to view profiles");
      });
  };

  return (
    <div className="grid grid-cols-2">
      {data?.following.length > 0 ? (
        data?.following.map((request: any) => (
          <div className="flex">
            <div
              className="flex flex-col cursor-pointer"
              onClick={() => goToProfile(request.leader.username)}
            >
              <div className="relative">
                <img
                  src={
                    request.leader.headerImage
                      ? request.leader.headerImage
                      : noBanner
                  }
                  alt="banner"
                  height="auto"
                  style={{ maxWidth: "100%", height: "auto" }}
                  className="rounded-lg"
                />
                <img
                  className="absolute left-[2%] top-[16.6%] w-1/6 h-4/6 rounded-full border-2 border-black"
                  src={request.leader.avatar ? request.leader.avatar : noAvatar}
                  alt="avatar"
                />
              </div>
              <div className="flex text-sm">
                <div className="w-1/3">
                  <div className="font-semibold">{request.leader.username}</div>
                  <div className="text-xs text-gray-500">
                    {formatDistanceToNow(new Date(request.createdAt), {
                      addSuffix: true,
                    })}
                  </div>
                </div>
                <div className="w-1/3">
                  <div>Asked: {request.leader.stats.asked}</div>
                  <div>Answered: {request.leader.stats.answered}</div>
                </div>
                <div className="w-1/3">
                  <div>Following: {request.leader.stats.following}</div>
                  <div>Followers: {request.leader.stats.followers}</div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="center mt-4">
          You are not following anyone at this time
        </div>
      )}
    </div>
  );
};

export default Following;
