import axios from "axios";
import Cookies from "js-cookie";

import { COOKIE_TOKEN } from "../constants/strings";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const API_ROUTE = `${DOMAIN}/api/v1`;
const AUTH_ROUTES = `${API_ROUTE}/user`;

export const register = async (
  email: string,
  username: string,
  password: string,
  password2: string
) => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/signup`,
    data: {
      email,
      username,
      password,
      password2,
    },
  });
};

export const terminate = async () => {
  return await axios({
    method: "DELETE",
    url: `${AUTH_ROUTES}/terminate`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const login = async (
  emailOrUsername: string,
  password: string,
  rememberMe: boolean
) => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/login`,
    data: {
      emailOrUsername,
      password,
      rememberMe,
    },
  });
};

export const authenticate = async () => {
  return await axios({
    method: "GET",
    url: `${AUTH_ROUTES}/auth`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const resendVerification = async () => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/resendVerification`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
  });
};

export const subscribeToPremium = async () => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/premium`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {},
  });
};

export const verifyEmail = async (token: string) => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/verify`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      token,
    },
  });
};

export const forgotPassword = async (emailOrUsername: string) => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/forgot-password`,
    data: {
      emailOrUsername,
    },
  });
};

interface NewPasswordProps {
  token: string;
  newPass: string;
  confirmPass: string;
}

export const newPassword = async ({
  token,
  newPass,
  confirmPass,
}: NewPasswordProps) => {
  return await axios({
    method: "POST",
    url: `${AUTH_ROUTES}/new-password`,
    data: {
      token,
      newPass,
      confirmPass,
    },
  });
};
