import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";

export interface ModalState {
  isModalOpen: boolean;
  modalState: string;
  deleteId?: string;
  blockUserId?: string;
  blockQuestionId?: string;
  refId?: string;
  imagePreviews?: Array<string>;
}

const initialState: ModalState = {
  isModalOpen: false,
  modalState: "",
  deleteId: "",
  blockUserId: "",
  blockQuestionId: "",
  imagePreviews: [],
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<Partial<ModalState>>) => {
      return { ...state, ...action.payload };
    },
    setRefId: (state, action: PayloadAction<string>) => {
      return { ...state, refId: action.payload };
    },
    setDeleteId: (state, action: PayloadAction<string>) => {
      return { ...state, deleteId: action.payload };
    },
    setBlockUserId: (state, action: PayloadAction<string>) => {
      return { ...state, blockUserId: action.payload };
    },
    setBlockQuestionId: (state, action: PayloadAction<string>) => {
      return { ...state, blockQuestionId: action.payload };
    },
    setImagePreviews: (state, action: PayloadAction<Array<string>>) => {
      return { ...state, imagePreviews: action.payload };
    },
    closeModal: () => {
      return { ...initialState };
    },
  },
});

export const {
  setModal,
  setRefId,
  setDeleteId,
  setBlockUserId,
  setBlockQuestionId,
  setImagePreviews,
  closeModal,
} = modalSlice.actions;

export const selectModal = (state: RootState) => state.modal;

export default modalSlice.reducer;
