import { useState } from "react";

import Modal from "../../common/Modal";
import ModalMenu from "./ModalMenu";
import { Gender } from "../../../../../types/enums";

interface GenderModalProps {
  modalRef: any;
}

const GenderModal = ({ modalRef }: GenderModalProps) => {
  const [selection, setSelection] = useState("");

  return (
    <div ref={modalRef}>
      <Modal title="Gender">
        <ModalMenu
          type="gender"
          valuesEnum={Gender}
          selection={selection}
          setSelection={setSelection}
        />
      </Modal>
    </div>
  );
};

export default GenderModal;
