import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Geocoder } from "@mapbox/search-js-react";
import ReactLoading from "react-loading";

import LineBreak from "../../../../../utils/LineBreak";
import { reverseGeocode } from "../../../../../api/externalApi";
import { FeatureCollection } from "../../../../../types/mapbox";
import Modal from "../../common/Modal";
import { toaster } from "../../../../../utils/toast";
import {
  unshareDemographics,
  updateDemographics,
} from "../../../../../api/demographicsApi";
import { selectUser } from "../../../../../store/features/authSlice";
import {
  selectDemographics,
  setAllDemographics,
} from "../../../../../store/features/demographicsInfoSlice";
import { closeModal } from "../../../../../store/features/modalSlice";
import { convertCountryNames } from "../../../../../utils/functions";

const LocationModal = () => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);
  const { location } = useSelector(selectDemographics);

  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [geoPos, setGeoPos] = useState<GeolocationPosition | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [geocodeLoading, setGeocodeLoading] = useState(false);
  const [hasPrevious, setPrevious] = useState(location?.city !== undefined);

  const success = (position: GeolocationPosition) => {
    setGeoPos(position);
  };

  const error = () => {};

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
      setGeocodeLoading(true);
    }
  };

  const goBack = () => {
    setCity("");
    setGeoPos(null);
    setLongitude(null);
    setLatitude(null);
  };

  const setDemographic = () => {
    if (city && longitude && latitude) {
      const location = {
        city,
        district,
        province: region,
        longitude,
        latitude,
        country,
      };
      updateDemographics({ userId, location })
        .then(() => {
          dispatch(setAllDemographics({ location }));
          dispatch(closeModal());
        })
        .catch((error) => {
          console.error(error.response.data.message);
          toaster(error.response.data.message);
        });
    }
  };

  const unshare = () => {
    unshareDemographics({ userId, field: "location" })
      .then(() => {
        dispatch(setAllDemographics({ location: undefined }));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster({ text: error.response.data.message });
      });
  };

  useEffect(() => {
    const fetchCity = async () => {
      if (geoPos?.coords) {
        await reverseGeocode(geoPos.coords.latitude, geoPos.coords.longitude)
          .then((res: any) => {
            const data: FeatureCollection = res.data;
            if (data.features && data.features.length > 0) {
              const city = data.features.find((feature: any) =>
                feature.place_type.includes("place")
              );
              const district = data.features.find((feature: any) =>
                feature.place_type.includes("district")
              );
              const region = data.features.find((feature: any) =>
                feature.place_type.includes("region")
              );
              const country = data.features.find((feature: any) =>
                feature.place_type.includes("country")
              );

              city && setCity(city.text);
              district && setDistrict(district.text);
              region && setRegion(region.text);
              country && setCountry(convertCountryNames(country.text));
              setLongitude(data.query[0]);
              setLatitude(data.query[1]);

              if (!city) throw new Error("Error finding coordinates");
            }
          })
          .catch((error) => {
            console.error("Cant fetch city: ", error);
            toaster({
              text: `Error fetching city ${error.response.data.message}`,
            });
          });
      }
      setGeocodeLoading(false);
    };

    fetchCity();
  }, [geoPos?.coords]);

  const isDisabled =
    city === location?.city &&
    longitude === location.longitude &&
    latitude === location.latitude;

  return (
    <Modal title="Location">
      <div className="w-80">
        {hasPrevious ? (
          <>
            <div className="center mb-4 text-sm font-semibold">
              Previous Set Location
            </div>
            <div className="center">{location?.city}</div>
            <button
              className="button w-full mt-5"
              onClick={() => setPrevious(false)}
            >
              Change
            </button>
            <button className="button-cancel w-full mt-5" onClick={unshare}>
              Unshare
            </button>
          </>
        ) : (
          <>
            {longitude && latitude ? (
              <div className="center">{city}</div>
            ) : (
              <>
                <div className="mb-2">Search for your city</div>
                <div className="mb-5">
                  {/* @ts-ignore */}
                  <Geocoder
                    placeholder="Find your city"
                    accessToken={process.env.REACT_APP_MAP_BOX_ACCESS_KEY!}
                    options={{
                      language: "en",
                      types: "place",
                    }}
                    value={city}
                    onRetrieve={(e) => {
                      console.log(e);
                      e.properties.context.place?.name &&
                        setCity(e.properties.context.place?.name);
                      e.properties.context.district?.name &&
                        setDistrict(e.properties.context.district?.name);
                      e.properties.context.region?.name &&
                        setRegion(e.properties.context.region?.name);
                      setCountry(
                        convertCountryNames(e.properties.context.country?.name)
                      );
                      setLongitude(e.geometry.coordinates[0]);
                      setLatitude(e.geometry.coordinates[1]);
                    }}
                  />
                </div>
                <LineBreak text="or" />
                {geocodeLoading ? (
                  <div className="center">
                    <ReactLoading type="bars" color="#ccc" />
                  </div>
                ) : (
                  <div className="center mt-5">
                    <button className="button" onClick={handleLocationClick}>
                      Fetch Location
                    </button>
                  </div>
                )}
              </>
            )}
            {longitude && latitude && (
              <div className="flex justify-evenly mt-5">
                <button className="button-cancel " onClick={goBack}>
                  Back
                </button>
                <button
                  className={`${isDisabled ? "button-disabled" : "button"} `}
                  onClick={setDemographic}
                  disabled={isDisabled}
                >
                  Save
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default LocationModal;
