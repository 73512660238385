import { useState } from "react";
import { Rating as ReactRating } from "react-simple-star-rating";
import { GiRoundStar } from "react-icons/gi";
import { isEmpty } from "lodash";

import { RatingAnswers } from "../../../../../types/answers";
import { groupByAge } from "../../../../../utils/math";
import RangeSlider from "../../../../pages/Settings/Demographics/Modals/RangeSlider";
import useRangeSetter from "../../../../../hooks/useRangeSetter";

interface AgeDataProps {
  answers: RatingAnswers;
}

const AgeData = ({ answers }: AgeDataProps) => {
  const [interval, setInterval] = useState(10);

  const { newRanges } = useRangeSetter(interval, answers?.age, groupByAge);

  return (
    <>
      {!isEmpty(answers?.age) ? (
        <>
          <div className="mb-8 font-semibold">Age</div>
          <div className="flex justify-evenly">
            {newRanges &&
              Object.entries(newRanges).map(([key, value]) => {
                if (isEmpty(value)) return null;
                const average = value.total / value.count;
                return (
                  <div key={key}>
                    <div className="center">{average.toFixed(2)}</div>
                    <ReactRating
                      size={60}
                      emptyIcon={<GiRoundStar size={60} />}
                      fillIcon={<GiRoundStar size={60} color="lightblue" />}
                      initialValue={average / 10}
                      iconsCount={1}
                      emptyStyle={{ display: "flex" }}
                      allowTitleTag={false}
                      fillStyle={{ display: "-webkit-inline-box" }}
                      style={{ marginTop: 18 }}
                      allowFraction={true}
                      allowHover={false}
                      readonly
                    />
                    <div
                      className="text-sm mt-6 text-nowrap"
                      style={{ transform: "rotate(-75deg)" }}
                    >
                      {key}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="center mt-6">
            <div className="w-full">
              <RangeSlider
                id="age-slider"
                min={5}
                max={10}
                value={interval}
                onChange={(e) => setInterval(e)}
              />
            </div>
            <span className="text-nowrap mb-2">Age Range</span>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AgeData;
