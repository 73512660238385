export const SITE_NAME = "POLIQS";
export const COOKIE_TOKEN = "JWT-demographs";
export const LOGOUT_ACTION = "logout";

export const AUTH_MODAL_STATE = "authorization-modal";
export const QUESTION_MODAL_STATE = "new-question-modal";
export const REPORT_QUESTION_MODAL = "report-question-modal";
export const REPORT_COMMENT_MODAL = "report-comment-modal";
export const BLOCK_QUESTIONS_MODAL = "block-questions-modal";
export const BLOCK_USERS_MODAL = "block-users-modal";
export const UNBLOCK_USERS_MODAL = "unblock-users-modal";
export const DELETE_QUESTION_MODAL = "delete-question-modal";
export const DELETE_COMMENT_MODAL = "delete-comment-modal";
export const IMAGE_PREVIEW_MODAL = "question-images-preview-modal";

export const LEFT_PANEL_DRAWER = "left-panel-drawer";

export const PAGES_MY_PROFILE = "MyProfile";
export const PAGES_SETTINGS = "Settings";
export const PAGES_PROFILE = "Profile";

export const PROFILE_QUESTIONS = "Questions";
export const PROFILE_COMMENTS = "Comments";
export const PROFILE_LIKED = "Liked";
export const PROFILE_VOTED = "Voted";
export const PROFILE_FOLLOWING = "Following";
export const PROFILE_FOLLOWERS = "Followers";

export const SETTINGS_ACCOUNT = "Account";
export const SETTINGS_PROFILE = "Profile";
export const SETTINGS_DEMOGRAPHICS = "Demographics";
export const SETTINGS_PRIVACY = "Privacy";
export const SETTIGNS_PREFERENCES = "Preferences";

export const SETTINGS_ACCOUNT_EMAIL_MODAL = "settings-account-modal-email";
export const SETTINGS_ACCOUNT_PASSWORD_MODAL =
  "settings-account-modal-password";
export const SETTINGS_ACCOUNT_USERNAME_MODAL =
  "settings-account-modal-username";
export const SETTINGS_ACCOUNT_SUBSCRIBE_MODAL =
  "settings-account-modal-subscribe";
export const SETTINGS_ACCOUNT_TERMINATE_MODAL =
  "settings-account-modal-terminate";

export const SETTINGS_PROFILE_USERNAME_MODAL =
  "settings-profile-modal-username";
export const SETTINGS_PROFILE_PICTURE_MODAL = "settings-profile-modal-picture";
export const SETTINGS_PROFILE_BANNER_MODAL = "settings-profile-modal-banner";
export const SETTINGS_PROFILE_BIO_MODAL = "settings-profile-modal-bio";

export const SETTINGS_DEMOGRAPHIC_AGE_MODAL = "settings-demographics-modal-age";
export const SETTINGS_DEMOGRAPHIC_HEIGHT_MODAL =
  "settings-demographics-modal-height";
export const SETTINGS_DEMOGRAPHIC_GENDER_MODAL =
  "settings-demographics-modal-gender";
export const SETTINGS_DEMOGRAPHIC_ETHNICITY_MODAL =
  "settings-demographics-modal-ethnicity";

export const SETTINGS_DEMOGRAPHIC_ORIENTATION_MODAL =
  "settings-demographics-modal-orientation";
export const SETTINGS_DEMOGRAPHIC_RELIGION_MODAL =
  "settings-demographics-modal-religion";
export const SETTINGS_DEMOGRAPHIC_POLITICAL_MODAL =
  "settings-demographics-modal-political";

export const SETTINGS_DEMOGRAPHIC_LOCATION_MODAL =
  "settings-demographics-modal-location";

export const SETTINGS_PRIVACY_VIEW_QUESTIONS =
  "settings-privacy-view-questions";
export const SETTINGS_PRIVACY_VIEW_COMMENTS = "settings-privacy-view-comments";
