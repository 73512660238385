import { useNavigate } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { formatDistanceToNow } from "date-fns";

import { getProfileComments } from "../../../api/followApi";
import { useQuery } from "@tanstack/react-query";

interface CommentsProps {
  username: string;
}

const Comments = ({ username }: CommentsProps) => {
  const navigate = useNavigate();

  const goToQuestion = (questionId: string) => {
    navigate(`/question/${questionId}`);
  };

  const { data: comments } = useQuery({
    queryKey: ["getComments", username],
    queryFn: () =>
      getProfileComments(username).then((response) => {
        const data = response.data;
        return data?.comments;
      }),
  });

  return (
    <>
      {comments?.length > 0 ? (
        comments
          .filter((comment: any) => comment.question !== null)
          .map((c: any) => {
            const timeAgo = formatDistanceToNow(new Date(c.createdAt), {
              addSuffix: true,
            });

            return (
              <div
                key={c._id}
                className="cursor-pointer pt-2"
                onClick={() => goToQuestion(c.question._id)}
              >
                <div className="flex flex-row justify-between pb-3">
                  <div className="flex">
                    <div className="flex flex-col">
                      <div className="font-semibold truncate">
                        {c.questio?.title}
                      </div>
                      <div className="truncate">{c?.text}</div>
                      <span className="text-sm text-gray-500">{timeAgo}</span>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col text-center pt-2">
                      <div>
                        <FaAngleUp />
                      </div>
                      {c.score}
                      <div>
                        <FaAngleDown />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            );
          })
      ) : (
        <div className="center mt-4">No comments yet</div>
      )}
    </>
  );
};

export default Comments;
