import { Dispatch, Fragment, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { RiPictureInPictureExitFill } from "react-icons/ri";

import { Choice, FilterItem } from "../../../types";
import ChoiceDisplay from "./Displays/ChoiceDisplay";
import RatingDisplay from "./Displays/RatingDisplay";
import { ChoiceAnswers, RatingAnswers } from "../../../types/answers";
import RatingData from "./Data/RatingData";
import ChoiceData from "./Data/ChoiceData";
import { QuestionType } from "../../../types/enums";
import { setModal } from "../../../store/features/modalSlice";
import { IMAGE_PREVIEW_MODAL } from "../../../constants/strings";
import DemoFilter from "./QuestionHeader/DemoFilter";

interface QuestionContentProps {
  questionType: QuestionType;
  title: string;
  description: string;
  image?: string;
  myAnswer: any;
  choices?: Array<Choice>;
  flip: boolean;
  answers?: ChoiceAnswers | RatingAnswers;
  setSelectedChoice: Dispatch<SetStateAction<string>>;
  setSelectedRating: Dispatch<SetStateAction<number>>;
  filtering: boolean;
  setFilters?: Dispatch<SetStateAction<Array<FilterItem>>>;
}

const QuestionContent = ({
  questionType,
  title,
  description,
  image,
  myAnswer,
  choices,
  flip,
  answers,
  setSelectedChoice,
  setSelectedRating,
  filtering,
  setFilters,
}: QuestionContentProps) => {
  const dispatch = useDispatch();

  let allPictures = false;
  if (questionType === QuestionType.CHOICE && choices) {
    allPictures = !choices.some((choice) => choice.picture === undefined);
  }

  const openImagePreviewModal = () => {
    let modalImages = [];
    if (image) {
      modalImages.push(image);
    }
    if (allPictures) {
      choices?.forEach((choice) => modalImages.push(choice.picture));
    }

    dispatch(
      setModal({
        isModalOpen: true,
        modalState: IMAGE_PREVIEW_MODAL,
        imagePreviews: modalImages,
      })
    );
  };

  return (
    <>
      <div className="center text-normal font-semibold mt-2">{title}</div>
      {filtering && <DemoFilter setFilters={setFilters} />}
      {flip ? (
        <>
          {questionType === QuestionType.RATING && (
            <RatingData
              answers={answers as RatingAnswers}
              hasAnswer={!!myAnswer}
            />
          )}
          {questionType === QuestionType.CHOICE && (
            <ChoiceData
              answers={answers as ChoiceAnswers}
              choices={choices!}
              hasAnswer={!!myAnswer}
            />
          )}
        </>
      ) : (
        <>
          <div className="text-sm my-2">
            <div className="font-normal">
              {description.split("\n").map((line: string, index: number) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
          {(image || allPictures) && (
            <button className="w-full center" onClick={openImagePreviewModal}>
              <RiPictureInPictureExitFill color="#3b82f6" size={20} />
            </button>
          )}
          <div>
            {questionType === QuestionType.RATING && (
              <RatingDisplay
                previouslySelected={myAnswer}
                image={image}
                setSelectedRating={setSelectedRating}
              />
            )}
            {questionType === QuestionType.CHOICE && (
              <ChoiceDisplay
                previouslySelected={myAnswer}
                choices={choices!}
                setSelectedChoice={setSelectedChoice}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default QuestionContent;
