import { CSSProperties } from "react";

interface CustomCSSProperties extends CSSProperties {
  "--c"?: string;
  "--min"?: number;
  "--max"?: number;
}

interface RangeSliderProps {
  id: string;
  min: number;
  max: number;
  value: number;
  onChange: (newCm: number) => void;
  color?: string;
  sliders?: number;
}

const RangeSlider = ({
  id,
  min,
  max,
  value,
  onChange,
  color,
  sliders,
}: RangeSliderProps) => {
  const labelStyle: CustomCSSProperties = {
    "--c": color,
  };

  const height = sliders === 2 ? "h-1/2" : "h-full";

  return (
    <label htmlFor={id}>
      <input
        className={`range-slider ${height} py-3 w-[95%]`}
        id={id}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        style={labelStyle}
      />
    </label>
  );
};

export default RangeSlider;
