import { ReactNode } from "react";
import { kebabCase, snakeCase } from "lodash";

interface SectionProps {
  title: string;
  children: ReactNode;
}

const Section = ({ title, children }: SectionProps) => {
  return (
    <div className="p-6 pb-4">
      <h3 id={snakeCase(title)} className="text-[30px] pb-4 font-semibold">
        {title}
      </h3>
      <div className="text-lg">{children}</div>
    </div>
  );
};

export default Section;

interface BulletedListProps {
  head: string;
  items: Array<string>;
}

export const BulletedList = ({ head, items }: BulletedListProps) => {
  return (
    <>
      <p>{head}</p>
      <ul className="list-disc pl-6">
        {items.map((i) => {
          return <li key={i}>{i}</li>;
        })}
      </ul>
    </>
  );
};

interface SectionLinkProps {
  text: string;
  link: string;
}

export const SectionLink = ({ text, link }: SectionLinkProps) => {
  return (
    <a className="text-blue-500" href={link}>
      {text}
    </a>
  );
};

interface PProps {
  children?: ReactNode;
  i?: boolean;
  b?: boolean;
  u?: boolean;
}

export const P = ({ children, i, b, u }: PProps) => {
  return (
    <p
      className={`pb-2 ${i && "italic"} ${b && "font-bold"} ${
        u && "underline"
      }`}
    >
      {children}
    </p>
  );
};

interface PrivacyQAProps {
  question: string;
  answer: string;
  linkText?: string;
  link?: string;
  i?: boolean;
  p?: number;
}

export const PrivacyQA = ({
  question,
  answer,
  linkText,
  link,
  i,
  p,
}: PrivacyQAProps) => {
  return (
    <div style={{ padding: p || 0 }}>
      <P i={i}>
        <b>{question} </b>
        {answer}{" "}
        {linkText && link && <SectionLink text={linkText} link={link} />}
      </P>
    </div>
  );
};

interface H1Props {
  children: ReactNode;
}

export const H1 = ({ children }: H1Props) => {
  return (
    <h1
      id={kebabCase(children?.toString())}
      className="text-xl mt-6 pb-2 font-semibold"
    >
      {children}
    </h1>
  );
};

interface H2Props {
  children: ReactNode;
}

export const H2 = ({ children }: H2Props) => {
  return <h2 className="text-lg mt-4 pb-2 font-semibold">{children}</h2>;
};

interface ULProps {
  children: ReactNode;
}

export const UL = ({ children }: ULProps) => {
  return <ul className="list-disc pl-6">{children}</ul>;
};

interface MailLinkProps {
  mail: string;
  text?: string;
}

export const MailLink = ({ mail, text }: MailLinkProps) => {
  return (
    <a className="text-blue-500" href={mail}>
      {text ? text : mail}
    </a>
  );
};
