import axios from "axios";
import Cookies from "js-cookie";

import { COOKIE_TOKEN } from "../constants/strings";
import { ReportType } from "../types/enums";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const API_ROUTE = `${DOMAIN}/api/v1`;
const PRIVACY_ROUTE = `${API_ROUTE}/privacy`;

interface ReportData {
  reportType: ReportType;
  refId: string;
  reason: string;
}

export const createReport = async (data: ReportData) => {
  return await axios({
    method: "POST",
    url: `${PRIVACY_ROUTE}/report`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data,
  });
};

export const blockUser = async (blockUserId: string) => {
  return await axios({
    method: "POST",
    url: `${PRIVACY_ROUTE}/blockUser`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      blockUserId,
    },
  });
};

export const blockQuestion = async (
  blockQuestionId: string,
  blockUserId?: string
) => {
  return await axios({
    method: "POST",
    url: `${PRIVACY_ROUTE}/blockQuestion`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      blockQuestionId,
      ...(blockUserId && { blockUserId }),
    },
  });
};

export const unblockUser = async (blockUserId: string) => {
  return await axios({
    method: "POST",
    url: `${PRIVACY_ROUTE}/unblockUser`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      blockUserId,
    },
  });
};
