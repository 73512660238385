import { useRef, useState } from "react";
import { FormikErrors } from "formik";
import { AiFillPicture } from "react-icons/ai";
import imageCompression from "browser-image-compression";

import { ChoiceFormValues } from "../types";

interface FileUploadProps {
  minAllowed: number;
  maxAllowed: number;
  setFilePreviews: (value: React.SetStateAction<string[]>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<ChoiceFormValues>>;
}

const FileUpload = ({
  minAllowed,
  maxAllowed,
  setFilePreviews,
  setFieldValue,
}: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [, setFileError] = useState("");

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (
      selectedFiles &&
      selectedFiles.length >= minAllowed &&
      selectedFiles.length <= maxAllowed
    ) {
      const options = {
        maxSizeMB: 30, // Maximum size in MB
        useWebWorker: true,
      };

      Promise.all(
        Array.from(selectedFiles).map((file: File) =>
          imageCompression(file, options)
        )
      )
        .then((compressedFiles) => {
          return Promise.all(
            compressedFiles.map((file: File) => {
              return new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                  if (
                    event.target?.result &&
                    typeof event.target.result === "string"
                  ) {
                    resolve(event.target.result);
                  }
                };
                reader.readAsDataURL(file);
              });
            })
          ).then((previews) => {
            setFilePreviews(previews);
            setFieldValue("files", compressedFiles);
          });
        })
        .catch((error) => {
          console.error("Error compressing the file:", error);
        });
    } else {
      setFileError(
        `Please select between ${minAllowed} and ${maxAllowed} files`
      );
    }
  };

  return (
    <div
      className="cursor-pointer mx-2"
      onClick={() => {
        inputRef.current?.click();
      }}
    >
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
        multiple={maxAllowed > 1}
      />
      <AiFillPicture size={28} color="#3b82f6" />
    </div>
  );
};

export default FileUpload;
