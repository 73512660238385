import { subscribeToPremium } from "../../api/authApi";

const Premium = () => {
  const handleButtonClick = async () => {
    await subscribeToPremium()
      .then((res) => {})
      .catch((error) => console.error(error));
  };
  return <button onClick={handleButtonClick}>Sub</button>;
};

export default Premium;
