import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

export interface UserState {
  userId: string;
  email: string;
  username: string;
  picture?: string;
  premium: boolean;
  emailVerified: boolean;
}

const initialState: UserState = {
  userId: "",
  email: "",
  username: "",
  picture: "",
  premium: false,
  emailVerified: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, action: PayloadAction<UserState>) => {
      return action.payload;
    },
    setPartialUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    removeUser: () => {
      return initialState;
    },
  },
});

export const { setUser, setPartialUser, removeUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
