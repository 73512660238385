import { useDispatch, useSelector } from "react-redux";
import { FiPlusCircle } from "react-icons/fi";

import { QuestionData } from "../../../types";
import Question from "../Question";
import { selectFeed } from "../../../store/features/feedSlice";
import { toaster } from "../../../utils/toast";
import { setModal } from "../../../store/features/modalSlice";
import { selectUser } from "../../../store/features/authSlice";
import * as strings from "../../../constants/strings";
import Loading from "../../Loading";

interface ContentProps {
  isLoading: boolean;
  feed: Array<QuestionData>;
  feedRef: any;
}

const Content = ({ isLoading, feed, feedRef }: ContentProps) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);
  const { deletedQuestions, addedQuestions } = useSelector(selectFeed);

  const filteredFeed = feed.filter(
    (item: QuestionData) => !deletedQuestions?.includes(item._id)
  );

  const newQuestions = addedQuestions ? addedQuestions : [];
  const combinedFeed = [...newQuestions, ...filteredFeed];

  const uniqueIds = new Set();
  const updatedFeed = combinedFeed.filter((item) => {
    if (uniqueIds.has(item._id)) {
      return false;
    } else {
      uniqueIds.add(item._id);
      return true;
    }
  });

  const setModalState = (modalState: string) => {
    dispatch(setModal({ isModalOpen: true, modalState }));
  };

  const addQuestion = async () => {
    if (!userId) {
      toaster({ text: "You must be logged in to perform this action!" });
      setModalState(strings.AUTH_MODAL_STATE);
    } else {
      setModalState(strings.QUESTION_MODAL_STATE);
    }
  };

  return (
    <div className="w-full flex justify-center">
      <div className="center flex-row w-full max-w-[1000px]">
        <div
          ref={feedRef}
          className="container min-w-[320px] overflow-y-scroll h-screen scrollbar-hidden pb-20"
        >
          <button className="new-question-button" onClick={addQuestion}>
            <FiPlusCircle size={22} style={{ marginRight: 8 }} /> Create New
          </button>
          {
            <>
              {updatedFeed.length > 0 ? (
                <>
                  {updatedFeed.map((question: QuestionData, index: number) => {
                    return (
                      <Question
                        key={`${question._id}-${index}`}
                        id={question._id}
                        author={question.author}
                        questionType={question.questionType}
                        title={question.title}
                        description={question.description}
                        image={question.image}
                        createdAt={question.createdAt}
                        myAnswer={question.myAnswer}
                        liked={question.liked}
                        likes={question.likes}
                        comments={question.comments}
                        choices={question.choices}
                        votes={question.votes}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {!isLoading && (
                    <div className="center mt-8">
                      No results, try refreshing or come back later
                    </div>
                  )}
                </>
              )}
              {isLoading && <Loading />}
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Content;
