import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import SidePanel from "./RightPanel";
import LeftPanel from "./LeftPanel";
import PopularFeed from "./Feeds/Feed";
import { selectFeed } from "../../store/features/feedSlice";
import NewFeed from "./Feeds/NewFeed";
import FollowingFeed from "./Feeds/Following";
import { FeedTypes } from "../../types/enums";
import StickyNotification from "../../utils/StickyNotifs";
import { getMe } from "../../api/profileApi";
import { selectUser } from "../../store/features/authSlice";
import { useLocation } from "react-router-dom";
import SearchFeed from "./Feeds/SearchFeed";

const Feed = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get("search");

  const { userId } = useSelector(selectUser);
  const { feed } = useSelector(selectFeed);

  const { isLoading, data } = useQuery({
    queryKey: ["getMe", userId],
    queryFn: async () => {
      return getMe()
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return null;
        });
    },
  });

  return (
    <div className="flex flex-row">
      <div className="fixed top-14 left-0 right-0  flex flex-col z-30">
        {!isLoading && !data?.emailVerified && userId && (
          <StickyNotification message="Your email has not yet been verified. You will not be able to answer questions" />
        )}
      </div>
      <LeftPanel />
      <div className="w-full flex justify-center">
        <div className="center flex-row w-full max-w-[1000px]">
          {searchTerm ? (
            <SearchFeed searchTerm={searchTerm} />
          ) : (
            <>
              {feed === FeedTypes.POPULAR && <PopularFeed />}
              {feed === FeedTypes.NEW && <NewFeed />}
              {feed === FeedTypes.FOLLOWING && <FollowingFeed />}
            </>
          )}
          <div className="flex justify-start h-screen">
            <SidePanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;
