import { Dispatch, ReactNode, SetStateAction, useRef, useState } from "react";
import QuestionHeader from "./QuestionHeader";

import { Author, Choice, FilterItem } from "../../../types";
import QuestionContent from "./QuestionContent";
import QuestionFooter from "./QuestionFooter";
import { ChoiceAnswers, RatingAnswers } from "../../../types/answers";
import { QuestionType } from "../../../types/enums";
import "./question.css";
import "../feed.css";

interface QuestionProps {
  id: string;
  author: Author;
  createdAt: string;
  questionType: QuestionType;
  title: string;
  description: string;
  image?: string;
  myAnswer?: any;
  liked: boolean;
  likes: number;
  comments: number;
  votes: number;
  choices?: Array<Choice>;
  answers?: ChoiceAnswers | RatingAnswers;
  flipped?: boolean;
  children?: ReactNode;
  setFilters?: Dispatch<SetStateAction<Array<FilterItem>>>;
}

const Question = ({
  id,
  author,
  createdAt,
  questionType,
  title,
  description,
  image,
  myAnswer,
  liked,
  likes,
  comments,
  choices,
  votes,
  answers,
  flipped = false,
  setFilters,
  children,
}: QuestionProps) => {
  const headerRef = useRef(null);

  const [flip, setFlip] = useState(flipped);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [selectedRating, setSelectedRating] = useState(0);
  const [filtering, setFiltering] = useState(false);

  return (
    <div className="card mb-2">
      <QuestionHeader
        id={id}
        author={author}
        createdAt={createdAt}
        headerRef={headerRef}
        flip={flip}
        setFiltering={setFiltering}
      />
      <QuestionContent
        questionType={questionType}
        title={title}
        description={description}
        image={image}
        myAnswer={myAnswer}
        choices={choices}
        flip={flip}
        answers={answers}
        setSelectedChoice={setSelectedChoice}
        setSelectedRating={setSelectedRating}
        filtering={filtering}
        setFilters={setFilters}
      />
      <QuestionFooter
        id={id}
        flip={flip}
        setFlip={setFlip}
        liked={liked}
        likes={likes}
        comments={comments}
        questionType={questionType}
        votes={votes}
        answered={!!myAnswer}
        selectedChoice={selectedChoice}
        selectedRating={selectedRating}
      />
      {children}
    </div>
  );
};

export default Question;
