import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RxAvatar } from "react-icons/rx";

import { selectUser } from "../../store/features/authSlice";
import { setModal } from "../../store/features/modalSlice";
import { AUTH_MODAL_STATE } from "../../constants/strings";
import HeaderAuthMenuItem from "./HeaderAuthMenuItem";
import { authHeaderMenu } from "../../constants/menus";
import LineBreak from "../../utils/LineBreak";
import useHandleOutslideClick from "../../hooks/useHanldeOutsideClick";

const HeaderAuth = () => {
  const dispatch = useDispatch();

  const menuRef = useRef<HTMLDivElement>(null);

  const [isdropdownOpen, setDropdownOpen] = useState(false);

  const { userId, username, picture } = useSelector(selectUser);

  const setModalState = (modalState: string) => {
    dispatch(setModal({ isModalOpen: true, modalState }));
  };

  const handleButtonClick = () => {
    if (userId) setDropdownOpen(!isdropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node))
      setDropdownOpen(false);
  };

  useHandleOutslideClick(isdropdownOpen, handleClickOutside);

  return (
    <div className="center !justify-end">
      {!userId ? (
        <button
          className="button"
          onClick={() => {
            setModalState(AUTH_MODAL_STATE);
          }}
        >
          Login
        </button>
      ) : (
        <>
          <button
            className="ml-4 rounded-full overflow-hidden border-2 border-transparent hover:border-blue-500 transition-colors duration-300"
            onClick={handleButtonClick}
          >
            {picture ? (
              <img src={picture} alt="avatar" className="h-9 w-9 " />
            ) : (
              <RxAvatar size={36} />
            )}
          </button>
          <div ref={menuRef} className="absolute w-60 top-14 right-2">
            {isdropdownOpen && (
              <div className="card">
                {authHeaderMenu.map((item) => {
                  let location = item.location;
                  if (item.location === "/profile") {
                    location = `${item.location}/${username}`;
                  }

                  return (
                    <Fragment key={item.text}>
                      <HeaderAuthMenuItem
                        Icon={item.icon}
                        text={item.text}
                        location={location}
                        action={item.action}
                        closeMenu={() => setDropdownOpen(false)}
                      />
                      {item.linbreak && <LineBreak />}
                    </Fragment>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderAuth;
