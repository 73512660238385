import { useEffect, useRef, useState } from "react";
import { useHeadsObserver } from "../../hooks/useHeadsObserver";
import Section, {
  BulletedList,
  P,
  PrivacyQA,
  SectionLink,
  UL,
} from "./TOS/Section";
import TableOfContents from "./TOS/TableOfContents";
import LeftPanel from "../feed/LeftPanel";

const ContentPolicy = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [headings, setHeadings] = useState<
    Array<{ id: string; text: string; level: number }>
  >([]);

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll("h2, h3, h4")).map(
      (elem) => {
        const htmlElem = elem as HTMLElement; // Type assertion
        return {
          id: elem.id,
          text: htmlElem.innerText,
          level: Number(elem.nodeName.charAt(1)),
        };
      }
    );
    setHeadings(elements);
  }, []);

  const { activeId } = useHeadsObserver(scrollRef);

  return (
    <div className="flex">
      <LeftPanel />
      <div className="flex justify-center h-screen">
        <div
          ref={scrollRef}
          className="overflow-scroll scrollbar-hidden max-w-[800px]"
        >
          <h1 className="text-[35px] p-6 pb-4 font-bold">Content Policy</h1>
          <p className="text-[18px] p-6 pb-4">
            POLIQS is shaped by our collective actions and can only thrive if we
            adhere to a shared set of rules. We ask that you follow not only the
            letter but also the spirit of these rules:
          </p>
          <Section title="Rules">
            <PrivacyQA
              question="Rule 1"
              answer="Be nice. Everyone deserves to use Reddit without facing harassment, bullying, or threats of violence. Users that incite violence or promote hate based on identity or vulnerability will be banned."
              p={8}
            />
            <PrivacyQA
              question="Rule 2"
              answer="Shre genuine content. Avoid engaging in deceitful practices such as spamming, vote manupulaiton, or fraud. Do not disrupt or interfere with the integrity of the content in any way."
              p={8}
            />
            <PrivacyQA
              question="Rule 3"
              answer="Respect the privacy of others. Instigating harassment, for example by revealing someone’s personal or confidential information, is not allowed. Never post or threaten to post intimate or sexually-explicit media of someone without their consent"
              p={8}
            />
            <PrivacyQA
              question="Rule 4"
              answer="Do not share or promote sexual, abusive, or suggestive content involving minors. Any predatory or inappropriate behavior involving minors is also strictly forbidden."
              p={8}
            />
            <PrivacyQA
              question="Rule 5"
              answer="Do not post any illegal content, solicit or facilitate illegal or prohibited actions."
              p={8}
            />
            <PrivacyQA
              question="Rule 6"
              answer="Don't break the site or do anything that interferes with its use."
              p={8}
            />
            <PrivacyQA
              question="Rule 7"
              answer="You have the right to remain anonymous, but don’t impersonate an individual or an entity in a misleading or deceptive manner."
              p={8}
            />
          </Section>

          <Section title="Enforcement">
            <P>
              We have several methods for enforcing our rules, including, but
              not limited to:
            </P>
            <UL>
              <li>Requesting you stop</li>
              <li>Removing Content</li>
              <li>
                Removing priveleges from account or imposing additional
                restrictions
              </li>
              <li>Temporarily or permanently suspending accounts</li>
            </UL>
          </Section>
          <div className="h-40" />
        </div>
        <div className="min-w-[450px] p-8">
          <TableOfContents
            headings={headings}
            activeId={activeId}
            scrollRef={scrollRef}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentPolicy;
