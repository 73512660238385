import LeftPanelContent from "./LeftPanelContent";

const LeftPanel = () => {
  return (
    <div className="left-panel">
      <LeftPanelContent />
    </div>
  );
};

export default LeftPanel;
