import { useState } from "react";
import { IoClose } from "react-icons/io5";

interface StickyNotificationProps {
  message: string;
}

const StickyNotification = ({ message }: StickyNotificationProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="bg-red-600 text-white p-4 flex items-center justify-between shadow-lg z-50">
      <span>{message}</span>
      <button
        className="ml-4 text-white hover:text-gray-200"
        onClick={handleClose}
      >
        <IoClose size={20} />
      </button>
    </div>
  );
};

export default StickyNotification;
