import { useState } from "react";

import Modal from "../../common/Modal";
import ModalMenu from "./ModalMenu";
import { PrivacyViewing } from "../../../../../types/enums";
import { useSelector } from "react-redux";
import { selectPrivacy } from "../../../../../store/features/privacySlice";

interface ReligionModalProps {
  modalRef: any;
}

const ViewQuestionsModal = ({ modalRef }: ReligionModalProps) => {
  const { questions } = useSelector(selectPrivacy);

  const [selection, setSelection] = useState(questions);

  return (
    <div ref={modalRef}>
      <Modal title="Who can view my questions">
        <ModalMenu
          type="questions"
          valuesEnum={PrivacyViewing}
          selection={selection}
          setSelection={setSelection}
        />
      </Modal>
    </div>
  );
};

export default ViewQuestionsModal;
