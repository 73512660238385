import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";

import { closeModal } from "../../../../store/features/modalSlice";

interface ModalProps {
  title: string;
  children?: ReactNode;
}

const Modal = ({ title, children }: ModalProps) => {
  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch(closeModal());
  };

  return (
    <div className="card">
      <div className="flex justify-between mb-3">
        <div className="text-base font-semibold">{title}</div>{" "}
        <button onClick={resetModal}>
          <IoMdClose />
        </button>
      </div>
      {children}
    </div>
  );
};

export default Modal;
