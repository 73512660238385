import { ComponentType } from "react";

interface QuestionFooterButtonProps {
  Icon: ComponentType<{ size: number }>;
  action?: () => void;
  count?: number;
  size?: number;
  disabled?: boolean;
  color?: string;
}

const QuestionFooterButton = ({
  Icon,
  action,
  count,
  size,
  disabled,
  color,
}: QuestionFooterButtonProps) => {
  const ICON_SIZE = 20;
  return (
    <button
      className={`center ${color ? color : "text-icon"}`}
      onClick={action}
      disabled={disabled}
    >
      <Icon size={size || ICON_SIZE} />
      <span className="ml-1">{count}</span>
    </button>
  );
};

export default QuestionFooterButton;
