import { useEffect, useRef, useState } from "react";
import mapboxgl, { Map } from "mapbox-gl";

import { getBoundingBox, interpolateColor } from "../../../../../utils/math";
import { useParams } from "react-router-dom";
import { GiRoundStar } from "react-icons/gi";
import "mapbox-gl/dist/mapbox-gl.css";
import { isEmpty } from "lodash";

interface MapboxMapProps {
  locationData?: { [key: string]: Record<string, number> };
}

const MapboxMap = ({ locationData }: MapboxMapProps) => {
  const initalFeatureState = { sub: "", name: "" };
  const [map, setMap] = useState<Map | null>(null);
  const [hoveredCountry, setHoveredCountry] = useState("");
  const [selectedFeature, setSelectedFeature] = useState<{
    [name: string]: any;
  }>(initalFeatureState);
  const [subDivision, setSubDivision] = useState("");

  const { questionId } = useParams<{ questionId: string }>();

  const mapNode = useRef(null);

  const getDominantColor = (countryColors: {
    [key: string]: Record<string, number>;
  }) => {
    const stateColorMap: { [key: string]: string } = {};

    Object.entries(countryColors).forEach(([country, values]) => {
      if (!values?.rating) {
        stateColorMap[country] = "#ccc";
      } else {
        const color = interpolateColor(
          "#add8e6",
          "#0044E6",
          values.rating * 0.1
        );

        stateColorMap[country] = color;
      }
    });
    return stateColorMap;
  };

  useEffect(() => {
    const node = mapNode.current;

    if (node === null) return;
    const initializeMap = () => {
      const mapboxMap = new mapboxgl.Map({
        container: node,
        accessToken: process.env.REACT_APP_MAP_BOX_ACCESS_KEY,
        center: [-71.0589, 42.3601],
        zoom: 2,
      });

      mapboxMap.on("load", () => {
        setMap(mapboxMap);
      });

      const navigation = new mapboxgl.NavigationControl();
      if (!mapboxMap.hasControl(navigation)) mapboxMap.addControl(navigation);
    };

    if (!map) initializeMap();
  }, [map]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (subDivision) {
      searchParams.append("country", subDivision);
    }

    const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
    const baseUrl = `${DOMAIN}/api/v1/maps/`;
    const fullUrl = selectedFeature.sub
      ? `${baseUrl}?${searchParams.toString()}`
      : baseUrl;

    if (map) {
      if (!map.getSource("countries"))
        map.addSource("countries", {
          type: "geojson",
          data: fullUrl,
        });

      const dominantColors = isEmpty(locationData)
        ? {}
        : getDominantColor(locationData);

      if (!map.getLayer("country-fills"))
        map.addLayer({
          id: "country-fills",
          type: "fill",
          source: "countries",
          layout: {},
          paint: {
            "fill-color": [
              "match",
              ["get", "name"],
              ...Object.keys(dominantColors).reduce<string[]>(
                (acc, countryId) => {
                  acc.push(countryId);
                  acc.push(dominantColors[countryId]);
                  return acc;
                },
                []
              ),
              "#ccc",
            ],
            "fill-opacity": 0.8,
          },
        });

      if (!map.getLayer("country-border"))
        map.addLayer({
          id: "country-border",
          type: "line",
          source: "countries",
          layout: {},
          paint: {
            "line-color": "black",
            "line-width": 1,
          },
        });

      map.on("mousemove", "country-fills", (e) => {
        if (e.features.length > 0) {
          setHoveredCountry(e.features[0].properties.name);
        }
      });

      map.on("mouseleave", "country-fills", () => {
        setHoveredCountry("");
      });

      map.on("click", (e) => {
        const selectedFeatures = map.queryRenderedFeatures(e.point, {
          layers: ["country-fills"],
        });
        const selected = selectedFeatures[0];
        if (selected) {
          const geometry: any = selected.geometry;

          if (selected.properties?.sub) {
            setSelectedFeature(selected.properties);
          }

          map.fitBounds(getBoundingBox(geometry), {
            padding: { top: 20, left: 20, bottom: 20, right: 20 },
          });
        }
      });
    }
  }, [map, questionId, locationData]);

  if (isEmpty(locationData))
    return <div className="center">No Data to Display at this time</div>;

  return (
    <>
      {hoveredCountry !== "" ? (
        <div className="absolute bg-gray-200 z-10 right-0 bottom-0 m-2 w-40 text-center rounded-lg p-2 text-black">
          <div className="text-sm font-semibold">{hoveredCountry}</div>
          {locationData[hoveredCountry]?.rating && (
            <div className="center pt-2">
              <GiRoundStar color="#ffbc0b" size={20} />
              {`${locationData[hoveredCountry].rating.toFixed(2)} | ${
                locationData[hoveredCountry].count
              } vote${locationData[hoveredCountry].count !== 1 ? "s" : ""}`}
            </div>
          )}
        </div>
      ) : null}
      <div
        ref={mapNode}
        className="rounded-lg"
        style={{ minWidth: "100px", minHeight: "400px" }}
      />
    </>
  );
};

export default MapboxMap;
