import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDarkMode } from "react-icons/md";

import LineBreak from "../../../utils/LineBreak";
import * as settings from "../../../store/features/settingsSlice";
import { leftPanelMenu } from "../../../constants/menus";
import { setFeed } from "../../../store/features/feedSlice";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../../../store/features/modalSlice";

const LeftPanelContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { darkMode } = useSelector(settings.selectSettings);

  const changeFeed = (location: string) => {
    dispatch(setFeed({ feed: location }));
    navigate("/");
    dispatch(closeModal());
  };

  const setDarkMode = (darkMode: boolean) => {
    dispatch(settings.toggleDarkMode({ darkMode: !darkMode }));
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <>
      <div className="flex flex-col ml-2 p-2">
        <div className="flex items-center p-4">
          <MdOutlineDarkMode size={20} className="mr-2" />
          <div className="mr-2">Toggle Dark Mode</div>
          <label className="switch">
            <input
              type="checkbox"
              checked={darkMode}
              onChange={(e) => setDarkMode(darkMode)}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <LineBreak />
        {leftPanelMenu.map((item) => {
          const Icon = item.icon;
          return (
            <div key={item.text}>
              <button
                type="button"
                className="flex w-full hover:bg-gray-100 dark:hover:bg-gray-900 p-4 rounded-lg"
                onClick={() => {
                  item.feed && changeFeed(item.feed);
                  item.location && navigate(item.location);
                }}
              >
                <div>
                  <Icon size={20} className="mr-2" />
                </div>
                <div>{item.text}</div>
              </button>
              {item.breakpoint && <LineBreak />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LeftPanelContent;
