import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa6";

import { closeModal } from "../../../../../store/features/modalSlice";
import { toaster } from "../../../../../utils/toast";
import {
  selectPrivacy,
  setPrivacySettings,
} from "../../../../../store/features/privacySlice";
import { PrivacyViewing } from "../../../../../types/enums";

import { selectUser } from "../../../../../store/features/authSlice";
import { setPrivacy } from "../../../../../api/profileApi";

interface ModalMenuProps {
  type: string;
  valuesEnum: Record<string, string>;
  selection: PrivacyViewing;
  setSelection: Dispatch<SetStateAction<any>>;
}

const ModalMenu = ({
  type,
  valuesEnum,
  selection,
  setSelection,
}: ModalMenuProps) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(selectUser);
  const { questions, comments } = useSelector(selectPrivacy);

  const typeMapping: { [key: string]: string } = {
    questions: "questions",
    comments: "comments",
  };

  const updatePrivacy = () => {
    const newSelection = typeMapping[type]
      ? { [typeMapping[type]]: selection }
      : {};

    setPrivacy({ userId, ...newSelection })
      .then(() => {
        dispatch(setPrivacySettings(newSelection));
        dispatch(closeModal());
      })
      .catch((error) => {
        console.error(error.response.data.message);
        toaster(error.response.data.message);
      });
  };

  const privacyMap: { [key: string]: string | undefined } = {
    questions: questions,
    comments: comments,
  };

  const previousValue = privacyMap[type];
  const isDisabled = !selection || previousValue === selection;

  return (
    <div className="w-80 flex flex-col text-normal">
      <div className="max-h-[70vh] overflow-scroll overflow-x-hidden">
        {Object.values(valuesEnum).map((value) => {
          return (
            <button
              key={value}
              className="flex justify-between w-full items-center hover:bg-gray-100 dark:hover:bg-gray-900 p-2"
              onClick={() => setSelection(value)}
            >
              <div>{value}</div>
              <div>
                {((previousValue === value && !selection) ||
                  selection === value) && <FaCheck />}
              </div>
            </button>
          );
        })}
      </div>

      <button
        className={`${isDisabled ? "button-disabled" : "button"}  w-full mt-5`}
        onClick={updatePrivacy}
        disabled={isDisabled}
      >
        Save
      </button>
    </div>
  );
};

export default ModalMenu;
