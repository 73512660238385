import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import { toaster } from "./toast";

export const errorCatcher = (
  error: AxiosError,
  message?: string,
  next?: () => void
) => {
  if (error.response?.status === StatusCodes.UNAUTHORIZED) {
    message && toaster({ text: message });
    next && next();
  } else {
    console.error(error);
  }
};

export const dataURLToBlob = (dataURL: string) => {
  const [header, data] = dataURL.split(",");
  const mime = header.split(":")[1].split(";")[0];
  const bytes = atob(data);
  const array = [];

  for (let i = 0; i < bytes.length; i++) {
    array.push(bytes.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: mime });
};

export const blobToFile = (blob: Blob, filename: string): File => {
  return new File([blob], filename, {
    type: blob.type,
    lastModified: new Date().getTime(),
  });
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const convertCountryNames = (name: string | undefined): string => {
  switch (name) {
    case "United States":
      return "United States of America";
    default:
      return name as string;
  }
};
