import { Dispatch, SetStateAction, useState } from "react";

import { Choice } from "../../../../types";
import "./choiceDisplay.css";

interface ChoiceDisplayProps {
  previouslySelected?: string;
  choices: Array<Choice>;
  setSelectedChoice: Dispatch<SetStateAction<string>>;
}

const ChoiceDisplay = ({
  previouslySelected,
  choices,
  setSelectedChoice,
}: ChoiceDisplayProps) => {
  const [choiceId, setChoiceId] = useState(previouslySelected || "");

  const handleSelect = (id: string) => {
    setChoiceId(id);
    setSelectedChoice(id);
  };

  const getClassName = (id?: string) => {
    if (!choiceId || (choiceId !== id && !previouslySelected)) {
      return "grid-item";
    }

    if (choiceId === id) {
      return "grid-item-selected text-black";
    }

    return "grid-item-no-hover";
  };

  return (
    <div className="center flex flex-col">
      <div className="w-4/5 grid grid-cols-2 gap-4 max-sm:grid-cols-1 my-2">
        {choices.map((choice, index) => {
          return (
            <button
              key={`choice-${index}`}
              className={getClassName(choice._id)}
              onClick={() => {
                handleSelect(choice._id as string);
              }}
              disabled={
                previouslySelected !== null &&
                previouslySelected !== undefined &&
                previouslySelected !== ""
              }
            >
              <div className="center flex-col">
                <div>{choice.text}</div>

                {choice.picture && (
                  <img
                    src={choice.picture}
                    alt={`Preview ${choice.text}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      objectFit: "cover",
                      margin: "5px",
                      borderRadius: 8,
                    }}
                  />
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ChoiceDisplay;
