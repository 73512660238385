import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import Cookies from "js-cookie";

import { QuestionData } from "../../../types";
import { getPersonalizedFeed } from "../../../api/questionApi";
import Content from "./Content";
import {
  useFetchFeedOnFetchLoad,
  useInitializeFeed,
  useScrollFetch,
} from "../../../hooks/FeedHooks";
import { FeedTypes } from "../../../types/enums";
import { selectUser } from "../../../store/features/authSlice";
import { COOKIE_TOKEN, SITE_NAME } from "../../../constants/strings";
import { selectFeed } from "../../../store/features/feedSlice";

const FollowingFeed = () => {
  const { userId } = useSelector(selectUser);
  const { refreshFeed } = useSelector(selectFeed);
  const feedRef = useRef<HTMLDivElement>(null);

  const [nextPage, setNextPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [feed, setFeed] = useState<Array<QuestionData>>([]);
  const [isLoading, setLoading] = useState(true);

  const token = Cookies.get(COOKIE_TOKEN);

  useEffect(() => {
    setNextPage(1);
  }, [userId, token]);

  const loadMoreQuestions = async (page: number) => {
    setLoading(true);
    await getPersonalizedFeed(page, FeedTypes.FOLLOWING)
      .then((response) => {
        const data = response.data.data;
        setHasNext(data.hasNext);
        setNextPage((prevPage) => prevPage + 1);
        const questions = data.questions;
        setFeed((prevFeed) => [...prevFeed, ...questions]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useInitializeFeed(setFeed, loadMoreQuestions, [userId, refreshFeed]);
  const { load, setLoad } = useScrollFetch(feedRef);
  useFetchFeedOnFetchLoad(load, setLoad, loadMoreQuestions, hasNext, nextPage);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production")
      ReactGA.send({
        hitType: "pageview",
        title: `${SITE_NAME} | Following`,
      });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{SITE_NAME} | Following</title>
        <meta
          name="description"
          content={`Following feed ${SITE_NAME} questions`}
        />
      </Helmet>
      <Content isLoading={isLoading} feed={feed} feedRef={feedRef} />
    </div>
  );
};

export default FollowingFeed;
