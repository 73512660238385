import { useState } from "react";

import Modal from "../../common/Modal";
import ModalMenu from "./ModalMenu";
import { Politics } from "../../../../../types/enums";

interface PoliticalModalProps {
  modalRef: any;
}

const PoliticalModal = ({ modalRef }: PoliticalModalProps) => {
  const [selection, setSelection] = useState("");
  return (
    <div ref={modalRef}>
      <Modal title="Political Affiliation">
        <ModalMenu
          type="politics"
          valuesEnum={Politics}
          selection={selection}
          setSelection={setSelection}
        />
      </Modal>
    </div>
  );
};

export default PoliticalModal;
