import { ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { removeUser } from "../../store/features/authSlice";
import { COOKIE_TOKEN, LOGOUT_ACTION } from "../../constants/strings";

interface HeaderAuthMenuItemProps {
  Icon?: ComponentType<{ size: number }>;
  text: string;
  size?: number;
  location?: string;
  action?: string;
  closeMenu: () => void;
}

const HeaderAuthMenuItem = ({
  Icon,
  text,
  size,
  location,
  action,
  closeMenu,
}: HeaderAuthMenuItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(removeUser());
    Cookies.remove(COOKIE_TOKEN);
  };

  const handleMenuItemClick = () => {
    if (action === LOGOUT_ACTION) logout();
    if (location) navigate(location);
    closeMenu();
  };

  const ICON_SIZE = 18;
  return (
    <button onClick={handleMenuItemClick} className="auth-header-menu-item">
      <div className="flex items-center">
        <span>{Icon && <Icon size={size || ICON_SIZE} />}</span>
        <span className="ml-2 text-sm">{text}</span>
      </div>
    </button>
  );
};

export default HeaderAuthMenuItem;
