import axios from "axios";
import Cookies from "js-cookie";

import { COOKIE_TOKEN } from "../constants/strings";
import { isEmpty } from "lodash";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const API_ROUTE = `${DOMAIN}/api/v1`;
const DEMOGRAPHIC_ROUTES = `${API_ROUTE}/demographics`;

export const getDemographics = (userId: string) => {
  return axios({
    method: "GET",
    url: `${DEMOGRAPHIC_ROUTES}`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    params: { userId },
  });
};

interface UpdateDemographicsData {
  userId: string;
  dob?: string;
  height?: {
    normalized: number;
    units: string;
  };
  gender?: string;
  ethnicity?: string;
  orientation?: string;
  religion?: string;
  politics?: string;
  location?: {
    city: string;
    longitude: number;
    latitude: number;
  };
}

export const updateDemographics = (data: UpdateDemographicsData) => {
  return axios({
    method: "POST",
    url: `${DEMOGRAPHIC_ROUTES}/update`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      userId: data.userId,
      ...(data.dob && { dob: data.dob }),
      ...(!isEmpty(data.height) && { height: data.height }),
      ...(data.gender && { gender: data.gender }),
      ...(data.ethnicity && { ethnicity: data.ethnicity }),
      ...(data.orientation && { orientation: data.orientation }),
      ...(data.religion && { religion: data.religion }),
      ...(data.politics && { politics: data.politics }),
      ...(!isEmpty(data.location) && { location: data.location }),
    },
  });
};

export const unshareDemographics = (data: {
  userId: String;
  field: string;
}) => {
  return axios({
    method: "PATCH",
    url: `${DEMOGRAPHIC_ROUTES}/unshare`,
    headers: {
      Authorization: `Bearer ${Cookies.get(COOKIE_TOKEN)}`,
    },
    data: {
      userId: data.userId,
      field: data.field,
    },
  });
};
