import { Rating as ReactRating } from "react-simple-star-rating";
import { isEmpty, uniqueId } from "lodash";
import { GiRoundStar } from "react-icons/gi";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { RatingAnswers } from "../../../../../types/answers";

interface RatingSlideProps {
  answers?: RatingAnswers;
  type: keyof RatingAnswers;
}

const RatingSlide = ({ answers, type }: RatingSlideProps) => {
  const value = isEmpty(answers) ? {} : answers[type];

  const isRecord = (
    value: any
  ): value is Record<string, Record<string, number>> => {
    return typeof value === "object" && value !== null;
  };

  return (
    <>
      {isEmpty(value) ? (
        <>empty</>
      ) : (
        <div className="center flex flex-row flex-wrap my-8">
          {isRecord(value) &&
            Object.entries(value)
              .sort((a, b) => {
                if (a[0] > b[0]) {
                  return 1;
                } else if (a[0] < b[0]) {
                  return -1;
                }
                return 0;
              })
              .map(([key, value]) => {
                const votes = value.count;
                const average = value.total / value.count;
                return (
                  <div
                    className="flex flex-col mx-10 text-sm mt-2"
                    key={uniqueId()}
                  >
                    {key}
                    <div data-tooltip-id={`${type}-${key}`}>
                      <ReactRating
                        size={25}
                        emptyIcon={<GiRoundStar size={25} />}
                        fillIcon={<GiRoundStar size={25} color="lightblue" />}
                        initialValue={average || 0}
                        iconsCount={10}
                        emptyStyle={{ display: "flex" }}
                        fillStyle={{ display: "-webkit-inline-box" }}
                        allowHover={false}
                        allowTitleTag={false}
                        allowFraction
                        readonly
                      />
                    </div>
                    <ReactTooltip
                      id={`${type}-${key}`}
                      place="bottom"
                      content={`${average.toFixed(
                        2
                      )} out of 10 | ${votes} votes`}
                      style={{ zIndex: 10 }}
                    />
                  </div>
                );
              })}
        </div>
      )}
    </>
  );
};

export default RatingSlide;
